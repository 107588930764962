import axios from '@/axios'
import Config from '@/config.js'

export async function createAuthInfo (imp_uid, { sns, accessToken, inputMid }) {
  const res = await axios.get(`/member/getauthinfo?imp_uid=${imp_uid}`);
  const json = res.data;
  const itemData = json.resultMap.data;
  itemData.imp_uid = imp_uid;
  if (sns && sns !== 'null') {
    itemData.sns = sns
  }
  if (accessToken && accessToken !== 'null') {
    itemData.accessToken = accessToken
  }
  if (inputMid && inputMid !== null) {
    itemData.inputMid = inputMid
  }
  return itemData
}

export async function requestLogout () {
  var token = sessionStorage.getItem('token');
  return axios.get(`/member/logout`, {headers: {'token': token}})
}

export async function signInWithToken (token, { store }) {
  if (!token) return

  sessionStorage.setItem('token', token)
  const res = await axios.get(`loginmember/getmember`, {
    headers: {
      'token': token
    }
  });
  const json = res.data;
  const member = json.resultMap.member;
  if (member) {
    store.commit('user/CHANGE_IS_LOGIN', true);
    store.commit('user/CHANGE_MEMBER_DATA', member);
  } else {
    window.alert('유효하지 않은 인증입니다.')
  }
}

export async function agreeSignIn (token) {
const res = await axios.post(`member/loginagree`, {
  ltoken: token
});
return res.data;
}

export async function signIn (mid, passwd, app = '') {
  const res = await axios.post(`member/partnerlogin`, {
    mid,
    passwd,
    pid: app === 'money' ? 'flexmoney' : 'flexlife'
  });
  const data = res.data;
  if (data?.returnCode === 1 && data.resultMap.returnVal === -1004) {
    await agreeSignIn(data.resultMap.token)
    const res = await axios.post(`member/partnerlogin`, {
      mid,
      passwd,
      pid: app === 'money' ? 'flexmoney' : 'flexlife'
    });
    return res.data
  }
  return data
}

export async function signInSns (params) {
  const redirectUri = {
    'kakao': Config.URL.redirectUri_kakao,
    'google': Config.URL.redirectUri_google,
  }
  const res = await axios.post(`member/snslogin`, { ...params, redirect_uri: redirectUri[params.sns] });
  const data = res.data;
  if (data?.returnCode === 1 && data.resultMap.returnVal === -1004) {
    await agreeSignIn(data.resultMap.token)
    const res = await axios.post(`member/snslogin`, params);
    return res.data
  }
  return data
}
