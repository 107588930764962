<template>
  <!-- container start { -->
  <div id="container" style="display: flex; justify-content: center; align-items: center; height: 300px;">
    <spinner />
    <!-- } content end -->
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import Spinner from '@/components/Spinner.vue'
import useApp from '@/hooks/useApp'
import Config from '@/config.js'
export default {
  components: {
    Spinner,
  },
  setup() {
    const { saveQuery } = useApp()
    const store = useStore();

    const clientId = '1045595419140-dtm6tjmhj97db7dt9etot1fjrdovdn9t.apps.googleusercontent.com';

    onMounted(() => {
      store.commit('common/CHANGE_CURRENT_PAGE', 'GoogleLogin');
      saveQuery()

      const redirectUrl = Config.URL.redirectUri_google
      const url = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=' + clientId +
          '&redirect_uri=' + redirectUrl +
          '&response_type=code' +
          '&scope=email profile';

      document.location.href = url
    })

    return {
    }
  }
}
</script>

<style>

</style>
