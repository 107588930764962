<template>
  <!-- container start { -->
  <div id="container" class="sub-contents">
    <!-- content start { -->
    <div id="contents">

      <!-- sub title h2 start -->
      <!-- <div class="sub-title">
          <div class="position">
              <h2 @click="$router.go(-1)">
                  <img src="@/assets/images/cm/ico_back.png" alt="back" class="mr-5"> 회원정보
              </h2>
          </div>
      </div>   -->

      <!-- sub title h2 start -->
      <div class="sub-title">
        <h2>내정보</h2>
      </div>

      <!-- table type 1 start -->
      <table class="tbl-type1 tbl-top-line">
        <colgroup>
          <col style="width:30%">
          <col style="width:70%">
        </colgroup>
        <tbody>
        <!--tr>
            <th>이름</th>
            <td>{{memberObj.NAME}}</td>
        </tr-->
        <tr>
          <th>아이디</th>
          <td>{{ memberObj.MID }}</td>
        </tr>
        <tr>
          <th>E-mail</th>
          <td>{{ memberObj.EMAIL || '-' }}</td>
        </tr>
        <!--tr>
            <th>핸드폰번호</th>
            <td>{{memberObj.PHONE}}</td>
        </tr-->
        <tr>
          <th>제휴사 동의현황</th>
          <td>
            <div
                class="mb-5">
              <div class="mr-10" style="font-weight: bolder">
                <i :class="'icon-selected'"/> 플렉스티비
              </div>
            </div>
            <div
                class="mb-5"
                v-for="(item) in partnersObj"
                :key=item.PARTNERS_IDX
            >

              <div class="mr-10" :style="`font-weight: ${item.ISAGREE== 'Y' ? 'bolder' : 'initial'};`">
                <i :class="item.ISAGREE== 'Y' ? 'icon-selected' : 'icon-not-selected'" /> {{ item.PARTNERS_NAME }}
                <router-link to="modifyPartner" style="right: 20px; position: absolute; text-decoration: underline;">{{ item.ISAGREE== 'Y' ? '동의 철회 >' : '이용 동의 >' }}</router-link>
              </div>
            </div>
            <!-- <input type="checkbox" id="chk2" checked readonly>
            <label for="chk2" class="mr-10">FLEX FN</label> -->
          </td>
        </tr>
        <tr>
          <th>마케팅 정보 수신동의</th>
          <td>
            <div class="mb-5">
              <div v-if="memberObj.AGREE_EM === 'Y'" class="mr-10">
                E-MAIL
              </div>
            </div>
            <div class="mb-5">
              <div v-if="memberObj.AGREE_SMS === 'Y'" class="mr-10">
                SMS
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <!-- //table type 1 end -->

      <div class="d-flex-start my-40">
        <button @click="onGoRemovePro" type="button" class="btn btn-xl w-50">회원탈퇴</button>
        <button @click="onGoModifyPro" type="button" class="btn btn-xl btn-outline-blue ml-10 w-50">회원정보변경</button>
      </div>

    </div>
    <!-- } content end -->
  </div>
  <!--  } container end -->
  <Footer/>


</template>

<script>
//import {useAuthentication} from '@/composables/commonFn';
import Footer from '@/components/Footer.vue';
import axios from '@/axios';
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex';
import { signInWithToken } from '@/helpers/auth'

export default {
  components: {
    Footer,
  },
  setup() {
    const router = useRouter();
    const route = useRoute()
    const memberObj = ref({});
    const partnersObj = ref([]);
    const snsObj = ref([]);
    const store = useStore();
    const isLogin = computed(() => store.state.user.isLogin);

    onMounted(() => {
      store.commit('common/CHANGE_CURRENT_PAGE', 'Profile');
      const { token } = route.query
      if (token) {
        signInWithToken(token, { store }).then(() => {
          apiCallGetmypartners();
          if (!isLogin.value) {
            router.push({ name: 'Login' })
          }
        })
      } else {
        apiCallGetmypartners();
        if (!isLogin.value) {
          router.push({ name: 'Login' })
        }
      }
    })

    const apiCallGetmember = async () => {
      var token = sessionStorage.getItem('token');
      try {
        const res = await axios.get(`loginmember/getmember`, {
          headers: {
            'token': token
          }
        });
        let json = res.data;
        memberObj.value = json.resultMap.member;
        // ACCOUNTTYPE: "1"
        // ADDR1: "addr1"
        // ADDR2: "addr2"
        // AGREE_DM: "Y"
        // AGREE_EM: "Y"
        // AGREE_SMS: "Y"
        // AGREE_TM: "Y"
        // BIRTHDAY: "19730925"
        // CREATIONDATE: "2022-08-01 18:40:01"
        // EMAIL: "woo0075@nate.com"
        // GENDER: null
        // IDX: "6"
        // ISAUTH: "Y"
        // ISBAN: "N"
        // ISMEMBER: "Y"
        // LASTLOGINDATE: "2022-08-03 13:17:04"
        // LASTLOGINIP: "45.67.97.90"
        // LOGINENABLE: "Y"
        // MID: "woo0075"
        // MODIFICATIONDATE: "2022-08-03 13:17:04"
        // NAME: "김경우"
        // PHONE: "01038069771"
        // ZIPCODE: "123456"
        console.log("apiCallGetmember:", json)
      } catch (error) {
        console.log("apiCallGetmember error", error);
        return false;
      }
    }
    apiCallGetmember();

    const apiCallGetmypartners = async () => {
      var token = sessionStorage.getItem('token');
      try {
        const res = await axios.get(`loginmember/getmypartners`, {
          headers: {
            'token': token
          }
        });

        let json = res.data;
        partnersObj.value = (json?.resultMap?.dataList ?? []).filter(v => v.PID === 'flexmoney');

      } catch (error) {
        console.log("apiCallGetmypartners error", error);
        return false;
      }
    }

    const apiCallGetmysns = async () => {
      var token = sessionStorage.getItem('token');
      try {
        const res = await axios.get(`sns/getmysns`, {
          headers: {
            'token': token
          }
        });

        let json = res.data;
        snsObj.value = json.resultMap.dataList;
        if (!snsObj.value) snsObj.value = [];
        // "dataList": [
        //     {
        //         "MEMBER_IDX": "48",
        //         "SNS_CODE": "naver",
        //         "CID": "Dz5AiTk8zFXl4VEstex64eak4vMEVmNRguuyYPHD40s",
        //         "MID": "woo0021",
        //         "MEMBER_NAME": "김경우",
        //         "SNS_NAME": "네이버",
        //         "CREATIONDATE": "2022-10-21 14:45:40"
        //     }
        // ]
        console.log("apiCallGetmypartners:", snsObj.value)
      } catch (error) {
        console.log("apiCallGetmypartners error", error);
        return false;
      }
    }
    apiCallGetmysns();

    const findSns = (code) => {
      var found = snsObj.value.find(element => element.SNS_CODE == code);
      if (found) {
        return true;
      } else {
        return false;
      }
    }

    const onGoMain = () => {
      router.push({
        name: 'Main',
      });
    }

    const onGoRemovePro = () => {
      router.push({
        name: 'RemoveProfile',
      });
    }

    const onGoModifyPro = () => {
      router.push({
        name: 'ModifyProfile',
      });
    }

    return {
      memberObj,
      onGoMain,
      onGoRemovePro,
      onGoModifyPro,
      partnersObj,
      snsObj,
      findSns
    }
  }

}
</script>

<style>

</style>
