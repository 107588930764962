<template>
  <!-- container start { -->
  <div id="container">
    <!-- content start { -->
    <div id="contents">
      <!-- login start { -->
      <div class="login-area">
        <div class="login-section">
          <div class="login-tab">
            <div class="btn-login">
              <button type="button" class="btn btn-blue" @click="handleLogOut">로그아웃</button>
            </div>
          </div>
        </div>
      </div>
      <!-- } login end -->
    </div>
    <!-- } content end -->
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { requestLogout } from '@/helpers/auth'
import { closeWindow, createRedirectUrl } from '@/helpers/redirect'

export default {
  name: "logout",
  setup () {
    const route = useRoute();
    const { redirect_url: redirectUrl } = route.query

    function handleLogOut () {
      requestLogout().then(() => {
        window.location.href = createRedirectUrl(redirectUrl)
        closeWindow(redirectUrl)
      })
    }

    return {
      handleLogOut
    }
  }
}
</script>

<style scoped>

</style>
