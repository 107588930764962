<template>
 <!-- container start { -->
    <div id="container" class="sub-contents" style="max-width: 400px; margin: 0 auto;">
        <!-- content start { -->
        <div id="contents">

            <!-- sub title h2 start { -->
            <div class="sub-title">
                <h2>회원가입</h2>
            </div>
            <!-- } sub title h2 end -->

            <!-- success start { -->
            <div class="qna-success sign">
                <h3>회원가입이 완료 되었습니다.</h3>
                <img src="@/assets/images/us/signup-success.png" width="160" alt="회원가입이 완료 되었습니다.">
                <p>로그인을 하시면 다양한 플렉스라이프가 펼쳐집니다.</p>
                <div class="btn-area" @click="onSignIn">
                    <router-link  :to="{ name: ''}" class="btn btn-blue btn-xl w-100">로그인 바로가기</router-link>
                </div>
            </div>
            <!-- } success end -->
        </div>
        <!-- } content end -->
    </div>
    <!--  } container end -->
    <Footer/>


</template>

<script>
import Footer from '@/components/Footer.vue';
import useApp from '@/hooks/useApp'
import { useRouter } from 'vue-router'
import { closeWindow, createRedirectUrl } from '@/helpers/redirect'
export default {
  components:{
    Footer,
  },
  setup() {
    const router = useRouter();
    const { nextUrl, isNativeApp } = useApp()

    const onSignIn = () => {
      if (isNativeApp()) {
        const url = nextUrl.value
        window.location.href = createRedirectUrl(url)
        closeWindow(url)
        return
      }
      router.push({
        name: 'Login',
      });
    }

    return {
      onSignIn
    }
  }
}
</script>

<style>

</style>
