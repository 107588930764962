const BASE_URL = window ? window.location.origin : '/'

module.exports = {
    URL: {
        apiLifeUrl: "https://api.flexlife.co.kr/",
        redirectUri_naver: `${BASE_URL}/naverauth.html`,
        redirectUri_kakao: `${BASE_URL}/kakaoauth.html`,
        redirectUri_google: `${BASE_URL}/googleauth.html`,
        redirectUri_m_sms: `${BASE_URL}/SmsGetImpRedir`,
    }
}
