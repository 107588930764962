import { createRouter, createWebHistory } from 'vue-router';
import Main from '../pages/main';
import Company from '../pages/company.vue';
import Guide from '../pages/guide.vue';
import Service from '../pages/service.vue';
import Events from '../pages/events.vue';
import Profile from '../pages/profile.vue';
import ModifyProfile from '../pages/modifyProfile.vue';
import RemoveProfile from '../pages/removeProfile.vue';
import Login from '../pages/login.vue';
import Logout from '@/pages/logout.vue';
import CenterNotice from '../pages/centerNotice.vue';
import CenterNoticeView from '../pages/centerNoticeView.vue';
import CenterQuestions from '../pages/centerQuestions.vue';
import CenterQnA from '../pages/centerQnA.vue';
import CenterQnaWrite from '../pages/centerQnaWrite.vue';
import CenterQnaSuccess from '../pages/centerQnaSuccess.vue';
import Signup from '../pages/signup.vue';
import SignupSuccess from '../pages/signupSuccess.vue';
import ChangePassword from "@/pages/changePassword"
import Certificate from '@/pages/certificate.vue'
import Partners from "@/pages/partners";
import ModifyPartner from '@/pages/modifyPartner.vue'
import KakaoLogin from '@/pages/kakaoLogin.vue'

import Policy from '../pages/policy.vue';
import FindPassword from '../pages/findPassword.vue';
import ChangePhone from "@/pages/changePhone";
import AddPersonalAuth from "@/pages/addPersonalAuth";

import Privacy from '../pages/privacy.vue';
import SnsLoginRedir from '../pages/snsLoginRedir.vue';
import SmsGetImpRedir from '../pages/smsGetImpRedir.vue';
import FindId from '../pages/findId.vue';
import FoundPassword from '../pages/foundPassword.vue';
import AppleLogin from '@/pages/appleLogin.vue'
import GoogleLogin from '@/pages/googleLogin.vue'

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(){
        return { top: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Main',
            component: Main,
        },
        {
            path: '/company',
            name: 'Company',
            component: Company
        },
        {
            path: '/guide',
            name: 'Guide',
            component: Guide
        },
        {
            path: '/service',
            name: 'Service',
            component: Service
        },
        {
            path: '/events',
            name: 'Events',
            component: Events
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            meta: { requiresAuth: true }
        },
        {
            path: '/modifyProfile',
            name: 'ModifyProfile',
            component: ModifyProfile,
            meta: { requiresAuth: true }
        },
        {
            path: '/removeProfile',
            name: 'RemoveProfile',
            component: RemoveProfile,
            meta: { requiresAuth: true }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
            meta: { requiresAuth: true }
        },
        {
            path: '/centerNotice',
            name: 'CenterNotice',
            component: CenterNotice
        },
        {
            path: '/centerNotice/:id',
            name: 'CenterNoticeView',
            component: CenterNoticeView
        },
        {
            path: '/centerQuestions',
            name: 'CenterQuestions',
            component: CenterQuestions
        },
        {
            path: '/centerQnA',
            name: 'CenterQnA',
            component: CenterQnA
        },
        {
            path: '/centerQnA/write',
            name: 'centerQnaWrite',
            component: CenterQnaWrite
        },
        {
            path: '/centerQnA/success',
            name: 'centerQnaSuccess',
            component: CenterQnaSuccess
        },
        {
            path: '/signup',
            name: 'Signup',
            component: Signup
        },
        {
            path: '/signupSuccess',
            name: 'SignupSuccess',
            component: SignupSuccess
        },
        {
            path: '/policy',
            name: 'Policy',
            component: Policy
        },
        {
            path: '/privacy',
            name: 'Privacy',
            component: Privacy
        },
        {
            path: '/findPassword',
            name: 'FindPassword',
            component: FindPassword
        },
        {
            path: '/snsLoginRedir',
            name: 'SnsLoginRedir',
            component: SnsLoginRedir
        },
        {
            path: '/smsGetImpRedir',
            name: 'SmsGetImpRedir',
            component: SmsGetImpRedir
        },
        {
            path: '/findId',
            name: 'FindId',
            component: FindId
        },
        {
            path: '/foundPassword',
            name: 'FoundPassword',
            component: FoundPassword
        },
        {
            path: '/changePassword',
            name: 'ChangePassword',
            component: ChangePassword
        },
        {
            path: '/changePhone',
            name: 'ChangePhone',
            component: ChangePhone
        },
        {
            path: '/addPersonalAuth',
            name: 'AddPersonalAuth',
            component: AddPersonalAuth
        },
        {
            path: '/partners',
            name: 'Partners',
            component: Partners
        },
        {
            path: '/certificate',
            name: 'Certificate',
            component: Certificate
        },
        {
            path: '/modifyPartner',
            name: 'ModifyPartner',
            component: ModifyPartner
        },
        {
            path: '/kakaoLogin',
            name: 'KakaoLogin',
            component: KakaoLogin
        },
        {
            path: '/appleLogin',
            name: 'AppleLogin',
            component: AppleLogin
        },
        {
            path: '/googleLogin',
            name: 'GoogleLogin',
            component: GoogleLogin
        }
    ]
});

export default router;
