<template>
  <div />
</template>

<script>

import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
import {createAuthInfo} from '@/helpers/auth'

export default {
  components: {
  },
  setup() {
    const {URL} = window;
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    const router = useRouter();

    onMounted(() => {
      getauthInfo(urlParams.get('imp_uid'))
    })
    const getauthInfo = async (imp_uid) => {
      try {
        const itemData = await createAuthInfo(imp_uid, {
          sns: urlParams.get('sns'),
          accessToken: urlParams.get('accessToken'),
          inputMid: urlParams.get('inputMid')
        })
        router.push({
          name: urlParams.get('request_page'),
          params: {
            item: JSON.stringify(itemData)
          },
          query: {
            app: urlParams.get('app')
          }
        });
      } catch (error) {
        console.log(error);
        return false;
      }
    }
    return {
    }
  }

}
</script>

<style>

</style>
