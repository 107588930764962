<template>
  <!-- container start { -->
  <div id="container" class="sub-contents">
    <!-- content start { -->
    <div id="contents">
      <!-- sub title text start -->
      <div class="sub-title-txt">
        <p>아이디확인을 통해 비밀번호를 변경하고 로그인 해주세요.</p>
      </div>
      <!-- //sub title text end -->

      <div>

        <p class="p-sub-tit">
          비밀번호 변경
          <br/>
          <span class="fs14 fc-blue">* 비밀번호는 8자이상입니다 (영문/숫자/기호 포함)</span>
        </p>
        <div class="pw-finder-box">
          <label for="pw-finder-input1" class="blind">비밀번호 확인</label>
          <input type="password" name="pw-finder-input1" id="pw-finder-input1" placeholder="현재 비밀번호를 입력해주세요"
                 class="w-100"
                 v-model="originPassword">
        </div>
        <div class="pw-finder-box">
          <label for="pw-finder-input1" class="blind">비밀번호 확인</label>
          <input type="password" name="pw-finder-input1" id="pw-finder-input1" placeholder="비밀번호를 입력해주세요" class="w-100"
                 v-model="pass_1">
          <label for="pw-finder-input2" class="blind">비밀번호 확인</label>
          <input @focusout="onPassFocusout" type="password" name="pw-finder-input2" id="pw-finder-input2"
                 placeholder="입력하신 비밀번호를 다시 한번 입력해주세요" class="w-100 mt-10" v-model="pass_2">
        </div>
        <div class="d-flex-between mt-20">
          <button @click="onCancle" type="button" class="btn btn-xl w-50">취소</button>
          <button @click="onOk" type="button" class="btn btn-xl btn-outline-blue w-50 ml-10">변경</button>
        </div>
      </div>

    </div>
    <!-- } content end -->
  </div>
  <!--  } container end -->
  <Footer/>
</template>

<script>

import Footer from '@/components/Footer.vue';
import axios from '@/axios';
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { closeWindow, createRedirectUrl } from '@/helpers/redirect'
import useApp from '@/hooks/useApp'

export default {
  components: {
    Footer,
  },
  setup() {
    const isPassTable = ref(false);
    const inputMid = ref('');
    const originPassword = ref('')
    const pass_1 = ref('')
    const pass_2 = ref('')
    const router = useRouter();
    const route = useRoute();
    const { nextUrl } = useApp()
    var pw_passed = true;  // 추후 벨리데이션 처리시에 해당 인자값 확인을 위해

    const apiCallPasswdreset = async () => {
      const params = {
        oldpasswd: originPassword.value,
        newpasswd: pass_2.value
      }
      try {
        const token = sessionStorage.getItem('token');
        const res = await axios.put(`loginmember/passwdchange`, params, {headers: {'token': token}});
        const json = res.data;
        if (json.returnCode == 1) {
          if (json.resultMap.returnVal == 1) {
            alert("비밀번호 변경을 성공하였습니다.");
            if (nextUrl.value) {
              const url = nextUrl.value
              window.location.href = createRedirectUrl(url)
              closeWindow(url)
              return
            }
            router.push({
              name: 'Login',
            });
          } else {
            alert(json.resultMap.message || json.message);
          }
        } else {
          alert("비밀번호 변경을 실패하였습니다.");
        }
      } catch (error) {
        console.log("apiCallPasswdreset error", error);
        return false;
      }
    }

    onMounted(() => {
      console.log(" foundPass route.params.item :", route.params.item)
    })


    const fn_pw_check = () => {
      const origin = originPassword.value;
      const pw = pass_1.value; //비밀번호
      const pw2 = pass_2.value; // 확인 비밀번호

      pw_passed = true;
      var pattern1 = /[0-9]/;
      var pattern2 = /[a-zA-Z]/;
      /* eslint-disable-next-line */
      var pattern3 = /[~!@\#$%<>^&*]/;     // 원하는 특수문자 추가 제거

      if (pw.length == 0) {
        alert("비밀번호를 입력해주세요");
        return false;
      } else {
        if (pw !== pw2) {
          alert("비밀번호가 일치하지 않습니다.");
          return false;
        }
      }
      if (origin === pw) {
        alert("변경전과 동일한 비밀번호를 입력하였습니다.");
        return false;
      }

      if (!pattern1.test(pw) || !pattern2.test(pw) || !pattern3.test(pw) || pw.length < 8 || pw.length > 50) {
        alert("영문+숫자+특수기호 8자리 이상으로 구성하여야 합니다.");
        return false;
      }

      var SamePass_0 = 0; //동일문자 카운트
      var SamePass_1 = 0; //연속성(+) 카운드
      var SamePass_2 = 0; //연속성(-) 카운드
      for (var i = 0; i < pw.length; i++) {
        var chr_pass_0;
        var chr_pass_1;
        var chr_pass_2;
        if (i >= 2) {
          chr_pass_0 = pw.charCodeAt(i - 2);
          chr_pass_1 = pw.charCodeAt(i - 1);
          chr_pass_2 = pw.charCodeAt(i);
          //동일문자 카운트
          if ((chr_pass_0 == chr_pass_1) && (chr_pass_1 == chr_pass_2)) {
            SamePass_0++;
          } else {
            SamePass_0 = 0;
          }
          //연속성(+) 카운드
          if (chr_pass_0 - chr_pass_1 == 1 && chr_pass_1 - chr_pass_2 == 1) {
            SamePass_1++;
          } else {
            SamePass_1 = 0;
          }
          //연속성(-) 카운드
          if (chr_pass_0 - chr_pass_1 == -1 && chr_pass_1 - chr_pass_2 == -1) {
            SamePass_2++;
          } else {
            SamePass_2 = 0;
          }
        }
        if (SamePass_0 > 0) {

          alert("동일문자를 3자 이상 연속 입력할 수 없습니다.");
          pw_passed = false;
        }
        if (SamePass_1 > 0 || SamePass_2 > 0) {
          alert("영문, 숫자는 3자 이상 연속 입력할 수 없습니다.");
          pw_passed = false;
        }
        if (!pw_passed) {
          return false;
        }
      }
      return true;
    }

    const onPassFocusout = (e) => {
      console.log("onfocus", e)
      if (!fn_pw_check()) {
        //pass_1.value = '';
        pass_2.value = '';
      }
    }

    const onOk = () => {
       if (!fn_pw_check()) {
        //pass_1.value = '';
        pass_2.value = '';
        return
      }

      apiCallPasswdreset();
    }


    const onCancle = () => {
      router.back()
    }
    return {
      isPassTable,
      inputMid,
      onPassFocusout,
      originPassword,
      pass_1,
      pass_2,
      onCancle,
      onOk
    }
  }
}
</script>

<style>

</style>
