<template>
  <!-- container start { -->
  <div id="container">
    <!-- content start { -->
    <div id="contents">
      <!-- login start { -->
      <div class="login-area">
        <h1 class="login-title">계속 하시려면<br/>먼저 본인인증을 진행해 주세요!</h1>
        <div class="login-section">
          <div class="login-tab">
            <div class="btn-login" style="padding-top: 90px;">
              <button type="button" class="btn btn-blue" @click="handleCertificate">본인 인증하기</button>
            </div>
          </div>
        </div>
      </div>
      <!-- } login end -->

    </div>
    <!-- } content end -->
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useAuthentication } from '@/composables/commonFn'
import useApp from '@/hooks/useApp'

export default {
  name: "certificate",
  setup () {
    const route = useRoute();
    const { saveQuery } = useApp()
    const { page, app, redirect_url: redirectUrl } = route.query
    const { getImpuid } = useAuthentication()

    function handleCertificate () {
      saveQuery()
      getImpuid(page, { app, redirectUrl })
    }

    return {
      handleCertificate
    }
  }
}
</script>

<style scoped>

</style>
