const env = process.env.VUE_APP_ENV || 'test'
const HOME_URL = {
  money: {
    test: 'https://flexmoney.co.kr',
    stage: 'https://flexmoney.co.kr',
    production: 'https://www.flexmoney.co.kr'
  }
}

function getEnv () {
  const origin = window.location.origin || ''
  if (origin.includes('stage')) {
    return 'stage'
  } else if (origin.includes('www')) {
    return 'production'
  }
  return env
}

export function getHomeUrl (partner) {
  return HOME_URL[partner][getEnv()] || ''
}
