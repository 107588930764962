<template>
    <!-- container start { -->
    <div id="container" style="max-width: 400px; margin: 0 auto;">
        <!-- content start { -->
        <div id="contents">

            <!-- sub title h2 start -->
            <!-- <div class="sub-title">
                <div class="position">
                    <h2 @click="$router.go(-1)">
                        <img src="@/assets/images/cm/ico_back.png" alt="back" class="mr-5"> 공지사항
                    </h2>
                </div>
            </div>   -->

            <!-- sub notice tab start { -->
            <!--div class="notice-tab">
                <div class="active"><router-link :to="{ name: 'CenterNotice'}">공지사항 </router-link></div>
                <div><router-link :to="{ name: 'CenterQuestions'}">자주하는 질문 </router-link></div>
                <div><router-link :to="{ name: isLogin ? 'CenterQnA' : 'Login' }">온라인문의 </router-link></div>
            </div-->
            <!-- } sub notice tab end -->

            <!-- board header start { -->
            <div class="board-header mt-40">
                <div class="form-select">
                    <select id="sel1" v-model="srchType" >
                        <option value="search_like" selected="selected">전체</option>
                        <option value="subject_like">제목</option>
                        <option value="contents_like">내용</option>
                        <!-- <option value="search_like">제목+내용</option> -->
                    </select>
                </div>
                <div class="srch-input">
                    <input type="text" name="inputSearch" id="inputSearch" class="w-100" placeholder="검색어를 입력해주세요." v-model="noticeKeyword" @keyup.enter="srchNotice" >
                    <button type="button" class="btn btn-lg srch-btn" @click="srchNotice">
                        <img src="@/assets/images/cm/ico_search.png" alt="search">
                    </button>
                </div>
            </div>
            <!-- } board header end -->

            <!-- notice start { -->
            <table class="tbl-type1 tbl-default mb-40">
                <colgroup>
                    <col style="width:100%">
                </colgroup>
                <tbody>
                    <tr v-for="item in noticeLists" :key="item.IDX">
                        <td class="subject">
                            <router-link :to="{ name: 'CenterNoticeView', params: { id:item.IDX }}">
                                <span class="title">{{ item.SUBJECT }}</span>
                                <span class="txt">{{ item.MODIFICATIONDATE.substr(0,10) }}</span>
                            </router-link>
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="noticeLists == null">
                        <td class="fs16 py-40">등록된 공지사항이 없습니다.</td>
                    </tr>
                </tbody>
            </table>
            <!-- } notice end -->

            <!-- paging start -->
            <div class="paging" v-if="noticeLists">
                <v-pagination
                    v-model="currentPage"
                    :pages= numberOfPages
                    :rangeSize="1"
                    activeColor="#fff"
                    @update:modelValue="updateHandler"
                />
            </div>
            <!-- <div class="paging">
                <a href="javascript:;" class="first"><img src="@/assets/images/cm/ico_paging_first.png" alt="처음"></a>
                <a href="javascript:;" class="prev"><img src="@/assets/images/cm/ico_paging_prev.png" alt="이전"></a>
                <a href="javascript:;" class="active">1</a>
                <a href="javascript:;">2</a>
                <a href="javascript:;">3</a>
                <a href="javascript:;" class="next"><img src="@/assets/images/cm/ico_paging_next.png" alt="다음"></a>
                <a href="javascript:;" class="last"><img src="@/assets/images/cm/ico_paging_last.png" alt="마지막"></a>
            </div> -->
            <!-- //paging end -->

        </div>
        <!-- } content end -->
    </div>
    <!--  } container end -->

    <Footer/>


</template>

<script>
import Footer from '@/components/Footer.vue';
import axios from '@/axios';
import { useRouter } from 'vue-router'
import { onMounted, ref, computed } from 'vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useStore } from 'vuex';

export default {
    components:{
        Footer,
        VPagination,
    },
    setup() {
        const store = useStore();
        const isLogin = computed(() => store.state.user.isLogin);
        const router = useRouter();
        const noticeLists = ref([]);
        const noticeKeyword = ref(null);
        const srchType = ref('search_like');
        const limit = 10;
        const currentPage = ref(1)
        const numberOfData = ref(0);
        const numberOfPages = computed(()=> {
            return Math.ceil(numberOfData.value/limit)
        });




        const srchNotice = () => {
            // console.log('search checked :::: ' + noticeKeyword.value + ' : ' + srchType.value);
            getNoticeList()
        }

        const getNoticeList = async( page = currentPage.value ) => {
            currentPage.value = page;
            let offset =  (currentPage.value -1) * limit;
            let getUrl = `board/noticelist?limit=${limit}&offset=${offset}`;
            if(noticeKeyword.value)
            {
                var addurl = `&${srchType.value}=${noticeKeyword.value}`;
                getUrl += addurl;
            }

            //console.log('url ============> ' + getUrl, noticeKeyword.value);

            try {
                const res = await axios.get(getUrl);
                const resData = res.data;

                if ( resData.returnCode == 1 ) {
                    if(resData.dataList == null) resData.dataList = [];
                    noticeLists.value = resData.resultMap.dataList;
                    numberOfData.value = resData.resultMap.dataCount;
                } else {
                    // console.log('error', json.message)
                    resData.value = [];
                    numberOfData.value  = 0;
                    // return null;
                }
            } catch(error) {
                //console.log(error);
                alert('데이터 불러오기에 실패하였습니다.\n확인 후 다시 시도하시기 바랍니다.');
                router.push({
                    name: 'Main',
                });
                return false;
            }
        }

        onMounted(()=> {
            store.commit('common/CHANGE_CURRENT_PAGE', 'CenterNotice');
            updateHandler(1);
        })

        const updateHandler = () => {
            getNoticeList();
        }

        return {
            noticeLists,
            noticeKeyword,
            srchType,
            srchNotice,

            numberOfPages,
            currentPage,
            updateHandler,
            isLogin
        }
    }


}
</script>

<style>

</style>
