<template>
  <!-- container start { -->
  <div id="container" class="sub-contents">
    <!-- content start { -->
    <div id="contents">

      <!-- sub title text start -->
      <div class="sub-title-txt">
        <p>아이디 찾기 결과<br>FLEX LIFE에 가입하신 아이디 정보입니다.</p>
      </div>
      <!-- //sub title text end -->

      <p class="p-sub-tit">아이디 확인</p>
      <div class="pw-finder-box">
        <div class="mb-10">
          <div class="id-finder-result">
            <div
                v-for="(item, index) in  findIds"
                :key=index
                class="finder-input-radio"

            >
              <input type="radio" name="rdo-1" :id="'rdo' + index" :value="item" @click="onSelectId(item)"
                     :checked="index==0?true:false">
              <label :for="'rdo' + index">{{ item }}</label>
            </div>

          </div>
        </div>
        <div class="id-finder-result" v-if="!findIds.length">
          <p class="text-center my-20">등록된 아이디가 없습니다. 다시 확인하여 주십시오.</p>
        </div>
      </div>

      <button @click="onOk" type="button" class="btn btn-xl w-100 btn-blue mt-3">확인</button>

    </div>
    <!-- } content end -->
  </div>
  <!--  } container end -->
  <Footer/>

</template>

<script>
import Footer from '@/components/Footer.vue';
import axios from '@/axios';
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import useApp from '@/hooks/useApp'
import { closeWindow } from '@/helpers/redirect'

export default {
  components: {
    Footer
  },


  setup() {
    let currentSelectId = null;
    const findIds = ref([]);
    const router = useRouter();
    const route = useRoute();
    const { nextUrl, isNativeApp } = useApp()

    const getFindId = async () => {
      try {
        const res = await axios.get(`/member/findmembermid?imp_uid=${JSON.parse(route.params.item).imp_uid}`);
        let json = res.data;
        console.log("getFindId:", json)
        findIds.value = json.resultMap.dataList
        if (!json.resultMap.dataList) {
          findIds.value = [];
        }
        currentSelectId = findIds.value[0];
      } catch (error) {
        console.log(error);
        return false;
      }
    }
    const onSelectId = (id) => {
      currentSelectId = id;
    }

    getFindId();
    const onOk = () => {
      console.log(nextUrl.value, isNativeApp())
      if (isNativeApp()) {
        window.location.href = nextUrl.value
        closeWindow()
        return
      }
      router.push({
        name: 'Login',
        params: {
          item: currentSelectId
          // item:'ttttt'
        }
      });
    }
    return {
      onOk,
      findIds,
      onSelectId

    }
  }

}
</script>

<style>

</style>
