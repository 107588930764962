<template>
  <div id="container" class="sub-contents">
    <!-- content start { -->
    <div id="contents" v-if="isSuccess">
      <!-- qna success start { -->
      <div class="qna-success">
        <h3>본인인증이 완료 되었습니다.</h3>
        <img src="@/assets/images/cs/qna-success.png" width="160" alt="온라인 문의가 정상 등록되었습니다.">
      </div>
      <!-- } qna success end -->

      <div class="mt-30 mb-50 d-flex-around">
        <router-link :to="{ name: 'Login' }" class="btn btn-xl btn-outline-gray w-50">로그인하러 가기</router-link>
      </div>

    </div>
    <!-- } content end -->
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/axios'

export default {
  name: "AddPersonalAuth",
  setup() {
    const route = useRoute();
    const isSuccess = ref(false);

    onMounted(() => {
      const params = JSON.parse(route.params.item)
      const token = sessionStorage.getItem('temp_token')
      if (!token) return

      axios.post('member/addauthinfo', {
        authtoken: token,
        imp_uid: params.imp_uid
      }).then((resp) => {
        const data = resp.data
        if (data.returnCode === 1 && data.resultMap?.returnVal === 1) {
          isSuccess.value = true
          sessionStorage.removeItem('temp_token')
        }
      })
    })

    return {
      isSuccess
    }
  }
}
</script>

<style scoped>

</style>
