<template>   
    <Modal> 
        <template #title>
        {{ '아이디 찾기'}}
        </template>

        <template #body>
            <div class="modal-us-title"><span class="fs20 bold">아이디 찾기 결과</span><br>FLEX LIFE에 가입하신 아이디 정보입니다.</div>
            <div class="modal-us-box mt-0">
                <div class="id-finder-result">
                    <div 
                        v-for="(item, index) in  props.findIds"
                        :key = index
                        class="finder-input-radio"
                    >
                        <input type="radio" name="rdo-1" id="rdo1" checked>
                        <label for="rdo1">{{item}}</label>
                    </div>
                    <!-- <div class="finder-input-radio">
                        <input type="radio" name="rdo-1" id="rdo2">
                        <label for="rdo2">FLEX ID 2222</label>
                    </div> -->
                </div>
                <div class="id-finder-result" v-if="!props.findIds.length">
                    <p class="text-center my-20">등록된 아이디가 없습니다. 다시 확인하여 주십시오.</p>
                </div>
            </div>
        </template>

        <template #footer>
            <button
                type="button" 
                class="btn btn-xl btn-blue w-100" 
                data-dismiss="modal"
                @click="onOk"
            >
                확인
            </button>
            
        </template>     
    </Modal>
    
</template>

<script>
import Modal from "@/components/Modal.vue"
//import {getCurrentInstance}from 'vue';
export default {
  components:{
      Modal
  }, 
  props:{
    findIds:{
           type:Array,
           require:true
    },
   
  },
  emits:['ok'],
  setup(props, { emit }){  
    // const { emit } = getCurrentInstance();
     const onOk = () =>{
        emit('ok')
     }     
     return {        
         onOk,  
         props
     }
   }

}
</script>

<style>

</style>