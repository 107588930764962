<template>
  <!-- container start { -->
  <div id="container" class="sub-contents">
    <!-- content start { -->
    <div id="contents">
      <!-- sub title text start -->
      <div class="sub-title-txt">
        <p>회원탈퇴 전 <span class="fc-blue">유의사항</span>을 확인해주세요.</p>
      </div>
      <!-- //sub title text end -->

      <p class="p-sub-tit">연동된 제휴사</p>
      <!-- table type 1 start -->
      <table class="tbl-type1 tbl-top-line">
        <colgroup>
          <col style="width:100%">
        </colgroup>
        <tbody>
        <tr
            v-for="(item) in partnersObj"
            :key=item.PARTNERS_IDX
        >
          <td v-if="item.ISAGREE==='Y' && item.PID !== 'flexlife'">
            {{ item.PARTNERS_NAME }}
          </td>
        </tr>
        </tbody>
      </table>
      <!-- //table type 1 end -->

      <p class="mt-10 mb-50 fs14 fc-gray-l">
        <span class="fc-blue">· FLEXLIFE</span> 아이디 탈퇴 시 모든 제휴사가 탈퇴됩니다.<br/>
        <span class="fc-blue">· 제휴사 해제</span> 를 원하시는 경우 <router-link to="modifyPartner">제휴사 연동</router-link> 메뉴를 이용하시기 바랍니다.
      </p>

      <p class="p-sub-tit">유의사항</p>
      <ul class="withdrawal-info">
        <li>FLEX 아이디를 탈퇴하시면 서비스 부정 이용 방지를 위하여 제휴사 회원정책에 따라 일정 기간 동안 회원 재 가입이 불가합니다.</li>
        <li>FLEX 제휴사 탈퇴 시, 제휴사에서 사용하시던 포인트 및 쿠폰 등은 복원할 수 없습니다.</li>
        <li>FLEX 제휴사에서 진행중인 주문 또는 이용중인 서비스가 있을 경우 탈퇴가 불가합니다.</li>
        <li>탈퇴 즉시 개인정보가 삭제되면, 어떠한 방법으로도 복원할 수 없습니다.(전자상거래 서비스 등의 거래내역은 전자상거래 등에서의 소비자보호에 관한 법률에 의거하여 보호됩니다.)</li>
      </ul>

      <div class="d-flex-between my-40">
        <button @click="onRemoveProCancle" type="button" class="btn btn-xl w-50">취소</button>
        <button @click="onRemoveProOk" type="button" class="btn btn-xl btn-red w-50 ml-10">회원탈퇴</button>
      </div>

    </div>
    <!-- } content end -->
  </div>
  <!--  } container end -->
  <Footer/>
  <BasicModal1b
      v-if="showModal"
      @close="closeModal"
      @ok="okModal"

      :titleTxt="titleTxt"
      :bodyTxt="bodyTxt"
  />

</template>

<script>
import { ref, onMounted } from 'vue'
import { closeWindow, createRedirectUrl } from '@/helpers/redirect'
import { useToast } from '@/composables/toast';
import BasicModal1b from '@/components/BasicModal1b.vue';
import Footer from '@/components/Footer.vue';
import { useRouter, useRoute } from 'vue-router';
import axios from '@/axios';
import $ from 'jquery';
import { useStore } from 'vuex';

export default {
  components: {
    Footer,
    BasicModal1b
  },
  setup() {
    const titleTxt = ref('내용 :')
    const bodyTxt = ref('회원탈퇴 성공했습니다.')
    const showModal = ref(false);
    const partnersObj = ref([]);
    const store = useStore();
    const {
      triggerToast
    } = useToast();

    onMounted(() => {
      store.commit('common/CHANGE_CURRENT_PAGE', 'RemoveProfile');
    })


    const router = useRouter()
    const route = useRoute()

    const onRemoveProCancle = () => {
      if (route.query.app && route.query.redirect_url) {
        window.location.href = createRedirectUrl(route.query.redirect_url)
        closeWindow(route.query.redirect_url)
        return
      }
      router.push({
        name: 'Main',
      });
    }

    const onRemoveProOk = () => {
      apiCallWithdrawal().then((success) => {
        if (success) {
          if (route.query.app && route.query.redirect_url) {
            window.location.href = createRedirectUrl(route.query.redirect_url)
            closeWindow(route.query.redirect_url)
            return
          }
          router.push({
            name: 'Login',
          });
        }
      })

    }
    const closeModal = () => {
      showModal.value = false;
    }
    const okModal = () => {
      showModal.value = false;
    }

    $
    const onCheckPartner = (item, e) => {
      console.log("onCheckPartner:", item, e)


      if (item.PID == 'flexlife') {
        if (e.target.checked) {
          for (let i = 0; i < partnersObj.value.length; i++) {
            let item = partnersObj.value[i];
            console.log(item.ISAGREE)
            if (item.ISAGREE == 'Y') {
              $("#" + item.PID).prop('checked', true)
            }
          }
        } else {
          for (let i = 0; i < partnersObj.value.length; i++) {
            let item = partnersObj.value[i];
            console.log(item.ISAGREE)
            if (item.ISAGREE == 'Y') {
              $("#" + item.PID).prop('checked', false)
            }
          }
        }
      }
    }

    const apiCallWithdrawal = async () => {
      if (!window.confirm('탈퇴하시겠습니까?')) return false

      const token = sessionStorage.getItem('token');
      try {
        const res = await axios.put(`loginmember/withdrawal`, null,{
          headers: {
            'token': token
          }
        });
        let json = res.data;
        console.log("apiCallWithdrawal:", json)
        if (json.returnCode == 1) {
          if (json.resultMap.returnVal == 1) {
            return true
          } else {
            triggerToast(json.message, 'danger');
            return false
          }
        } else {
          triggerToast(json.message, 'danger');
          return false
        }
      } catch (error) {
        triggerToast(error, 'danger');
        console.log("apiCallWithdrawal error", error);
        return false;
      }
    }

    const apiCallGetmypartners = async () => {
      var token = sessionStorage.getItem('token');
      try {
        const res = await axios.get(`loginmember/getmypartners`, {
          headers: {
            'token': token
          }
        });

        let json = res.data;
        partnersObj.value = json.resultMap.dataList;
        console.log("apiCallGetmypartners:", partnersObj.value)
      } catch (error) {
        console.log("apiCallGetmypartners error", error);
        return false;
      }
    }
    apiCallGetmypartners();

    function showExpireTerm(partner) {
      console.log(partner)
    }

    return {
      onRemoveProOk,
      onRemoveProCancle,
      closeModal,
      okModal,
      showModal,
      titleTxt,
      bodyTxt,
      partnersObj,
      showExpireTerm,
      onCheckPartner
    }
  }
}
</script>

<style>

</style>
