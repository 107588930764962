<template>
  <div>
    <BasicModal2b
        v-if="showModal"
        @close="onCancel"
        @ok="onOk"
        @cancle="onCancel"
        :titleTxt="titleTxt"
        :bodyTxt="bodyTxt"
    />
  </div>
</template>

<script>
import {ref} from 'vue'
import BasicModal2b from '@/components/BasicModal2b.vue';
import {useRouter} from 'vue-router';
import { closeWindow, createRedirectUrl } from '@/helpers/redirect'
import {useAuthentication} from '@/composables/commonFn';
import {useStore} from 'vuex';
import useApp from '@/hooks/useApp'
import {signInSns} from "@/helpers/auth";
import { getHomeUrl } from '@/helpers/partner'

export default {
  components: {
    BasicModal2b
  },
  setup() {
    const {URL} = window;
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    const router = useRouter();
    var accessToken = null;
    const titleTxt = ref('');
    const bodyTxt = ref('');
    const showModal = ref(false);
    const store = useStore();
    const { app, nextUrl } = useApp()
    const {
      getImpuid
    } = useAuthentication();

    const snsLogin = async () => {
      const params = {
        pid: "flexmoney",
        sns: urlParams.get('type'),
        sns_code: urlParams.get('code')
      }
      if (urlParams.get('type') == 'naver') {
        params.state = urlParams.get('state');
      }
      try {
        const json = await signInSns(params);
        console.log("snsLogin:~", json);
        if (json.returnCode === 1) {
          console.log("snsLogin success:", json);
          if (json.resultMap.returnVal == 1) {
            //sns로그인
            sessionStorage.setItem('token', json.resultMap.token);
            store.commit('user/CHANGE_IS_LOGIN', true);
            store.commit('user/CHANGE_MEMBER_DATA', json.resultMap.member);
            if (nextUrl.value) {
              window.location.href = createRedirectUrl((nextUrl.value || getHomeUrl(app.value)), json.resultMap.token)
              closeWindow((nextUrl.value || getHomeUrl(app.value)))
              return
            }
            if (app.value) {
              window.location.href = createRedirectUrl(getHomeUrl(app.value), json.resultMap.token)
              return
            }
            router.push({
              name: 'Profile',
            });
          } else if (json.resultMap.returnVal == -1005) {
            //sns연결안됨 회원가입 유도

            titleTxt.value = `SNS (${urlParams.get('type')}) 로그인`
            bodyTxt.value = "등록되지 않은 SNS 정보입니다. 회원가입을 하시겠습니까?"
            showModal.value = true;
            accessToken = json.resultMap.accessToken
            console.log("accessToken:", json.resultMap.accessToken)
          } else if (json.resultMap.returnVal === -1006) {
            if (!window.confirm('로그인을 위해 본인인증이 필요한 계정입니다.이동하시겠습니까?')) return
            sessionStorage.setItem('temp_token', json.resultMap.token);
            router.push({
              path: '/certificate',
              query: {
                page: 'AddPersonalAuth',
                app: app.value,
                redirect_url: (nextUrl.value || getHomeUrl(app.value)) + `?token=${json.resultMap.token}`
              }
            })
          } else {
            if (json.message) {
              window.alert(json.message)
            } else {
              window.alert('알수 없는 오류가 발생하였습니다.')
            }
            window.location.href =createRedirectUrl(`/login?app=${app.value || ''}`)
          }
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    }

    const onCancel = () => {
      showModal.value = false;
      router.push({
        name: 'Main',
      });
      console.log("onCancel");
    }

    const onOk = () => {
      showModal.value = false;
      getImpuid('Signup', {sns: urlParams.get('type'), app: app.value, accessToken});
    }

    snsLogin()

    return {
      onCancel,
      onOk,
      showModal,
      titleTxt,
      bodyTxt
    }
  }

}
</script>

<style>

</style>
