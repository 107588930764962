import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { MONEY_APP_SCHEME } from '@/consts/urls'

const appDataKey = 'session.app'
const nextUrlDataKey = 'session.next_url'

export default function useApp() {
  const app = ref(null)
  const nextUrl = ref(null)
  const route = useRoute();

  if (nextUrl.value === null) {
    nextUrl.value = getNextUrl()
  }
  if (app.value === null) {
    app.value = getApp()
  }

  function saveQuery(redirectUrl = null) {
    const urlValue = redirectUrl || route.query.redirect_url
    const appValue = route.query.app ?? ''

    window.sessionStorage.setItem(nextUrlDataKey, urlValue)
    window.sessionStorage.setItem(appDataKey, appValue)

    nextUrl.value = urlValue
    app.value = appValue
  }

  function getNextUrl() {
    if (!window) return ''
    if (route.query.redirect_url) return route.query.redirect_url

    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('redirect_url')) return searchParams.get('redirect_url')

    return window.sessionStorage.getItem(nextUrlDataKey) ?? ''
  }

  function getApp() {
    if (!window) return ''
    if (route.query.app) return route.query.app

    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('app')) return searchParams.get('app')

    return window.sessionStorage.getItem(appDataKey) ?? ''
  }

  function isNativeApp() {
    return (nextUrl.value ?? '').startsWith(MONEY_APP_SCHEME)
  }

  function clear() {
    window.sessionStorage.removeItem(nextUrlDataKey)
    window.sessionStorage.removeItem(appDataKey)
    nextUrl.value = ''
    app.value = ''
  }

  return { nextUrl, app, isNativeApp, saveQuery, clear }
}
