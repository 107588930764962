<template>
<div id="footer">
  <div class="foot-area">
    <div class="foot-info">
        <ul>
            <li><router-link :to="{ name: 'Policy'}">이용약관</router-link></li>
            <li><router-link :to="{ name: 'Privacy'}">개인정보방침</router-link></li>
            <!-- <li><a href="javascript:;">운영정책</a></li>
            <li><a href="javascript:;">청소년보호정책</a></li> -->
        </ul>
    </div>
    <div class="foot-address">
        <p>
            <span>플렉스라이프</span>
            <span>회사명 : 주식회사플렉스이엔엠</span>
        </p>
        <p>
            <span>대표 : 김지수</span>
            <span>사업자등록번호 : 581-81-02149</span>
            <span class="link"><a href="#" @click="bizpopup">사업자번호조회</a></span>
        </p>
        <p>
            <span>통신판매업 신고번호 : 제 2020-서울서초-4378호</span>
            <span>서울특별시 강남구 역삼로12길 18</span>
            <span><a href="mailto:help@flexenm.com">help@flexenm.com</a></span>
            <span>대표번호 : 1566-7381</span>
        </p>
        <p class="foot-copy">COPYRIGHT &copy; 플렉스이엔엠 All rights reserved.</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  setup() {
    const url = 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5828102149&apv_perm_no=';
    const bizpopup = () => {
      window.open(url, 'bizCommPop', 'width=750, height=700');
    }

    return {
      bizpopup
    }
  }
}
</script>

<style>

</style>
