<template>        
    <!-- container start { -->
    <div id="container">
        <!-- content start { -->
        <div id="contents">

            <!-- page navigation -->
            <div class="breadcrumb">
                <ul>
                    <li class="home"><img src="@/assets/images/cm/ico_home.png" alt="home"></li>
                    <li>FLEX소개</li>
                    <li>이벤트</li>
                </ul>
            </div>

            <!-- sub title h2 start { -->
            <div class="sub-title">
                <h2>이벤트</h2>
            </div>
            <!-- } sub title h2 end -->

            <!-- sub title h3 start { -->
            <div class="sub-title-lg">
                <h3>FLEX회원만을 위한 멤버쉽이벤트에 지금 참여하세요.</h3>
            </div>
            <!-- } sub title h3 end -->

            <!-- event button start { -->
            <div class="text-left mb-10 event-header d-flex-between">
                <div class="left-btns">
                    <button type="button" class="btn btn-lg btn-blue" onclick="location.href='FLEX-LIFE-CS-006.html';">
                        진행중인 이벤트
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                    </button>
                    <!-- <button type="button" class="btn btn-lg btn-outline-gray" onclick="location.href='FLEX-LIFE-CS-006.html';">
                        당첨자 발표
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                    </button> -->
                </div>
                <div class="right-btns">
                    <button type="button" class="btn btn-lg btn-gray" onclick="location.href='FLEX-LIFE-CS-006.html';">
                        전체 이벤트
                    </button>
                </div>
            </div>
            <!-- } event button end -->

            <!-- event list start { -->
            <div style="margin:20px 0; border-top:1px solid #000; border-bottom:1px solid #ddd; padding:50px 0;">
                <p class="fs20 font-bold text-center">진행 중인 이벤트가 없습니다.</p>
            </div>
            <!-- <ul class="event-list">
                <li>
                    <a href="FLEX-LIFE-FI-006.html">
                        <h4 class="event-title">6월 Special 혜택</h4>
                        <button type="button" class="btn btn btn-outline-gray more">
                            자세히 보기
                            <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <img src="@/assets/images/fi/event_01.png" alt="6월 Special 혜택" />
                    </a>
                </li>
                <li>
                    <a href="FLEX-LIFE-FI-006.html">
                        <h4 class="event-title">올리브영과 함께 하는 컬러업 챌린지</h4>
                        <button type="button" class="btn btn btn-outline-gray more">
                            자세히 보기
                            <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <img src="@/assets/images/fi/event_02.png" alt="올리브영과 함께 하는 컬러업 챌린지" />
                    </a>
                </li>
                <li>
                    <a href="FLEX-LIFE-FI-006.html">
                        <h4 class="event-title">영화 &lt;헤어질 결심&gt; 예매권 증정 이벤트</h4>
                        <button type="button" class="btn btn btn-outline-gray more">
                            자세히 보기
                            <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <img src="@/assets/images/fi/event_03.png" alt="영화 &lt;헤어질 결심&gt; 예매권 증정 이벤트" />
                    </a>
                </li>
                <li>
                    <a href="FLEX-LIFE-FI-006.html">
                        <h4 class="event-title">앱 첫 쇼핑 초특가 선물 혜택</h4>
                        <button type="button" class="btn btn btn-outline-gray more">
                            자세히 보기
                            <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <img src="@/assets/images/fi/event_04.png" alt="앱 첫 쇼핑 초특가 선물 혜택" />
                    </a>
                </li>
                <li>
                    <a href="FLEX-LIFE-FI-006.html">
                        <h4 class="event-title">나만의 카드 꾸미고 최대 100만 포인트 당첨</h4>
                        <button type="button" class="btn btn btn-outline-gray more">
                            자세히 보기
                            <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <img src="@/assets/images/fi/event_05.png" alt="나만의 카드 꾸미고 최대 100만 포인트 당첨" />
                    </a>
                </li>
                <li>
                    <a href="FLEX-LIFE-FI-006.html">
                        <h4 class="event-title">프린트베이커리 온라인몰 할인 이벤트</h4>
                        <button type="button" class="btn btn btn-outline-gray more">
                            자세히 보기
                            <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <img src="@/assets/images/fi/event_06.png" alt="프린트베이커리 온라인몰 할인 이벤트" />
                    </a>
                </li>
            </ul> -->
            <!-- } event list end -->
            
            <!-- paging start -->
            <div class="paging">
                <a href="javascript:;" class="first"><img src="@/assets/images/cm/ico_paging_first.png" alt="처음"></a>
                <a href="javascript:;" class="prev"><img src="@/assets/images/cm/ico_paging_prev.png" alt="이전"></a>
                <a href="javascript:;" class="active">1</a>
                <a href="javascript:;">2</a>
                <a href="javascript:;">3</a>
                <a href="javascript:;" class="next"><img src="@/assets/images/cm/ico_paging_next.png" alt="다음"></a>
                <a href="javascript:;" class="last"><img src="@/assets/images/cm/ico_paging_last.png" alt="마지막"></a>
            </div>
            <!-- //paging end -->

        </div>
        <!-- } content end -->
    </div>
    <!--  } container end -->
    <Footer/>

  
</template>

<script>
import Footer from '@/components/Footer.vue';
export default {
components:{   
    Footer,
 }
}
</script>

<style>

</style>