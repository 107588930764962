<template>
	<!-- container start { -->
	<div id="container" class="sub-contents">
		<!-- content start { -->
		<div id="contents">
			<!-- sub title h2 start -->
			<!-- <div class="sub-title">
                <div class="position">
                    <h2 @click="$router.go(-1)">
                        <img src="@/assets/images/cm/ico_back.png" alt="back" class="mr-5"> 회원정보
                    </h2>
                </div>
            </div>   -->

			<!-- sub title h2 start { -->
			<div class="sub-title select-header">
				<form action="" method="get" class="srch-form">
					<div class="form-select mb-10">
						<select name="terms" id="terms">
							<option value="now">현재 FLEXLIFE 이용약관</option>
							<!-- <option value="2022-07-05">FLEXLIFE 이용약관(2022.07.05)</option> -->
						</select>
					</div>
					<!-- <div class="w-100 my-10">
                        <button type="submit" class="btn btn-lg w-100" >검색</button>
                    </div> -->
				</form>
			</div>
			<!-- } sub title h2 end -->

			<!-- company information start { -->
			<div class="terms-policy-content">
				<h1>제1조 (목적)</h1>
				<p>
					본 약관은 FLEXLIFE회원(아래에서 정의)이 플렉스이앤앰 주식회사(이하
					"당사"라 함)가 제공하는 FLEXLIFE 서비스를 이용함에 있어 FLEXLIFE회원과
					당사의 제반 권리·의무 및 관련절차 등을 규정하는데 그 목적이 있습니다.
				</p>
				<br />
				<h1>제2조 정의</h1>
				<div>
					① “서비스”라 함은 접속 가능한 유,무선 단말기의 종류와는 상관 없이
					이용자(또는 회원)이 이용 할 수 있는 제반 “서비스”를 의미합니다.<br />
					② “이용자”이라 함은 회사의 “서비스”에 접속하여 이 약관에 따라 “회사”가
					제공하는 “서비스”를 이용하는 회원, 비회원 고객을 말합니다.<br />
					③ “회원”이라 함은 회원등록을 한 자로서, 회사의 정보를 지속적으로
					제공받으며, 서비스를 계속적으로 이용할 수 있는 자를 말합니다. 방송을
					진행하는 회원 중에는 회사의 지원을 받아 서비스를 이용하는 회원도
					있습니다.<br />
					④ “비회원”이란 회원으로 가입하지 않고 회사가 제공하는 서비스를
					이용하는 자를 말합니다.<br />
					⑤ "아이디(ID)"라 함은 "이용자"의 식별과 "서비스" 이용을 위하여
					"이용자"이 정하고 "회사"가 승인하는 문자와 숫자의 조합을
					의미합니다.<br />
					⑥ "비밀번호"라 함은 "이용자"가 부여 받은 "아이디”와 일치되는
					"이용자"임을 확인하고 비밀보호를 위해 "이용자" 자신이 정한 문자 또는
					숫자의 조합을 의미합니다.<br />
					⑦ "유료서비스"라 함은 "회사" 또는 "회원"이 유료로 제공하는 각종 온라인
					디지털 컨텐츠(각종 정보 컨텐츠, VOD, 플렉스(캐쉬), 아이템 기타 유료
					컨텐츠를 포함) 및 제반 서비스를 의미합니다.<br />
					⑧ "게시물"이라 함은 이용자가 "서비스"를 이용함에 있어 "서비스상"에
					게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진,
					동영상 및 각종 파일과 링크 등을 의미합니다.
				</div>
				<br />
				<h1>제3조 약관의 효력과 변경</h1>
				<div>
					① 본 약관의 내용은 홈페이지 및 서비스 화면에 이를 공시함으로써 효력을
					발생합니다.<br />
					② “회사”는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호
					등에 관한 법률”등 관련법에 위배하지 않는 범위에서 이 약관을 변경할 수
					있으며, 약관을 변경할 경우에는 “이용자”이 쉽게 알 수 있도록 서비스
					초기화면에 최소 15일 전부터 공지합니다. 다만, 이용자에게 불리한 약관의
					개정의 경우에는 그 적용일자 30일전부터 적용일 이후 상당기간 동안 각각
					이를 서비스 홈페이지에 공지하여 그 내용을 확인할 수 있게 됩니다.<br />
					③ 이용자는 변경된 약관에 대해 동의하지 않을 권리가 있으며,
					시행일로부터 10일(이용자에게 불리하거나 중대한 사항의 변경인 경우에는
					30일) 내에 변경된 약관에 대해 거절의 의사를 표시하지 않았을 때에는 본
					약관의 변경에 동의한 것으로 간주합니다<br />
					④ 이용자는 변경된 약관에 동의하지 않으면, 언제나 “서비스” 이용을
					중단하고, 이용계약을 해지할 수 있습니다. 약관의 효력 발생일 이후의
					계속적인 “서비스” 이용은 약관의 변경사항에 대한 이용자의 동의로
					간주됩니다.
				</div>
				<br />
				<h1>제4조 서비스의 구분</h1>
				<div>
					① “회사”가 이용자에게 제공하는 “서비스”는 무료서비스, 유료서비스
					등으로 구분합니다.<br />
					② 무료서비스, 유료서비스 등의 종류와 이용방법 등은 이 약관 및 "회사"가
					공지 또는 이용안내에서 별도로 정하는 바에 의합니다.<br />
					③ 서비스 이용등급은 콘텐츠 초기 화면에 표시된 이용등급정보에 따르며,
					"회사"는 이용자에 대하여 "청소년보호법" 등에 따른 등급 및 연령 준수를
					위해 이용제한이나 등급별 제한을 할 수 있습니다.
				</div>
				<br />
				<h1>제5조 이용계약의 성립 및 체결단위</h1>
				<div>
					① 이용계약은 이용자의 이용신청에 대한 "회사"의 이용승낙으로
					성립합니다.<br />
					② 이용계약은 아이디 단위로 체결합니다.<br />
					③ "서비스"의 대량이용 등 특별한 "서비스" 이용에 관한 계약은 별도의
					계약으로 합니다.
				</div>
				<br />
				<h1>제6조 이용신청</h1>
				<div>
					① 이용자가 "서비스"의 이용을 원할 경우 인터넷 접속 등의 방식으로
					"회사"가 소정의 가입신청 양식에서 요구하는 사항을 기입하여 이용신청을
					하여야 합니다.<br />
					② "회사"는 이 약관의 주요 내용을 이용자에게 고지하여야 합니다.<br />
					③ 이용자가 전항의 고지 하단에 있는, 동의 버튼을 클릭하거나 또는
					체크박스에 체크하고 이용신청을 하는 것은 이 약관에 대한 동의로
					간주됩니다.<br />
					④ 이용신청은 이용자 자신의 실명으로 하여야 합니다.<br />
					⑤ 이용자는 회사가 제공하는 본인확인 방법을 통하여 서비스 이용신청을
					하여야 하며, 본인명의로 등록하지 않은 이용자는 일체의 권리를 주장할 수
					없습니다.<br />
					⑥ 타인의 정보(아이핀 및 휴대폰정보 등)를 도용하여 이용신청을 한
					이용자의 허위계정은 서비스 이용이 정지됩니다.<br />
					⑦ “이용신청 고객”이 미성년자(만 14세 미만)인 경우 이용이 불가능할 수
					있스며, 서비스마다 상이할 수 있습니다.
				</div>
				<br />
				<h1>제7조 이용신청의 승낙</h1>
				<div>
					① "회사"는 전조의 규정에 의한 이용신청에 대하여 업무수행상 또는 기술상
					지장이 없는 경우에는 원칙적으로 지체 없이 이용신청을 승낙합니다.<br />
					② "회사"는 이용신청을 승낙하는 때에는 다음 각호의 사항을 이용자에게
					"서비스"를 통하거나, 전자우편등의 방법으로 통지합니다.<br />
					<p class="pl-20">
						1. 아이디<br />
						2. 이용자의 권익보호 및 의무 등에 관한 사항<br />
						3. 기타 이용자가 "서비스" 이용 시 알아야 할 사항
					</p>
				</div>
				<br />
				<h1>제8조 이용신청에 대한 불승낙과 승낙의 보류</h1>
				<div>
					① "회사"는 다음 각호에 해당하는 이용신청에 대하여는 승낙을 하지 아니
					할 수 있습니다.<br />
					<p class="pl-20">
						1. 제6조 또는 제12조 제6항을 위반한 경우<br />
						2. 허위의 신청이거나 허위서류를 첨부한 경우<br />
						3. 이용자가 회사에 납부하여야 할 요금등을 납부하지 않은 경우<br />
						4. 기타 이용자의 귀책사유로 이용승낙이 곤란한 경우<br /><br />
					</p>
					② "회사"는 다음 각호에 해당하는 사유가 있는 경우에는 그 사유가 해소될
					때까지는 승낙을 보류할 수 있습니다.<br />
					<p class="pl-20">
						1. 회사의 설비에 여유가 없는 경우<br />
						2. "서비스"에 장애가 있는 경우<br />
						3. 제6조 제7항에 해당하는 경우
					</p>
				</div>
				<br />
				<h1>제9조 아이디 부여등</h1>
				<div>
					① "회사"는 원칙적으로 이용자가 신청한대로 아이디를 부여합니다.<br />
					② 아이디는 변경할 수 없는 것을 원칙으로 합니다.<br />
					③ 아이디 또는 닉네임이 제12조 제6항 각호에 해당하는 경우에는 "회사"는
					해당 이용자에 대한 "서비스" 제공을 중단하고, 새로운 아이디 또는
					닉네임으로 이용신청 할 것을 권할 수 있습니다.
				</div>
				<br />
				<h1>제10조 회사의 의무</h1>
				<div>
					① "회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지
					않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여
					노력합니다.<br />
					② "회사"는 계속적이고 안정적인 "서비스"의 제공을 위하여 설비에 장애가
					생기거나 멸실된 때에는 이를 지체 없이 수리 또는 복구하며, 다음 각 호의
					사유 발생 시 부득이한 경우 예고 없이 서비스의 전부 또는 일부의 제공을
					일시 중지할 수 있습니다. 이 경우 그 사유 및 중지 기간 등을 이용자에게
					지체 없이 사후 공지합니다.<br />
					<p class="pl-20">
						1. 회사가 긴급한 시스템 점검, 증설, 교체, 시설의 보수 또는 공사를
						하기 위하여 부득이한 경우<br />
						3. 시스템 또는 기타 서비스 설비의 장애, 유무선 Network 장애 등으로
						정상적인 서비스 제공이 불가능할 경우<br />
						4. 천재지변, 국가비상사태, 정전 등 회사가 통제할 수 없는 불가항력적
						사유로 인한 경우<br /><br />
					</p>
					③ "회사"는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의
					계약관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록
					노력합니다.<br />
					④ “회사”는 대표자의 성명, 상호, 주소, 전화번호, 모사전송번호(FAX),
					통신판매업 신고번호, 이용약관, 개인정보취급방침 등을 이용자가 쉽게 알
					수 있도록 온라인 서비스 초기화면에 게시합니다.
				</div>
				<br />
				<h1>제11조 개인정보보호</h1>
				<div>
					"회사"는 이용자들의 개인정보를 중요시하며, 정보통신망법,
					개인정보보호법, 전기통신사업법 등 관련 법규를 준수하고 있습니다.
					"회사"는 개인정보보호방침을 통하여 이용자가 제공하는 개인정보가 어떠한
					용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가
					취해지고 있는지 알려드립니다.
				</div>
				<br />
				<h1>제12조 이용자의 의무</h1>
				<div>
					① 이용자는 회원가입을 통해 이용신청을 할 경우 사실에 근거하여
					작성하여야 합니다. 이용자가 허위, 또는 타인의 정보를 등록한 경우
					회사에 대하여 일체의 권리를 주장할 수 없으며, 회사는 이로 인하여
					발생하는 손해에 대하여 책임을 부담하지 않습니다.<br />
					② 이용자는 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정,
					회사가 공지하는 사항을 준수하여야 합니다. 또한 이용자는 회사의 업무에
					방해가 되는 행위, 회사의 명예를 손상시키는 행위를 해서는 안됩니다.<br />
					③ 이용자는 청소년 보호법 등 관계 법령을 준수하여야 합니다. 이용자는
					청소년 보호법 등 관계 법령을 위반한 경우는 해당 법령에 의거 처벌을
					받게 됩니다.<br />
					④ 이용자는 주소, 연락처, 전자우편 주소 등 이용계약 사항이 변경된
					경우에 즉시 온라인 상에서 이를 수정해야 합니다. 수정을 하지 않거나
					수정 지연으로 발생되는 책임은 이용자에게 있습니다.<br />
					⑤ 이용자는 서비스 아이디와 비밀번호를 직접 관리해야 합니다. 이용자의
					관리 소홀로 발생한 문제는 회사에서 책임지지 않습니다.<br />
					⑥ 이용자가 아이디, 닉네임, 기타 서비스 내에서 사용되는 명칭 등의 선정
					시에는 다음 각 호에 해당하는 내용을 사용하여서는 안됩니다.<br />
					<p class="pl-20">
						1. 회사가 제공하는 서비스의 공식 운영자를 사칭하거나 유사한 명칭을
						사용하여 다른 이용자에게 혼란을 초래하는 행위<br />
						2. 선정적이고 음란한 내용이 포함된 명칭의 사용<br />
						3. 제3자의 상표권, 저작권 등의 권리를 침해할 가능성이 있는 명칭의
						사용<br />
						4. 비어, 속어라고 판단되거나 반사회적이고 공서양속을 해치는 내용이
						포함된 명칭의 사용<br />
						5. 주민등록번호, 전화번호 등 개인정보 유출 또는 사생활 침해의 우려가
						있는 경우<br />
						6. 관계법령에 저촉되거나 기타 이용자의 보호를 위한 합리적인 사유가
						있는 경우<br /><br />
					</p>
					⑦ 이용자는 회사의 명시적 동의가 없는 한 서비스 이용 권한, 기타 이용
					계약상의 지위를 타인에게 매도, 증여할 수 없으며 무형자산을 담보로
					제공할 수 없습니다.<br />
					⑧ 이용자는 회사에서 제공하는 서비스를 본래의 이용목적 이외의 용도로
					사용해서는 안됩니다. 이용자는 아래 각 호의 행위를 하여서는 안되며,
					이에 해당되는 행위를 할 경우에는 본 약관 및 각 서비스 별로 공지하는
					운영정책에 따라 이용자의 플렉스이엔엠 서비스 이용을 제한하거나 계정의
					삭제, 수사기관의 고발 조치 등 적법한 조치를 포함한 제재를 가할 수
					있습니다.<br />
					<p class="pl-20">
						1. 회원 가입 또는 변경 시 개인정보에 허위 내용을 기재하는 행위<br />
						2. 타인의 개인 정보를 도용하거나 부정하게 사용하는 행위<br />
						3. 이용자의 아이디 등 사이버 자산을 타인과 매매하는 행위<br />
						4. 회사의 운영진 또는 직원을 사칭하는 행위<br />
						5. 회사 프로그램 상의 버그를 악용하는 행위<br />
						6. 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 하는 행위<br />
						7. 다른 이용자를 희롱 또는 위협하거나 다른 이용자에게 고통, 피해,
						또는 불편을 주는 행위<br />
						8. 회사로부터 승인 받지 아니하고 각 서비스의 클라이언트 프로그램을
						변경하거나, 서버를 해킹하거나 또는 웹사이트의 일부분을 변경하는
						행위, 또는 이러한 목적으로 개발, 유포되는 프로그램을 사용하거나 이의
						사용을 장려 또는 광고하는 행위<br />
						9. 시청 또는 광고 조회수 조작, 기타 부정한 목적으로 다량의 정보를
						전송하여 서비스의 안정적인 운영을 방해하는 행위<br />
						10. 회사 또는 다른 이용자의 게시물, 영상 기타 광고의 전송을
						방해하거나 훼손, 삭제하는 행위<br />
						11. 회사의 승인을 받지 않고 다른 이용자의 개인정보를 수집 또는
						저장하는 행위<br />
						12. 수신자의 의사에 반하는 광고성 정보, 전자우편을 지속적으로
						전송하는 경우<br />
						13. 타인의 저작권, 상표권, 특허권 등의 지적재산권을 침해하는 경우<br />
						14. 회사의 허가 없이 소프트웨어 또는 하드웨어를 이용하여 서비스의
						내용에 권한 없이 관여하는 소프트웨어 혹은 하드웨어를 사용,
						배포하거나 사용을 장려하거나 이에 대한 광고를 하는 행위<br />
						15. 회사가 제공하는 서비스 프로그램의 이용방식, 기획의도를
						변경하거나 서비스에 비정상적으로 위해를 가하거나 고의로 방해하는
						일체의 행위<br />
						16. 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용
						외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나,
						제3자에게 제공하는 행위<br />
						17. 공공질서 및 미풍양속에 위반되는 저속하거나 음란한 내용의 정보,
						문장, 도형, 음향, 동영상 또는 불법 복제된 소프트웨어를 전송, 게시,
						전자우편 또는 기타 방법으로 타인에게 유포하는 등 불법적인 목적으로
						서비스를 이용하는 행위<br />
						18. 결제기관을 속여 부정한 방법으로 결제하거나
						지불거절(Chargeback)을 악용하여 정당한 이유 없이 유료서비스를
						구매하거나 환불하는 경우<br />
						19. 정당한 후원의 목적 외에 자금을 제공 또는 융통하기 위해
						휴대폰이나 상품권 등을 통해 유료서비스를 이용, 결제, 구매, 환전,
						환불하는 행위. 이를 돕거나 권유, 알선, 중개 기타 광고하는 행위<br />
						20. 방송통신위원회로부터 이용제한 심의, 의결을 받은 경우<br />
						21. 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을
						위반 하는 행위 및 기타 관계 법령에 위배되는 행위
					</p>
				</div>
				<br />
				<h1>제13조 서비스의 제한 등</h1>
				<div>
					① "회사"는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가
					발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한
					기간통신사업자가 전기통신서비스를 중지하는 등 부득이한 사유가 있는
					경우에는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.<br />
					② 무료서비스는 전항의 규정에도 불구하고, "회사"의 경영정책 등의 사유로
					서비스의 전부 또는 일부가 제한되거나 중지될 수 있으며, 유료로 전환될
					수 있습니다.<br />
					③ "회사"는 "서비스"의 이용을 제한하거나 정지하는 때와 무료서비스를
					유료로 전환하는 때에는 그 사유 및 제한기간, 예정일시 등을 지체 없이
					이용자에게 알려야 합니다.<br />
					④ 회사는 최종 사용일로부터 연속하여 1년 동안 서비스 사용 이력이 없는
					경우 정보통신망법에 따라 개인정보가 파기되는 사실, 기간 만료일 및
					파기되는 개인정보의 항목 등을 이용자에게 본 약관 제16조 제1항의
					방법으로 알린 후 회원정보를 영구적으로 삭제할 수 있습니다. 단,
					유료결제 상품을 보유 하고 있을 경우 삭제 대상에서 제외 되며 관련
					법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한
					일정기간 동안 회원정보를 보관합니다.
				</div>
				<br />
				<h1>제14조 서비스의 제공중단, 이용제한 등</h1>
				<div>
					① "회사"는 이용자가 유료서비스의 요금등을 지정한 기일로부터 1개월 이상
					납부하지 않을 경우에는 전부 또는 일부의 서비스제공을 중단할 수
					있습니다.<br />
					② 제12조의 규정에 의하여 이용자의 이용을 제한하는 경우, 제한의 종류 및
					기간 등 구체적인 기준은 "회사"의 운영정책, 공지, 이용안내 등에서
					별도로 정하는 바에 따릅니다.<br />
				</div>
				<br />
				<h1>제15조 이용제한 및 해제절차</h1>
				<div>
					① "회사"가 이용제한을 하고자 하는 때에는 그 사유, 일시 및 기간을
					정하여 사전에 전화 또는 전자우편 등의 방법으로 해당 이용자에게
					통지합니다. 다만, 긴급하게 이용을 제한할 필요가 있을 경우에는 그러하지
					않습니다.<br />
					② 전항의 규정에 의하여 이용정지의 통지를 받은 이용자는 그 이용정지의
					통지에 대하여 이의가 있을 때에는 이의신청을 할 수 있습니다.<br />
					③ "회사"는 이용정지 기간 중에 그 이용정지 사유가 해소된 것이 확인된
					경우에는 이용정지조치를 즉시 해제합니다.<br />
					④ "회사"는 이용자의 부당행위가 고의 또는 과실이 없었음이 입증된
					경우에는 이용자가 이용중인 유료 서비스에 대한 이용기간을 정지된
					기간만큼 연장해주도록 합니다.
				</div>
				<br />
				<h1>제16조 서비스의 해제, 해지 등</h1>
				<div>
					① 이용자가 이용 계약을 해지하고자 하는 때에는 본인이 직접 “회사”의
					서비스 홈페이지를 통하거나 서면 또는 전화의 방법으로 “회사”에
					신청하여야 합니다.<br />
					② “회사”는 전항의 규정에 의하여 해지 신청이 접수되면 지체 없이
					서비스의 이용을 제한합니다. 단, 이용자는 30일 이내의 유예 기간 동안
					이용 계약 해지를 철회할 수 있습니다. <br />
					③ “회사”는 이용계약을 해지하고자 할 경우에는 해지조치 7일전까지 그
					뜻을 이용자에게 통지하여 의견을 진술할 기회를 주어야 합니다. 다만,
					다음 각호의 어느 하나에 해당하는 경우에는 즉시 해지를 할 수
					있습니다.<br />
					<p class="pl-20">
						1. 이용자가 관련 법령 또는 약관 기타 서비스 취지에 반하는 중대한
						위법, 부정행위를 한 경우<br />
						2. 타인명의 이용신청 또는 허위의 이용신청, 허위서류를 첨부한
						이용신청임이 확인된 경우<br />
						3. 이용자가 명백히 약관상 의무이행을 거절하는 의사를 표시하거나 즉시
						탈퇴처리에 동의한 경우<br /><br />
					</p>
					④ “회사”는 해지된 이용자의 이용신청에 대하여 해지 후 일정기간 동안에는
					불승낙을 할 수 있습니다<br />
					⑤ “회사”는 계약이 해지된 이용자가 "서비스"에 제공하거나, 보관 중인
					자료 및 제공한 개인정보를 삭제하고, 이와 관련하여 어떠한 책임도
					부담하지 않습니다. 단, 제19조와 제22조에 따라 별도의 계약이 성립된
					자료는 삭제하지 않을 수 있습니다.<br />
					⑥ “회사”는 통합 아이디를 통해 가입한 개별 서비스가 1건 이상 존재하는
					경우. 이를 통합 회원 이용으로 간주하며, 이용중인 서비스가 완료되지
					않았거나 Cash가 남아있을 경우 유료 서비스 이용약관 제9조에 따라 해지
					신청을 거부할 수 있습니다. 원치 않을 경우, 회원은 “회사”가 정한 별도의
					이용 방법으로 해지 신청을 할 수 있습니다.
				</div>
				<br />
				<h1>제17조 손해배상</h1>
				<div>
					① "회사"는 무료서비스의 장애, 제공 중단, 보관된 자료 멸실 또는 삭제,
					변조 등으로 인한 손해에 대하여는 배상하지 않습니다. 다만, 회사의 고의
					또는 중대한 과실로 인하여 발생한 경우는 제외합니다.<br />
					② "회사"는 "회사"의 귀책사유로 인하여 이용자에게 서비스를 제공하지
					못하는 경우 유료 서비스 이용자에게는 공정거래위원회가 고시한
					소비자분쟁해결기준에 따라 보상합니다.<br />
					③ "회사"는 "콘텐츠"의 하자, 이용중지 또는 장애 등에 의하여 발생한
					"이용자"의 손해에 대하여 보상 또는 배상합니다.<br />
					④ 제3항에 따른 손해배상 등의 방법 및 절차는 "콘텐츠이용자보호지침"에
					따라 처리하며, 기타 손해배상의 방법, 절차 등은 관계법령에 따릅니다.
				</div>
				<br />
				<h1>제18조 면책</h1>
				<div>
					① "회사"는 이용자가 "회사"의 "서비스" 제공으로부터 기대되는 이익을
					얻지 못한 데 대하여 책임이 없습니다.<br />
					② "회사"는 제3자가 게시 또는 전송한 자료로 인한 이용자의 손해에
					대하여는 책임이 없습니다.<br />
					③ "회사"는 이용자 상호간 또는 이용자와 제3자 간에 "서비스"를 매개로
					발생한 분쟁에 대하여는 개입할 의무도 이로 인한 손해를 배상할 책임도
					없습니다.<br />
					④ “회사”의 책임 있는 사유로 인하여 발생한 손해에 관하여는 제1항 내지
					제3항을 적용하지 아니합니다.
				</div>
				<br />
				<h1>제20조 공개 게시물 등의 관리</h1>
				<div>
					① "회사"는 건전한 통신문화정착과 효율적 서비스 운영을 위하여 다음
					각호에 해당하는 공개 게시물 및 자료의 게시중단, 삭제, 이동, 등록거부
					하는 등의 필요조치를 취할 수 있습니다. 이 경우 게시물 및 자료의 삭제
					등에 대하여 회사는 귀책사유가 없는 한 어떠한 책임도 부담하지
					않습니다.<br />
					<p class="pl-20">
						1. 타인의 법률상 이익을 침해하는 행위와 관련된 것으로 추정되는
						게시물, 자료로서, 이해당사자의 삭제 등 요청이 있거나, 회사가 피소,
						고발될 수 있는 사유를 제공하는 게시물, 자료<br />
						2. 서비스에 위해를 가할 소지가 있는 바이러스 등이 포함된 게시물,
						자료<br />
						3. 제20조에 의하여 정해진 게재 기한 및 용량을 초과한 게시물, 자료(이
						경우 회사는 사전에 해당 이용자에게 삭제, 이동 등에 관한 안내를 할 수
						있습니다)<br />
						4. 제12조 제8항 각호를 위반하여 다른 이용자의 서비스 이용에 현저한
						지장을 초래하는 게시물, 자료<br />
						5. 전기통신 관계법령 및 형사 관계법령에 따른 국가기관 등의 삭제 등
						요구가 있는 게시물, 자료<br /><br />
					</p>
					② "회사"는 게시물 등에 대하여 제3자로부터 명예훼손, 지적재산권 등의
					권리 침해를 이유로 게시중단 요청을 받은 경우 이를 임시로
					게시중단(전송중단)할 수 있으며, 게시중단 요청자와 게시물 등록자 간에
					소송, 합의 기타 이에 준하는 관련기관의 결정 등이 이루어져 회사에
					접수된 경우 이에 따릅니다.<br />
					③ 해당 게시물 등에 대해 임시게시 중단이 된 경우, 게시물을 등록한
					이용자는 회사에 소명자료를 첨부하여 이의신청을 할 수 있으며, 게시 중단
					사유가 해소된 날로부터 30일 이내에 재게시를 요청하지 아니한 경우
					회사는 이를 삭제할 수 있습니다.<br />
					④ 이용자는 “회사”에게 본인의 게시물 삭제를 요청할 수 있고, 사망한
					이용자의 유족은 사망사실과 가족관계를 증빙하여 그 이용자의 게시물의
					이전, 접근배제 또는 삭제를 요청할 수 있습니다.
				</div>
				<br />
				<h1>제21조 정보의 제공</h1>
				<div>
					① "회사"는 이용자가 서비스 이용 중 필요하다고 인정되는 각종 정보를
					게재하거나, 전자우편(E-Mail), 휴대폰 단문 메시지(SMS)와 알림(PUSH),
					전화, 우편 등의 방법으로 이용자에게 제공(또는 전송)할 수 있습니다.
					이용자는 이를 원하지 않을 경우에 회사가 제공하는 방법에 따라 수신을
					거부할 수 있습니다.<br />
					② 전항 단서에 따라 수신을 거부한 이용자의 경우에도 이용약관,
					개인정보보호정책, 기타 이용자의 이익에 영향을 미칠 수 있는 중요한
					사항의 변경 등 이용자가 반드시 알고 있어야 하는 사항에 대해서는
					전자우편 등의 방법으로 정보의 제공 등을 할 수 있습니다.<br />
					③ 회사는 광고주의 판촉 활동에 이용자가 참여하거나, 거래의 결과로서
					발생하는 손실 또는 손해에 대해서는 책임을 지지 않습니다.
				</div>
				<br />
				<h1>제22조 지적재산권</h1>
				<div>
					① 이용자는 "서비스" 에 제공한 "콘텐츠(영상파일, 라이브캠 촬영,
					모바일녹화영상, 방송국이나 게시판 등에 게시한 글, 방송시청 중 채팅자료
					등 이용자가 서비스에 제공하거나 서비스를 이용하면서 업로드한 자료
					일체)"와 관련하여 저작권, 인격권 등 제 3자와의 분쟁 발생시 그 법적
					대응 및 결과에 대한 책임을 지며, "회사"는 해당 "콘텐츠"와 관련된
					일체의 책임을 명시적으로 부인합니다.<br />
					② "회사"는 "서비스"를 통한 저작권 침해행위나 지적재산권 침해를
					허용하지 아니하며, 이용자의 "콘텐츠"가 타인의 지적재산권을 침해한다는
					사실을 적절하게 고지받거나 인지하게 되는 경우 저작권법 기타 관련
					법령에서 정한 절차에 따라 그 "콘텐츠" 일체의 서비스를 중지하거나
					제거할 수 있습니다. 또한 "회사"는 이용자의 "콘텐츠"가 제12조 제8항
					각호 또는 제20조 제1항 각호에 해당할 경우 제15조 또는 제20조에서 정한
					방법으로 “콘텐츠”를 삭제하거나 이동 또는 등록 거부할 수 있는 권리를
					보유 합니다.<br />
					③ 이용자의 "콘텐츠"에 대한 저작권은 원래의 저작자가 보유하되, 본
					약관이 정하는 바에 따라 "회사" 혹은 "회사가 지정한 자"에게 "콘텐츠"에
					대한 사용 권한을 부여합니다. "회사"가 이용자의 "콘텐츠"를 사용하는
					용도와 방법은 아래와 같습니다.<br />
					<p class="pl-20">
						1. 이용자가 "서비스"에 제공한 "콘텐츠"를 다른 이용자가 시청하도록
						함.<br />
						2. 이용자가 "서비스"에 제공한 "콘텐츠"를 "회사"나 다른 이용자가
						녹화/편집/변경하여 새로운 콘텐츠로 제작한 다음 이를 "서비스"의 다른
						이용자들이 시청하게 하거나, 또는 "회사"의 제휴사에 제공하여 그
						이용자들이 이를 시청할 수 있도록 함.<br />
						3. 이용자가 "서비스"에 제공한 "콘텐츠"를 "회사"가 저장한 후 이를
						VOD등의 다시 보기 서비스로 다른 이용자들이 시청할 수 있게 하거나,
						"회사"의 제휴사에 제공함으로써 제휴사가 그 이용자들에게 스트리밍
						또는 VOD 등의 다시보기 서비스로 시청할 수 있게끔 함.<br />
						4. 이용자가 “서비스”에 제공한 “콘텐츠”를 “회사”가 복제, 저장,
						편집하여 “서비스” 홍보 목적으로 “회사”의 관리 채널 또는 블로그 등에
						전송, 게시함.<br />
						5. 이용자가 “서비스”에 제공한 “콘텐츠”를 분석 및 통계작성, 기술 개발
						기타 “서비스” 개선을 위한 목적으로 학술·연구기관·업체에게 제공함<br /><br />
					</p>
					④ 본 조 제3항에 규정한 "회사", 다른 이용자 및 "회사의 제휴사"가
					이용자의 "콘텐츠"를 이용할 수 있는 조건은 아래와 같습니다.<br />
					<p class="pl-20">
						1. 콘텐츠 이용 매체/플랫폼 - 현재 알려져 있거나 앞으로 개발된 모든
						형태의 매체, 장비, 기술을 포함함.<br />
						2. 콘텐츠 이용 용도 - 상업적 또는 비상업적 이용을 구분하지 않음.<br />
						3. 콘텐츠 이용 범위 - 국내 및 국외에서의 복제, 수정, 각색, 공연,
						전시, 방송, 배포, 대여, 공중송신, 2차적 저작물 작성, 기타 이용<br />
						4. 콘텐츠 이용 조건 - 비독점적, 지속적인 무상의 권리로서 양도
						가능하며, 서브 라이센스가 가능함.<br /><br />
					</p>
					⑤ 이용자는 제3항에 따른 사용허락을 "회사"가 공지, 이용안내에서 정한
					바에 따라 장래에 대하여 철회할 수 있습니다.<br />
					⑥ “회사”가 이용자를 대신하여 저작권자로부터 저작물의 이용허락을 받을
					경우 이용자가 지급한 유료서비스 수수료의 일부는 그 이용요금을 지급하는
					데에 사용됩니다. 단, 이용자는 “회사”가 안내·공지하는 저작물 이용조건
					및 가이드를 준수하여야 하며, 이를 위반할 경우 이용자가 책임을
					부담하여야 합니다.
				</div>
				<br />
				<h1>제23조 관할법원과 준거법</h1>
				<div>
					① 서비스 이용에 관하여 회사와 이용자 간에 분쟁이 발생한 경우 협의로
					원만히 해결합니다.<br />
					② 전항으로 해결되지 아니할 경우 소송의 관할은 민사소송법에 따라
					정합니다.<br />
					③ 본 약관의 해석 및 적용은 대한민국 법령을 기준으로 합니다.
				</div>
				<br />
				<h1>제24조 자료 전송 기술 사용동의</h1>
				<div>
					"회사"는 자료의 전송을 위하여 이용자 간에 데이터를 중계 전송하는
					기술을 사용할 수 있습니다. 이용자는 이 약관을 통해, PC 등 자신의
					이용장치를 통하여 다른 사람이 중계 전송 받는 것을 허용하는데
					동의합니다. 전송기술에 대한 세부적인 사항은 이용안내 등에서 게시하여
					고지하며, 추가적인 내용에 대한 세부 동의는 별도의 파일전송관리자
					이용약관에 대한 동의를 통하여 합니다.
				</div>
				<br />
				<br />
				<h1 class="fs-24 font-bold">유료서비스 이용약관</h1>
				<div>
					"회사"는 자료의 전송을 위하여 이용자 간에 데이터를 중계 전송하는
					기술을 사용할 수 있습니다. 이용자는 이 약관을 통해, PC 등 자신의
					이용장치를 통하여 다른 사람이 중계 전송 받는 것을 허용하는데
					동의합니다. 전송기술에 대한 세부적인 사항은 이용안내 등에서 게시하여
					고지하며, 추가적인 내용에 대한 세부 동의는 별도의 파일전송관리자
					이용약관에 대한 동의를 통하여 합니다.
				</div>
				<br />
				<h1>제1조 목적</h1>
				<div>
					본 약관은 주식회사 플렉스이엔엠(이하 “회사”라고 합니다)에서 제공하는
					플렉스이엔엠 서비스를 통하여 제공 되는 각종 유료서비스 및 서비스이용한
					LEX(cash)를 이용함에 있어 회사와 회원의 권리,의무 및 책임사항, 기타
					필요한 사항을 규정함을 목적으로 합니다.
				</div>
				<br />
				<h1>제2조 약관의 효력과 변경</h1>
				<div>
					① 본 약관의 내용은 홈페이지 및 서비스 화면에 이를 공시하거나 전자우편
					등의 방법으로 이용자에게 공지하고, 이용자가 이에 동의함으로써 적용일에
					효력이 발생됩니다.<br />
					② 회사는 회사가 필요하다고 인정되는 경우 전자상거래 등에서의 소비자
					보호에 관한 법률 (이하 "전자상거래법"이라 합니다), 약관의 규제 관한
					법률 (이하 "약관규제법"이라 합니다), 게임산업진흥에 관한 법률 (이하
					"게임산업법"이라 합니다), 정보통신망이용촉진 및 정보보호 등에 관한
					법률 (이하 "정보통신망법"이라 합니다), 콘텐츠 산업 진흥법 (이하
					"콘텐츠산업법"이라 합니다) 등 관련 법령을 위배하지 않는 범위에서 본
					약관을 개정할 수 있습니다.<br />
					③ 본 약관에 명시되지 않은 사항에 대해서는 전기통신기본법,
					전기통신사업법, 정보통신망법 및 기타 관련 법령의 규정에 따릅니다.<br />
					④ 회사는 약관이 변경되는 경우에는 적용일자 및 변경사유를 명시하여
					현행약관과 함께 홈페이지 초기화면에 그 적용일자로부터 최소한 7일
					(이용자에게 불리하거나 중대한 사항의 변경은 30일) 이전부터 적용일 후
					상당한 기간 동안 공지하고, 기존 이용자에게 변경될 약관, 적용일자 및
					변경사유(변경될 내용 중 중요사항에 대한 설명을 포함한다)를 이용자가
					입력한 최근의 전자우편주소 또는 문자메시지 등으로 발송합니다.<br />
					⑤ 이용자는 개정되는 약관의 전체 또는 일부 내용에 동의하지 않을 권리가
					있습니다. 본 약관의 변경에 대하여 이의가 있는 이용자는 회원탈퇴를 통해
					이용계약을 해지할 수 있습니다. 다만, 이용계약이 해지되면 로그인 후
					제공되는 서비스를 이용할 수 없게 됩니다.<br />
					⑥ 이용자가 개정된 약관의 적용일자로부터 10일(회원에게 불리하거나
					중대한 사항의 변경인 경우에는 30일) 내에 변경된 약관의 적용에 대해
					거절의 의사를 표시하지 않았을 때에는 약관의 변경에 동의한 것으로 간주
					합니다.
				</div>
				<br />
				<h1>제3조 약관 외 준칙</h1>
				<div>
					본 약관에 명시되지 않은 사항에 대해서는 이용약관, 개별 이용약관,
					회사의 정책, 관계 법령의 규정에 따릅니다.
				</div>
				<br />
				<h1>제4조 용어의 정리</h1>
				<div>
					① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
					<p class="pl-20">
						1. 유료서비스 : 회사가 제공하는 결제 방식을 통해 유료로 구매한 후
						사용할 수 있는 재화 또는 용역으로, 구체적인 종류와 내용은 각 구매
						페이지에서 안내하고 있습니다.<br />
						2. 자동결제 : 이용자가 입력한 결제 수단 등의 결제 정보로 자동 요금
						결제가 되어 이용할 수 있는 서비스<br />
						3. 청약철회 : 이용자가 유료서비스를 결제한 후 사용하지 않은 재화
						또는 용역에 대한 구매의사를 철회하는 행위를 말합니다.<br />
						4. 구매 : 회사가 제공하는 유료서비스를 결제하는 행위를 말합니다.<br />
						5. 환불 : 회사에 결제한 유료서비스를 현금으로 되돌려 받거나, 구매한
						상품을 상품 구매의 취소에 의하여 현금으로 되돌려 받는 것을
						말합니다.<br />
						6. 환전 : 회사의 정책 및 안내에 따라 다른 이용자로부터 받은 후원을
						현금으로 전환하기 위해 신청을 하고, 플랫폼서비스 수수료 및
						원천징수세액 등을 제외한 나머지를 현금으로 받는 행위를 말합니다.<br />
						7. 수수료 : 회사가 서비스를 제공, 운영하는 데 필요한 제반 비용(서버
						유지비, 망사용료, 저작권료 기타 사용권 취득 비용, 그 밖에 인력, 시설
						유지 비용 등)으로 각 서비스 형태에 맞게 책정된 비율로 회사에게
						귀속되는 요금을 말합니다.<br /><br />
					</p>
					② 본 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는
					관계법령 및 기타 일반적인 상관례에 의합니다.
				</div>
				<br />
				<h1>제5조 유료서비스의 내용 등의 게시</h1>
				<div>
					① 회사는 다음 사항을 해당 유료서비스의 안내 또는 구매화면 등에
					이용자가 알기 쉽게 표시합니다.<br />
					<p class="pl-20">
						1. 유료서비스의 명칭, 종류 및 내용<br />
						2. 유료서비스의 가격과 그 지급방법 및 시기<br />
						3. 유료서비스의 공급방법, 시기 및 제공기간<br />
						4. 청약의 철회 및 계약의 해제의 기한·행사방법 및 효과에 관한 사항<br />
						5. 서비스계약이 계속 거래에 해당할 경우, 계약의 해지와 그 행사방법
						및 효과에 관한 사항<br />
						6. 서비스의 교환·반품·보증과 그 대금 환급의 조건 및 절차<br />
						7. 전자매체로 공급이 가능한 서비스의 전송·설치 등과 관련하여
						요구되는 기술적 사항<br />
						8. 이용자피해보상, 서비스에 대한 불만 및 이용자와 회사간 분쟁처리에
						관한 사항<br />
						9. 거래에 관한 약관<br />
						10. 서비스의 가격 외에 이용자가 추가로 부담하여야 할 사항이 있는
						경우, 그 내용 및 금액<br />
						11. 이용자가 미성년자인 경우, 법정대리인의 동의를 얻지 못하면
						미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용<br /><br />
					</p>
					② 회사에서 제공하는 유료서비스는 이용자가 구매하는 즉시 이용자
					아이디로 지급되며, 유료서비스 제공기간에 따라 사용할 수 있습니다. ③
					회사는 서비스 이용에 필요한 최신 다운로드와 PC 사양 확인 서비스를
					제공합니다.
				</div>
				<br />
				<h1>제6조 이용 계약의 성립</h1>
				<div>
					이용자가 본 약관에 동의하고, 회사가 정하는 결제수단과 방법을 통해
					유료서비스를 구매함으로써 이용계약이 성립됩니다.
				</div>
				<br />
				<h1>제7조 유료서비스의 구매</h1>
				<div>
					① 유료서비스의 구매는 회사의 플렉스이엔엠 계정이 있어야 가능합니다.<br />
					② 유료서비스의 구매는 회사에서 제공하는 결제방식을 통해서 가능합니다.
					다만, 각 결제수단을 운영하는 사업자가 별도로 있는 경우, 이용자는 해당
					결제수단을 이용하기 전에 해당 결제수단의 사업자가 제시하는 절차를
					이행하여야 합니다. 회사의 서비스 이용요금을 결제할 때에는 해당
					결제수단의 사업자가 제시하는 절차 이행 및 이용약관에 동의한 것으로
					간주합니다.
				</div>
				<br />
				<h1>제8조 결제에 대한 승인</h1>
				<div>
					① 회사는 다음의 각 항에 해당하는 이용신청에 대해서는 승인을 하지
					않거나, 추후 해당 승인을 취소할 수 있습니다.<br />
					<p class="pl-20">
						1. 이용 요금을 납부하지 않거나 납부자를 확인할 수 없는 경우<br />
						2. 만 19세 미만의 미성년자가 법정대리인의 동의 없이 신청한 경우<br />
						3. 이용신청자의 귀책사유로 승인이 불가능하다고 판단되는 경우<br />
						4. 기타 이용 신청을 승인하는 것이 관계 법령이 위반되거나 사회 안녕
						질서 및 미풍양속, 회사의 관련 약관 등 회사의 업무 방침에 위반된다고
						판단되는 경우<br /><br />
					</p>
					② 회사는 다음의 각 항에 해당하는 경우에는 이용신청에 대한 승인 제한
					사유가 해소될 때까지 승인을 제한할 수 있습니다.<br />
					<p class="pl-20">
						1. 서비스 설비가 부족하여 만족스러운 서비스를 제공할 수 없다고
						판단될 경우<br />
						2. 서비스 상의 장애가 발생한 경우
					</p>
				</div>
				<br />
				<h1>제8조 사용기간 등</h1>
				<div>
					유료 서비스 사용기간은 마지막 이용일로부터 5년 이내에 사용해야 하고,
					기간 내 사용되지 않은 유료서비스는 상사시효에 근거하여 회사의 최종
					서비스 이용일로부터 5년이 경과한 후에는 자동으로 소멸됩니다. 다만,
					다른 법령에서 이보다 단기의 시효의 규정이 있는 때에는 그 규정에
					의합니다.
				</div>
				<br />
				<h1>제9조 미성년자의 결제</h1>
				<div>
					① 이용자가 미성년자(만 19세 미만) 또는 한정치산자인 경우는, 유료
					서비스 이용을 위해 결제하기 전에, 민법 제5조 1항에 따라
					법정대리인(부모 등)의 동의를 받아야 합니다. 회사가 정한 절차에 따른
					법정대리인의 동의를 받지 않은 경우는 유료 서비스를 이용할 수 없습니다.
					미성년자가 다른 성년자 등의 주민등록번호를 이용하여 회원가입을 하거나
					성년자 등의 결제정보를 동의 없이 이용하는 등 사술로써 회사로 하여금
					성년자인 것으로 믿게 한 경우에는 법정대리인의 동의가 없더라도 유료
					서비스 이용계약을 취소하지 못합니다.<br />
					② 전항의 미성년자 결제에 대한 동의를 위해 법정대리인은 회사의 안내에
					따라 휴대폰 인증, 아이핀 인증, 팩스 인증의 방법을 사용할 수 있으며,
					법정대리인의 동의가 완료된 이후 회사는 법정대리인에게 이메일을 통해
					결제 동의사실 및 그 내역에 대해 고지합니다. 법정대리인은 이러한 통지를
					거부할 수 있으며, 아래의 고객센터를 통해 상세내역에 대하여 문의하실
					수도 있습니다.<br />
					<p class="pl-20">
						고객센터 :<br />
						- 전화) 1566 - 7381 (평일 09:00 ~ 18:00 공휴일, 토, 일 휴무)<br />
						- 팩스) 070-7812-3210<br />
						- 우편) 서울특별시 강남구 역삼로12길 18<br /><br />
					</p>
					③ 미성년자의 법정대리인은 회사의 안내에 따라 3개월, 6개월, 9개월, 1년,
					3년, 5년의 유효기간 중 선택하여 동의를 할 수 있습니다. 회사는
					법정대리인이 선택한 동의유효기간 만료시점으로부터 30일 전에
					법정대리인에게 이메일을 통해 통지하여 동의연장여부를 확인하며, 이에
					대해 법정대리인의 동의철회 요구 등이 없을 경우 동의유효기간은 동일한
					조건으로 자동 연장됩니다.
				</div>
				<br />
				<h1>제10조 이의신청</h1>
				<div>
					① 이용자는 유료서비스 구매, 결제 등에 이의가 있을 경우에는 유료서비스
					사용일로부터 1개월 이내에 이의신청을 할 수 있습니다.<br />
					② "회사"는 전항의 이의신청 접수 후 10일 이내에 타당성 여부를 조사하고,
					그 결과를 이용자에게 통지합니다.<br />
					③ 부득이한 사유로 전항의 기간 내에 이의신청결과를 통지할 수 없을
					경우에는 그 사유와 재지정된 처리기한을 명시하여 이를 이용자에게
					통지합니다.
				</div>
				<br />
				<h1>제11조 유료서비스에 대한 청약철회</h1>
				<div>
					① 이용자가 구매하는 유료서비스는 관련법령에 따라 청약의 철회가
					가능합니다. 단, 아래 각호의 경우에는 제한됩니다.<br />
					<p class="pl-20">
						1. 이용자가 유료서비스를 사용하거나 일부 소비로 그 가치가 현저히
						감소한 경우<br />
						2. 이용자에게 책임이 있는 사유로 유료서비스가 멸실되거나 훼손된
						경우<br />
						3. 유료서비스의 유효기간이 경과된 경우<br />
						4. 용역 또는 콘텐츠의 제공이 개시된 경우(가분적 용역이나 콘텐츠의
						경우 제공이 되지 않은 부분에 대하여는 가능)<br />
						5. 청약철회 등을 인정할 경우 회사에게 회복할 수 없는 중대한 피해가
						예상되어 회사가 사전에 그 사실을 고지하고 이용자로부터 (전자)문서에
						의한 동의를 받은 경우<br /><br />
					</p>
					② 이용자는 청약철회가 가능한 서비스에 대해서 구매일로부터 7일 이내에
					청약철회를 하여야 합니다. 다만, 계약내용에 관한 서면을 교부 받지
					아니한 경우, 회사의 주소 등이 기재되지 아니한 서면을 교부 받은 경우
					또는 회사의 주소 변경 등의 사유로 7일 이내에 청약철회를 할 수 없는
					경우에는 그 주소를 안 날 또는 알 수 있었던 날로부터 7일 이내에 당해
					계약에 관한 청약철회를 할 수 있습니다.<br />
					③ 이용자는 서비스의 내용이 표시·광고 내용과 다르거나 계약 내용과
					다르게 이행된 경우에는 당해 서비스를 공급받은 날로부터 3월 이내, 그
					사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 청약철회를 할 수
					있습니다.<br />
					④ 이용자는 구두 또는 서면(전자문서를 포함)으로 청약철회를 할 수
					있으며, 이용자는 청약철회를 서면으로 하는 경우, 그 의사표시가 기재된
					서면을 발송한 날에 그 효력이 발생합니다.<br />
					⑤ 회사는 이용자가 원활하게 청약철회를 할 수 있도록 계약 체결 시 사용한
					방법에 추가하여 아래와 같은 방법을 제공합니다.<br />
					<p class="pl-20">
						- 고객센터 전화 : 1566 - 7381 (평일 09:00 ~ 18:00 공휴일, 토, 일
						휴무)<br />
						- 고객센터 팩스 : 070-7812-3210<br /><br />
					</p>
					⑥ 서비스의 훼손에 대하여 이용자의 책임이 있는지의 여부, 서비스의
					구매에 관한 계약이 체결된 사실 및 그 시기, 서비스의 공급사실 및 그
					시기 등에 관하여 다툼이 있는 경우에는 회사가 이를 입증하여야
					합니다.<br />
					⑦ 청약철회를 한 경우 이용자는 공급받은 서비스를 반환하여야 하는바,
					서비스의 반환에 필요한 비용은 제12조 제2항에 의한 청약철회 등의
					경우에는 이용자가 부담하며, 제12조 제3항에 의한 청약철회의 경우 회사가
					부담합니다.<br />
					⑧ 회사는 서비스를 반환 받은 날부터(별도의 서비스 반환 등이 필요하지
					않은 경우에는 청약철회의 의사표시를 수령한 날로부터) 3영업일 이내에
					대금의 결제와 동일한 방법으로 이를 환급하며, 동일한 방법으로 환불이
					불가능할 때에는 이를 사전에 고지합니다. 이 경우 회사는 환급을 지연한
					때에는 그 지연기간에 대하여 연 15%의 비율에 의한 금원을 지연이자로
					지급합니다.<br />
					⑨ 회사는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구할
					수 없으며, 회사는 반환된 서비스가 일부 사용 또는 일부 소비된 경우 그
					서비스의 사용 또는 일부 소비에 의하여 이용자가 얻은 이익 또는 그
					서비스의 공급에 소요된 비용에 상당하는 금액의 범위 내에서 이용자에게
					청구할 수 있습니다.<br />
					⑩ 기타 본 약관에서 정하지 않은 부분은 전자상거래법 등 관련 법령에서
					정하는 바에 의합니다.
				</div>
				<br />
				<h1>제12조 계약 해지·해제</h1>
				<div>
					① 회사 또는 이용자는 다음 각호의 어느 하나에 해당하는 경우 유료서비스
					관련 계약을 해제 또는 해지할 수 있습니다.<br />
					<p class="pl-20">
						1. 이용자가 회사와 합의한 해제·해지사유가 발생한 경우<br />
						2. 법률에서 정한 해제·해지사유가 발생한 경우<br /><br />
					</p>
					② 회사가 유료서비스를 공급하지 않거나 불완전한 서비스를 공급한 경우에
					있어서 이용자가 당해 서비스계약을 해제 또는 해지하기 위하여는 상당한
					기간을 정하여 완전한 서비스를 공급할 것을 최고하여야 합니다. 다만,
					이용자는 다음 각호의 어느 하나에 해당하는 경우 최고를 하지 않고 계약을
					해제 또는 해지할 수 있습니다.<br />
					<p class="pl-20">
						1. 일정한 시일 또는 기간 내에 서비스를 공급하여야만 그 목적을 달성할
						수 있는 계약에서 회사가 그 시기에 서비스를 공급하지 아니한 경우<br />
						2. 회사가 미리 서비스를 공급하지 않을 의사를 표시한 경우<br />
						3. 완전한 서비스의 공급이 불가능한 경우(서비스 구입목적을 달성할 수
						없는 경우에 한함)<br />
						4. 서비스계약이 계속거래에 해당하는 경우 기타 법률에 규정되거나
						당사자가 합의한 경우<br />
						5. 기타 법률에 규정되거나 당사자가 합의한 경우<br /><br />
					</p>
					③ 회사는 이용자가 채무를 이행하지 않은 경우(서비스대금을 지급하지 않은
					경우 등)에 당해 서비스계약을 해제 또는 해지하기 위해서 상당한 기간을
					정하여 채무의 이행을 최고하여야 합니다. 다만, 회사는 다음 각호의 어느
					하나에 해당하는 경우 최고없이 계약을 해제 또는 해지할 수 있습니다.<br />
					<p class="pl-20">
						1. 이용자가 채무이행을 거절한 경우<br />
						2. 기타 법률에서 규정하고 있는 경우
					</p>
				</div>
				<br />
				<h1>제13조 계약 해지·해제의 효과</h1>
				<div>
					① 회사 또는 이용자가 서비스계약을 해제한 경우, 이용자는 회사에게
					서비스를 반환하여야 합니다. 다만, 이용자가 서비스를 이용한 경우 그
					사용에 따라 얻은 이익을 반환하여야 합니다. 회사는 대금을 받은 날로부터
					그 대금에 이자를 더하여 이용자에게 반환하여야 하며, 회사 또는 이용자는
					해제권의 행사와 별도로 상대방에게 손해배상을 청구할 수 있습니다. 다만,
					상대방이 손해배상책임을 면하기 위해서는 자신에게 고의 또는 과실 없음을
					증명하여야 합니다.<br />
					② 회사 또는 이용자가 서비스계약을 해지한 경우에 당해 서비스계약은
					해지한 시점 이후부터 효력을 잃으며, 회사 또는 이용자는 해지권의 행사와
					별도로 상대방에게 손해배상을 청구할 수 있습니다. 다만, 상대방이
					손해배상책임을 면하기 위해서는 자신에게 고의 또는 과실 없음을
					증명하여야 합니다. 이용자가 계속 거래에 해당하는 서비스계약을 해지한
					경우, 회사가 이용자에게 청구할 수 있는 손해배상액(위약금)은 다음
					각호에 의하여 산정됩니다.<br />
					<p class="pl-20">
						1. 회사는 이용자의 해지로 인해 발생하는 손실을 현저에게 초과하는
						위약금을 청구하거나 가입비 그 밖의 명칭여하를 불문하고 실제 공급된
						서비스의 대가를 초과하여 수령한 대금의 반환을 부당하게 거부하여서는
						아니됩니다.<br />
						2. 서비스계약이 해지된 경우 이용자는 반환할 수 있는 서비스를
						회사에게 반환할 수 있으며, 회사는 반환 받은 서비스의 가치에 상당하는
						금액을 계약의 해지에 따라 지급하여야 할 환급금에 더하거나 청구할 수
						있는 위약금에서 감액하여야 합니다.<br />
						3. 회사는 서비스계약이 해지된 때에 이용자로부터 받은 서비스의
						대금(서비스가 반환된 경우 환급하여야 할 금액을 포함)이 이미 공급한
						서비스의 대금에 위약금을 더한 금액보다 많은 경우에는 그 차액을
						이용자에게 환급하여야 합니다.<br />
						4. 회사는 차액의 환급을 지연한 경우에 지연기간에 대한 지연배상금을
						더하여 환급하여야 합니다.<br />
						5. 계약의 해지 또는 대금의 환급에 관하여 이용자의 책임이 있는지의
						여부, 계약이 체결된 사실 및 그 시기, 서비스의 공급 사실 및 그 시기에
						관하여 다툼이 있는 경우에는 회사가 이를 입증하여야 합니다.
					</p>
				</div>
				<br />
				<h1>제14조 양도금지</h1>
				<div>
					이용자의 약관 상의 권리는 이를 양도하거나 증여할 수 없으며, 질권의
					목적으로 사용할 수 없습니다.
				</div>
				<br />
				<h1>제15조 환전 등</h1>
				<div>
					① 환전이 가능한 유료서비스는 이용자가 회사의 정책 안내에 따라
					신청하여야 하며, 회사는 세금과 수수료 등을 공제한 금액을 이용자에게
					지급합니다.<br />
					② 회사는 이용자가 부정, 위법한 목적, 약관 기타 서비스 취지에 반하는
					방법으로 유료서비스를 구매, 이용한 경우에는 결제 승인을 취소하거나 그
					환전, 환불 등을 거절, 정지할 수 있으며 해당 유료서비스를 반환, 회수할
					수 있습니다.<br />
					③ 환전의 제한은 정상적인 이용이라는 점이 밝혀지거나 결제 대금이 정상
					납부될 경우에는 해제될 수 있습니다.
				</div>
				<br />
				<h1>제16조 환불</h1>
				<div>
					① 회원이 자신의 ID를 통해 결제한 유료 서비스 및 충전한 Lex(cash)
					잔여액에 대한 환불을 요청하면 적법한 절차에 따라 7일 이내 환불 받을 수
					있습니다. 단, 수납확인이 필요한 결제수단의 경우에는 수납확인일로부터
					영업일 7일 이내에 이를 환급하도록 합니다.<br />
					② 유료 서비스의 경우 이미 사용한 부분에 대한 환불은 불가능합니다.<br />
					③ 회사가 무료로 제공하거나 타인으로부터 선물 받은 유료서비스의 경우
					환불이 불가능합니다.<br />
					④ 회사의 서비스에서 탈퇴한 경우 환불이 불가능합니다.<br />
					⑤ 주민등록법, 저작권법, 컴퓨터프로그램보호법, 정보통신망법 등 각종
					법령에 대한 중대한 불법행위 또는 반복적 버그 악용, 운영자 사칭 등
					운영에 심각한 악영향을 미치는 행위를 한 것으로 판단 되는 이용자의 계정
					및 아이디 이용을 제한하거나 해당 이용자와의 계약을 해지하는 경우,
					회사는 이용료를 환불하지 않을 수 있습니다.
				</div>
				<br />
				<h1>제17조 책임제한</h1>
				<div>
					① "회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여
					"유료서비스"를 제공할 수 없는 경우에는 "유료서비스" 제공에 관한 책임이
					면제됩니다.<br />
					② "회사"는 이용자의 귀책사유로 인한 유료서비스 이용의 장애에 대하여는
					회사의 고의 또는 중과실이 없는 한 책임을 지지 않습니다<br />
					③ "회사"는 이용자가 "유료서비스"와 관련하여 게재한 정보, 자료, 사실의
					신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.<br />
					④ "회사"는 이용자 상호간 또는 이용자와 제3자 간에 "유료서비스"를
					매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않습니다.<br />
					⑤ "회사”는 이용자가 유료서비스를 이용하여 기대하는 수익을 상실한 것에
					대하여 회사의 고의 또는 중과실이 없는 한 책임을 지지 않습니다.
				</div>
				<br />
				<h1>제18조 관할법원</h1>
				<div>
					① 서비스 이용에 관하여 회사와 이용자 간에 이견 또는 분쟁이 있는 경우,
					양 당사자간의 합의에 의해 원만히 해결해야 합니다.<br />
					② 전항으로 해결되지 아니할 경우 소송의 관할은 “회사”의 주소를 기준으로
					합니다.<br />
					③ 본 약관의 해석 및 적용은 대한민국 법령을 기준으로 합니다.
				</div>
				<br />
				<h1>제19조 서비스하자 등에 의한 회원피해보상</h1>
				<div>
					① 회사의 자체 서비스의 중대한 하자에 의하여 유료로 구입한 서비스가
					손상 또는 훼손되거나 삭제된 경우, 이용자는 그에 상응하는 적절한 수단을
					통해 보상 받을 수 있습니다.<br />
					② 사업자가 서비스의 중지·장애에 대하여 사전 고지하지 않은 경우에
					있어서 이용자의 피해구제 등은 다음 각호에 의합니다. 다만, 이용자의
					책임 있는 사유로 인하여 서비스가 중지되거나 장애가 발생한 경우 서비스
					중지·장애시간에 포함하지 않습니다.<br />
					<p class="pl-20">
						1. 1개월 동안의 서비스 중지·장애발생 누적시간이 72시간을 초과한 경우
						: 계약해제 또는 해지 및 미이용기간을 포함한 잔여기간에 대한 이용료
						환급 및 손해배상 (단, 사업자가 고의 또는 과실없음을 입증한 경우
						손해배상책임을 지지 않음)<br />
						2. 사업자의 책임있는 사유로 인한 서비스 중지 또는 장애의 경우 :
						서비스 중지·장애시간의 3배를 무료로 연장<br />
						3. 불가항력 또는 제3자의 불법행위 등으로 인해 서비스의 중지 또는
						장애가 발생한 경우 : 계약을 해제할 수는 없지만, 서비스의 중지 또는
						장애시간만큼 무료로 이용기간을 연장<br /><br />
					</p>
					③ 사업자가 서비스의 중지·장애에 대하여 사전고지한 경우에 있어서
					이용자의 피해구제 등은 다음 각호에 의합니다. 다만, 서비스 개선을
					목적으로 한 설비 점검 및 보수시 1개월을 기준으로 최대 24시간은
					중지·장애 시간에 포함하지 않습니다.<br />
					<p class="pl-20">
						1. 1개월을 기준으로 서비스 중지·장애시간이 10시간을 초과하는 경우 :
						10시간과 이를 초과한 시간의 2배의 시간만큼 이용기간을 무료로 연장<br />
						2. 1개월을 기준으로 서비스 중지·장애시간이 10시간을 초과하지 않은
						경우 : 중지·장애 시간에 해당하는 시간을 무료로 연장<br /><br />
					</p>
					④ 제2항 및 제3항을 적용함에 있어서 사전고지는 서비스 중지, 장애 시점을
					기준으로 24시간 이전에 고지된 것에 한합니다.
				</div>
				<br />
			</div>
			<!-- } company information list end -->
		</div>
		<!-- } content end -->
	</div>
	<!--  } container end -->
	<Footer />
</template>

<script>
	import Footer from "@/components/Footer.vue";
	import { onMounted } from "vue";
	import { useStore } from "vuex";
	export default {
		components: {
			Footer,
		},
		setup() {
			const store = useStore();
			onMounted(() => {
				store.commit("common/CHANGE_CURRENT_PAGE", "Policy");
			});
		},
	};
</script>

<style></style>
