<template>
	<!-- container start { -->
	<div id="container" class="sub-contents pop">
		<!-- content start { -->
		<div id="contents" style="max-width: 400px">
			<div class="btn-close-page" @click="onClickSignupClose">
				<button type="button" class="btn">
					<img src="@/assets/images/cm/ico_close.png" alt="menu" />
				</button>
			</div>
			<!-- su title h2 start { -->
			<div class="sub-title">
				<h2>회원가입</h2>
			</div>
			<!-- } sub title h2 end -->

			<!-- 회원정보입력 start { -->
			<form method="post" name="signUpSubmit">
				<p class="signup-txt mt-0">
					회원정보입력 <span class="fs14 fc-red">*는 필수입력 항목입니다.</span>
				</p>

				<ul class="singup-li">
					<li>
						<label for="uName">이름 <span class="fs-12 fc-red">*</span></label>
						<input
							type="text"
							name="uName"
							id="uName"
							:value="uName"
							class="w-100"
							readonly
						/>
					</li>
					<li>
						<label for="uId">아이디 <span class="fs-12 fc-red">*</span></label>
						<div class="input-btn-2col">
							<input
								type="text"
								name="uId"
								id="uId"
								class="w-col"
								v-model="mId"
								@input="isCheckBtn = true"
							/>
							<button
								type="button"
								class="btn btn-lg"
								:class="isCheckBtn ? 'btn-outline-black' : 'btn-disabled'"
								@click="oncheckMid"
							>
								중복체크
							</button>
						</div>
						<span class="info-block blue">※ 아이디는 4자 이상입니다.</span>
					</li>
					<li v-if="!isSns">
						<label for="pw1"
							>비밀번호 <span class="fs-12 fc-red">*</span></label
						>
						<input
							type="password"
							name="pw1"
							id="pw1"
							class="w-100"
							v-model="pass"
						/>
						<span class="info-block blue"
							>※ 비밀번호는 8자 이상, 영문/숫자/기호 포함입니다.</span
						>
					</li>
					<li v-if="!isSns">
						<label for="pw2"
							>비밀번호 <span class="fs-12 fc-red">*</span></label
						>
						<input
							@input="onPassinput"
							type="password"
							name="pw2"
							id="pw2"
							class="w-100"
							v-model="confirmPass"
						/>
						<span v-if="passDanger" class="info-block danger"
							>비밀번호를 확인하여주세요.</span
						>
					</li>
					<li>
						<span class="txt"
							>휴대폰 번호 <span class="ml-5 fs-12 fc-red">*</span></span
						>
						<div class="d-flex-between">
							<span class="w-32">
								<label for="uPhone2" class="blind">휴대폰번호</label>
								<input
									type="text"
									name="uPhone2"
									id="uPhone2"
									maxlength="3"
									:value="phone_1"
									class="w-100"
									readonly
								/>
							</span>
							<span class="w-32">
								<label for="uPhone3" class="blind">휴대폰번호</label>
								<input
									type="text"
									name="uPhone3"
									id="uPhone3"
									maxlength="4"
									:value="phone_2"
									class="w-100"
									readonly
								/>
							</span>
							<span class="w-32">
								<label for="uPhone4" class="blind">휴대폰번호</label>
								<input
									type="text"
									name="uPhone4"
									id="uPhone4"
									maxlength="4"
									:value="phone_3"
									class="w-100"
									readonly
								/>
							</span>
						</div>
					</li>
					<UserAdditionalProfile />
					<li>
						<span class="txt">제휴사 선택</span>
						<div class="bg-gray-l">
							<input
								type="checkbox"
								ref="ckeck_1"
								id="chk1"
								name="chk-1"
								checked="checked"
								readonly
							/>
							<label for="chk1" class="mr-10" style="color: #000"
								>FLEX TV</label
							>
						</div>
					</li>
					<li>
						<span class="txt">마케팅 정보 수신동의</span>
						<div class="bg-gray-l">
							<input type="checkbox" id="chk3" name="chk-2" ref="checkEmail" />
							<label for="chk3" class="mr-10">E-MAIL</label>
							<input type="checkbox" id="chk4" name="chk-2" ref="checkSMS" />
							<label for="chk4" class="mr-10">SMS</label>
						</div>
					</li>
				</ul>
				<div class="board-bottom mb-40 d-flex-between">
					<button
						type="button"
						class="btn btn-xl btn-outline-gray w-50 mr-10"
						@click="signUpCancle"
					>
						취소
					</button>
					<button
						type="button"
						@click="signUpSubmitConfirm"
						class="btn btn-xl btn-blue w-50"
					>
						회원가입
					</button>
				</div>
			</form>
			<!-- } 회원정보입력 end -->
		</div>
		<!-- } content end -->
	</div>
	<!--  } container end -->
	<!-- <Footer/> -->
</template>

<script>
import { useRoute, useRouter } from 'vue-router'

import { onMounted, ref } from 'vue'
import { useStore } from 'vuex';
import axios from '@/axios';
import { useToast } from '@/composables/toast';
import { hasRedirectUrl, redirectToCallbackWithAuth } from '@/helpers/redirect'
import { signIn, signInSns } from '@/helpers/auth'
import UserAdditionalProfile from "@/components/UserAdditionalProfile";
import { MEMBER_ID_RULE, PASSWORD_RULE } from '@/consts/validation'
import useApp from '@/hooks/useApp'
import { getHomeUrl } from '@/helpers/partner'

export default {
  components: {
    UserAdditionalProfile
    // Footer,
  },
  setup() {
    const {
      triggerToast
    } = useToast();
    const { app, nextUrl, isNativeApp } = useApp()
    const ckeck_1 = ref(null);
    const passDanger = ref(true)
    const route = useRoute();
    const router = useRouter();
    const uName = ref('');
    const mId = ref('');
    const pass = ref('');
    const confirmPass = ref('');
    const phone_1 = ref('');
    const phone_2 = ref('');
    const phone_3 = ref('');
    const permitPartners = ref([]);
    const email_1 = ref('');
    const email_2 = ref('');
    const zipcode = ref('');
    const addr1 = ref('');
    const addr2 = ref('');
    const isSns = ref(false)


    var imp_uid;

    var pw_passed = true;  // 추후 벨리데이션 처리시에 해당 인자값 확인을 위해

    const embed = ref(null);
    const checkEmail = ref(null);
    const checkSMS = ref(null);
    const isCheckBtn = ref(true)
    const store = useStore();

    var fromRouteObj = null

    async function signUpSubmitConfirm() {
      const success = await apiCallGetpartnerscode()
      if (!success) {
        return
      }
      let token = null
      if (fromRouteObj.sns) {
        const data = await signInSns({
          pid: (route.query?.app || app.value).includes('money') ? 'flexmoney' : 'flexlife',
          sns: fromRouteObj.sns,
          sns_code: fromRouteObj.accessToken
        })
        token = data.resultMap.token
      } else {
        const data = await signIn(mId.value, confirmPass.value)
        token = data.resultMap.token
      }
      const searchParams = new URLSearchParams(window.location.search)
      const redirectUrl = searchParams.get('redirect_url')
      console.log(redirectUrl, nextUrl.value, 'redirect_url')
      window.alert('가입에 성공하였습니다.')
      redirectToCallbackWithAuth(route.query, token, redirectUrl || nextUrl.value)
    }

    const signUpCancle = () => {
      router.push({
        name: 'Main',
      });
    }


    const apiCallGetpartnerscode = async () => {
      try {
        const code = route.query?.app?.includes('money') ? 'flexmoney' : 'flexlife'
        const res = await axios.get(`member/getpartnerscode/${code}`);
        let json = res.data;
        const success = await apiCallSignUp(json.resultMap.token);
        return success
      } catch (error) {
        triggerToast(error, 'danger');
        console.log("apiCallGetpartnerscode error", error);
        return false;
      }
    }


    const onPassinput = () => {
      if (pass.value == confirmPass.value) {
        passDanger.value = false;
      } else {
        passDanger.value = true;
      }
    }

    const fn_pw_check = () => {
      var pw = pass.value; //비밀번호
      var pw2 = confirmPass.value; // 확인 비밀번호
      var id = mId.value; // 아이디
      pw_passed = true;
      if (id.length == 0) {
        alert("아이디를 입력해주세요");
        return false;
      } else {
        if (isCheckBtn.value) {
          alert("아이디 중복체크를 해주세요.");
          return false;
        }
      }
      if (isSns.value) return true
      if (pw.length == 0) {
        triggerToast("비밀번호를 입력해주세요", 'danger');
        return false;
      } else {
        if (pw != pw2) {
          triggerToast("비밀번호가 일치하지 않습니다.", 'danger');
          return false;
        }
      }
      if (!PASSWORD_RULE.test(pw)) {
        triggerToast('비밀번호는 8~15자 이내의 대 소문자, 숫자, 특수문자입니다.', 'danger');
        return
      }
      if (pw.indexOf(id) > -1) {
        alert("비밀번호는 ID를 포함할 수 없습니다.");
        return false;
      }
      var SamePass_0 = 0; //동일문자 카운트
      var SamePass_1 = 0; //연속성(+) 카운드
      var SamePass_2 = 0; //연속성(-) 카운드
      for (var i = 0; i < pw.length; i++) {
        var chr_pass_0;
        var chr_pass_1;
        var chr_pass_2;
        if (i >= 2) {
          chr_pass_0 = pw.charCodeAt(i - 2);
          chr_pass_1 = pw.charCodeAt(i - 1);
          chr_pass_2 = pw.charCodeAt(i);
          //동일문자 카운트
          if ((chr_pass_0 == chr_pass_1) && (chr_pass_1 == chr_pass_2)) {
            SamePass_0++;
          } else {
            SamePass_0 = 0;
          }
          //연속성(+) 카운드
          if (chr_pass_0 - chr_pass_1 == 1 && chr_pass_1 - chr_pass_2 == 1) {
            SamePass_1++;
          } else {
            SamePass_1 = 0;
          }
          //연속성(-) 카운드
          if (chr_pass_0 - chr_pass_1 == -1 && chr_pass_1 - chr_pass_2 == -1) {
            SamePass_2++;
          } else {
            SamePass_2 = 0;
          }
        }
        if (SamePass_0 > 0) {
          alert("동일문자를 3자 이상 연속 입력할 수 없습니다.");
          pw_passed = false;
        }
        if (SamePass_1 > 0 || SamePass_2 > 0) {
          alert("영문, 숫자는 3자 이상 연속 입력할 수 없습니다.");
          pw_passed = false;
        }
        if (!pw_passed) {
          return false;
        }
      }
      return true;
    }

    const oncheckMid = async () => {
      if (!isCheckBtn.value) {
        return
      }
      const id = mId.value
      if (!id) {
        triggerToast('아이디를 입력하세요.', 'danger')
        return
      }
      if (!MEMBER_ID_RULE.test(id)) {
        triggerToast('아이디는 6~15자 영문, 숫자입니다.', 'danger')
        return
      }
      try {
        const res = await axios.get(`/member/midcheck?mid=${mId.value}`);
        let json = res.data;
        if (json.resultMap.returnVal == 1) {
          alert("사용가능한 아이디입니다.");
          isCheckBtn.value = false
        } else {
          alert(json.message);
        }
      } catch (error) {
        console.log(error);
        triggerToast(error, 'danger');
        return false;
      }
    }

    const onEmailInputType = (e) => {
      console.log('onEmailInputType:', e.target.value)
      email_2.value = e.target.value;
    }

    const apiCallSignUp = async (ptoken) => {
      if (!fn_pw_check()) return;
      const params = {
        "mid": mId.value,
        "passwd": confirmPass.value,
        "name": uName.value,
        "phone": phone_1.value + phone_2.value + phone_3.value,
        "email": email_1.value ? (email_1.value + '@' + email_2.value) : undefined,
        "zipcode": zipcode.value,
        "addr1": addr1.value,
        "addr2": addr2.value,
        "ptoken": ptoken,
        "permitPartners": route.query?.app?.includes('money') ? ['flextv', 'flexmoney'] : ['flextv'],
        "imp_uid": imp_uid,
        "agreeSms": checkSMS.value.checked ? 'Y' : 'N',
        "agreeEm": checkEmail.value.checked ? 'Y' : 'N',
      }
      if (fromRouteObj.sns) {
        params.sns = fromRouteObj.sns;
        params.sns_token = fromRouteObj.accessToken;
      }
      console.log("apiCallSignUp: params ", params)
      try {
        const res = await axios.post(`member/join`, params);
        let json = res.data;
        console.log("apiCallSignUp:", json)
        if (json.returnCode == 1) {
          if (json.resultMap.returnVal == 1) {
            if (!hasRedirectUrl()) {
              setTimeout(() => {
                router.push({
                  name: 'SignupSuccess',
                });
              }, 500)
            }
            return true
          } else {
            triggerToast(json.message, 'danger');
          }
        }
      } catch (error) {
        console.log("apiCallSignUp error :", error);
        return false;
      }
    }

    const onChange = () => {
    }

    const onClickSignupClose = () => {
      if (isNativeApp()) {
        window.location.href = getHomeUrl('money')
        return
      }
      router.push({
        name: 'Main',
      });
    }

    onMounted(() => {
      store.commit('common/CHANGE_CURRENT_PAGE', 'Signup');
      console.log("store:", store.state.common.currentPage)
      console.log("route signup:", JSON.parse(route.params.item))

      fromRouteObj = JSON.parse(route.params.item);
      uName.value = fromRouteObj.name;
      phone_1.value = fromRouteObj.phone.substr(0, 3);
      phone_2.value = fromRouteObj.phone.substr(3, 4);
      phone_3.value = fromRouteObj.phone.substr(7);

      isSns.value = !!fromRouteObj.sns

      imp_uid = fromRouteObj.imp_uid;
    })

    return {
      uName,
      mId,
      pass,
      confirmPass,
      phone_1,
      phone_2,
      phone_3,
      permitPartners,
      email_1,
      email_2,
      zipcode,
      addr1,
      addr2,
      signUpSubmitConfirm,
      signUpCancle,
      onPassinput,
      passDanger,
      ckeck_1,
      onChange,
      onEmailInputType,
      embed,
      checkEmail,
      checkSMS,
      oncheckMid,
      isCheckBtn,
      isSns,
      onClickSignupClose
    }
  }
}
</script>

<style></style>
