<template>
	<!-- container start { -->
	<div id="container" class="sub-contents">
		<!-- content start { -->
		<div id="contents">
			<!-- sub title h2 start -->
			<!-- <div class="sub-title">
          <div class="position">
              <h2 @click="$router.go(-1)">
                  <img src="@/assets/images/cm/ico_back.png" alt="back" class="mr-5"> 회원정보변경
              </h2>
          </div>
      </div>   -->

			<!-- sub title text start -->
			<div class="sub-title-txt">
				<p>회원정보를 정확하게 입력해주세요.</p>
			</div>
			<!-- //sub title text end -->

			<p class="p-sub-tit">
				회원정보입력 <span class="fs14 fc-red">* 는 필수입력 항목입니다.</span>
			</p>
			<!-- table type 1 start -->
			<table class="tbl-type1 tbl-top-line">
				<colgroup>
					<col style="width: 25%" />
					<col style="width: 75%" />
				</colgroup>
				<tbody>
					<!--tr>
            <th>이름 <span class="ml-5 fs-12 fc-red">*</span></th>
            <td>{{memberObj.NAME}}</td>
        </tr-->
					<tr>
						<th>아이디 <span class="ml-5 fs-12 fc-red">*</span></th>
						<td>{{ memberObj.MID }}</td>
					</tr>
					<tr v-if="!isSnsAuth">
						<th>비밀번호</th>
						<td>
							<div class="d-flex-between">
								<span>********</span>
								<a @click="changePassword" style="cursor: pointer">
									<button type="button" class="btn btn-default">변경</button>
								</a>
							</div>
						</td>
					</tr>
					<tr>
						<th>휴대폰 번호</th>
						<td>
							<div class="d-flex-between">
								<span>{{ phone_1 }}-****-****</span>
								<a @click="onChangePhone" style="cursor: pointer">
									<button type="button" class="btn btn-default">변경</button>
								</a>
							</div>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<span class="fs14 fc-red"
								>* 휴대폰 번호를 정확하게 입력하지 않을 경우 회원 혜택이 제한될
								수 있습니다.</span
							>
						</td>
					</tr>
					<tr>
						<th>이메일주소</th>
						<td>
							<div class="d-flex-start">
								<span class="ml-10 w-70">
									<label for="inputText4" class="blind">이메일주소</label>
									<input
										type="text"
										name="inputText4"
										id="inputText4"
										class="w-100"
										v-model="email"
									/>
								</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<p class="mt-10 mb50 fs14">
				· 회원정보를 변경하시면, 가입하신 제휴사의 회원정보가 함께 변경됩니다.
			</p>

			<p class="p-sub-tit mt-50">마케팅 정보 수신동의</p>
			<!-- table type 1 start -->
			<table class="tbl-type1 tbl-top-line">
				<colgroup>
					<col style="width: 25%" />
					<col style="width: 75%" />
				</colgroup>
				<tbody>
					<tr>
						<th>마케팅정보<br />수신동의</th>
						<td>
							<div class="mb-5">
								<input type="checkbox" id="chk7" name="chk2" ref="chEmail" />
								<label for="chk7" class="mr-10">E-MAIL</label>
							</div>
							<div>
								<input type="checkbox" id="chk8" name="chk2" ref="chSMS" />
								<label for="chk8" class="mr-10">SMS</label>
							</div>
							<!-- <input type="checkbox" id="chk9"  name="chk2" ref="chTM" >
            <label for="chk9" class="mr-10">TM</label>
            <input type="checkbox" id="chk10"  name="chk2" ref="chDM">
            <label for="chk10" class="mr-10">DM</label> -->
						</td>
					</tr>
				</tbody>
			</table>
			<!-- //table type 1 end -->
			<p class="mt-10 mb50 fs14">
				· 기타 문의는 고객센터(1588-7381)로 문의바랍니다.
			</p>

			<div class="d-flex-start my-30">
				<button
					type="button"
					@click="onModifyCancle"
					class="btn btn-xl w-50 mr-10"
				>
					취소
				</button>
				<button
					type="button"
					@click="onModifyOk"
					class="btn btn-xl btn-outline-blue w-50"
				>
					변경완료
				</button>
			</div>
		</div>
		<!-- } content end -->
	</div>
	<!--  } container end -->
	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue';
import axios from '@/axios';
import { closeWindow, redirectToCallbackWithAuth } from '@/helpers/redirect'
import { createRedirectUrl } from '@/helpers/redirect'
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';
import * as validator from 'validator'

export default {
  components: {
    Footer,
  },
  setup() {
    const memberObj = ref({});
    const phone_1 = ref('');
    const phone_2 = ref('');
    const phone_3 = ref('');
    const email = ref('');
    const zipcode = ref('');
    const addr_1 = ref('');
    const addr_2 = ref('');
    const router = useRouter();
    const chEmail = ref(null);
    const chSMS = ref(null);
    const partnersObj = ref([]);
    const isSnsAuth = ref(false)

    const embed = ref(null);
    const store = useStore();
    const route = useRoute();

    onMounted(() => {
      store.commit('common/CHANGE_CURRENT_PAGE', 'ModifyProfile');
      apiCallGetmypartners()
    })
    const apiCallGetmember = async () => {
      var token = sessionStorage.getItem('token');
      try {
        const res = await axios.get(`loginmember/getmember`, {
          headers: {
            'token': token
          }
        });
        let json = res.data;
        memberObj.value = json.resultMap.member;

        phone_1.value = memberObj.value.PHONE.substr(0, 3);
        phone_2.value = memberObj.value.PHONE.substr(3, 4);
        phone_3.value = memberObj.value.PHONE.substr(7);
        email.value = memberObj.value.EMAIL;

        zipcode.value = memberObj.value.ZIPCODE;
        addr_1.value = memberObj.value.ADDR1 ? memberObj.value.ADDR1 : '';
        addr_2.value = memberObj.value.ADDR2 ? memberObj.value.ADDR2 : '';


        memberObj.value.AGREE_SMS == 'Y' ? chSMS.value.checked = true : chSMS.value.checked = false;
        memberObj.value.AGREE_EM == 'Y' ? chEmail.value.checked = true : chEmail.value.checked = false;

        isSnsAuth.value = json.resultMap.isKakao === 'Y'

        console.log("apiCallGetmember:", json)
      } catch (error) {
        console.log("apiCallGetpartnerscode error", error);
        return false;
      }
    }

    const apiCallGetmypartners = async () => {
      var token = sessionStorage.getItem('token');
      try {
        const res = await axios.get(`loginmember/getmypartners`, {
          headers: {
            'token': token
          }
        });

        const json = res.data;
        partnersObj.value = (json?.resultMap?.dataList ?? []).filter(v => v.PID === 'flexmoney');

      } catch (error) {
        console.log("apiCallGetmypartners error", error);
        return false;
      }
    }

    const onModifyOk = () => {
      apiCallMembermodify().then((success) => {
        if (success) {
          redirectToCallbackWithAuth(route.query)
        }
      })
    }

    const onModifyCancle = () => {
      if (route.query.app && route.query.redirect_url) {
        window.location.href = createRedirectUrl(route.query.redirect_url)
        closeWindow(route.query.redirect_url)
        return
      }
      router.push({
        name: 'Main',
      });
    }

    const onChangePhone = () => {
      router.push({
        path: '/certificate',
        query: {
          page: 'ChangePhone',
          app: route.query.app,
          redirect_url: route.query.redirect_url
        }
      })
    }

    const changePassword = () => {
      router.push({
        path: '/changePassword',
        query: {
          app: route.query.app,
          redirect_url: route.query.redirect_url
        }
      })
    }

    const valiEmail = () => {
      if (!email.value) return true
      if (!validator.isEmail(email.value)) return false

      return true
    }

    const apiCallMembermodify = async () => {
      var params = {
        "email": email.value,
        "imp_uid": "",
        "agreeSms": chSMS.value.checked ? 'Y' : 'N',
        "agreeEm": chEmail.value.checked ? 'Y' : 'N',
        "zipcode": zipcode.value,
        "addr1": addr_1.value,
        "addr2": addr_2.value,
      }
      const token = sessionStorage.getItem('token');
      if (!valiEmail()) {
        alert('이메일을 확인해 주세요.')
        return false
      }

      try {
        const res = await axios.put(`loginmember/membermodify`, params, {
          headers: {
            'token': token
          }
        });
        let json = res.data;
        console.log("apiCallMembermodify:", json)

        if (json.returnCode == 1) {
          if (json.resultMap.returnVal == 1) {
            alert("회원정보를 변경하였습니다.");
            return true
          } else {
            alert(json.message || '유효하지 않은 인자값 입니다.')
          }
        } else {
          alert("회원정보를 변경을 실패하였습니다.");
        }
      } catch (error) {
        console.log("apiCallGetpartnerscode error", error);
        return false;
      }
    }

    const checkAll = (e) => {

      console.log()
      if (e.currentTarget.checked) {
        chEmail.value.checked = true;
        chSMS.value.checked = true;
      } else {
        chEmail.value.checked = false;
        chSMS.value.checked = false;
      }
    }

    apiCallGetmember();

    return {
      memberObj,
      phone_1,
      phone_2,
      phone_3,
      email,
      zipcode,
      addr_1,
      addr_2,
      onModifyCancle,
      onModifyOk,
      onChangePhone,
      changePassword,
      checkAll,
      chEmail,
      chSMS,
      isSnsAuth,
      embed,
      partnersObj,
      valiEmail
    }
  }

}
</script>

<style></style>
