<template>        
    <!-- container start { -->
    <div id="container" class="sub-contents">
        <!-- content start { -->
        <div id="contents">

            <!-- <div class="sub-title">
                <div class="position">
                    <h2 @click="$router.go(-1)">
                        <img src="@/assets/images/cm/ico_back.png" alt="back" class="mr-5"> 온라인문의
                    </h2>
                </div>
            </div>   -->

            <!-- sub notice tab start { -->
            <div class="notice-tab">
                <div><router-link :to="{ name: 'CenterNotice' }">공지사항 </router-link></div>
                <div><router-link :to="{ name: 'CenterQuestions' }">자주하는 질문 </router-link></div>
                <div class="active"><router-link :to="{ name: isLogin ? 'CenterQnA' : 'Login' }">온라인문의 </router-link></div>
            </div>
            <!-- } sub notice tab end -->

            <div>
                <ul class="singup-li mt-40">
                    <li>
                        <span class="txt">이름</span>
                        <div class="in-txt">{{ memberObj.NAME }}</div>
                    </li>
                    <li>
                        <span class="txt">아이디</span>
                        <div class="in-txt">{{ memberObj.MID }}</div>
                    </li>
                    <li>
                        <label for="type">문의유형</label>
                        <div class="form-select w-100">
                            <select id="category" name="category" v-model="category">
                                <option value="일반" selected>일반</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <label for="subject">제목</label>
                        <div class="form-select w-100">
                            <input type="text" id="subjectInput" name="subjectInput" class="w-100" placeholder="제목을 입력해 주세요." v-model="subject">
                        </div>
                    </li>
                    <li>
                        <label for="subject">내용</label>
                        <QuillEditor name="content" theme="snow" style="font-size:16px; height:300px" ref="contents" placeholder="내용을 입력해주세요." />
                        <span class="fs14 info-block normal">
                            ※ 온라인 상담을 통한 전화 상담요청은 불가하오니 양해하여 주시기 바랍니다.
                        </span>
                    </li>
                </ul>
            </div>


            <!-- qna start { -->
            <!-- <table class="qna-form mt-40">
                <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                </colgroup>
                <tr class="name">
                    <th>이름</th>
                    <td>{{ memberObj.NAME }}</td>
                </tr>
                <tr class="id">
                    <th>아이디</th>
                    <td>{{ memberObj.MID }}</td>
                </tr>
                <tr>
                    <th><label for="type">문의유형</label></th>
                    <td>
                        <div class="form-select w-30">
                            <select id="category" name="category" v-model="category">
                                <option value="일반" selected>일반</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label for="subject">제목</label></th>
                    <td>
                        <input type="text" id="subjectInput" name="subjectInput" class="w-100" placeholder="제목을 입력해 주세요." v-model="subject">
                    </td>
                </tr>
                <tr>
                    <th><label for="contents">내용</label></th>
                    <td>
                        <textarea name="contents" id="contents" rows="15" class="w-100" placeholder="궁금하신 내용을 입력해 주세요." v-model="contents"></textarea> -->
                        <!-- <QuillEditor name="content" theme="snow" style="height:300px" ref="contents" placeholder="내용을 입력해주세요." />
                        <QuillEditor name="content" theme="snow" style="font-size:16px; height:300px" ref="contents" placeholder="내용을 입력해주세요." />
                        <span class="info-block normal">
                            ※ 온라인 상담을 통한 전화 상담요청은 불가하오니 양해하여 주시기 바랍니다.
                        </span>
                    </td>
                </tr>
            </table> -->
            <div class="board-bottom d-flex-between">
                <button type="button" class="btn btn-xl btn-outline-gray w-50 mr-10">취소</button>
                <button type="button" class="btn btn-xl btn-outline-blue w-50" @click="saveBoard">등록</button>
            </div>
            <!-- } qna end -->

        </div>
        <!-- } content end -->
    </div>
    <!--  } container end -->

    <Footer/>

  
</template>

<script>
import Footer from '@/components/Footer.vue';
import axios from '@/axios';
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';


export default {
    components:{   
        Footer,
        QuillEditor,
    },
    setup() {
        const router = useRouter();
        const memberObj = ref({});
        const category = ref('일반');
        const subject = ref('');
        const contents = ref('');
        const store = useStore();
        const isLogin = computed(() => store.state.user.isLogin);           

        const apiCallGetmember = async() => {
            var token = sessionStorage.getItem('token');
            try { 
                const res = await axios.get(`loginmember/getmember`, { headers: { 'token': token } });
                let json = res.data;       
                memberObj.value = json.resultMap.member;
                console.log(memberObj.value);
            } catch(error) {
                console.log(error);           
                return false;
            }
        }

        const saveBoard = async() => {

            let params = {
                category: category.value,
                subject: subject.value,
                contents: contents.value.getHTML(),
            }

            var token = sessionStorage.getItem('token')
            
            try {
                const res = await axios.post(`board/counselingwrite`, params, { headers: {'token' : token} });
                let json = res.data;
                let contentString = contents.value.getHTML().replace(/<[^>]*>?/g, '');

                console.log( 'save ========>> ' + category.value + '/' + subject.value + '/' + contentString);
                
                if( subject.value == '' ) {
                    alert('제목을 입력하여 주십시오');
                    return;
                }

                if( contentString == '') {
                    alert('내용을 입력하여 주십시오.');
                    return;
                }

                if( json.returnCode == 1 ) {
                    if( json.resultMap.returnVal == 1 ) {
                        alert('온라인문의가 정상 등록 되었습니다.');
                        router.push ({
                            name: 'centerQnaSuccess'
                        })
                    } else {
                        alert('글 등록 실패하였습니다. 잠시 후 다시 시도하여 주십시오.');
                        router.push ({
                            name: 'CenterQnA'
                        })
                        return false;

                        // if( contents.value.getText().trim().length === 0 ) {
                        //     alert('내용을 입력하여주십시오');
                        //     return false;
                        // }

                    }
                }


            } catch(error) {
                console.log(error);
                return false;
            }
        }

        onMounted(()=> {
            store.commit('common/CHANGE_CURRENT_PAGE', 'CenterNotice');
            apiCallGetmember();
        })



        return {
            // route,
            memberObj,
            category,
            subject,
            contents,
            saveBoard,
            isLogin
        }    

    }
}
</script>

<style>

</style>