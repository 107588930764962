<template>   
    <Modal> 
        <template #title>
        {{ props.titleTxt}}
        </template>

        <template #body>
        {{ props.bodyTxt}}
        </template>

        <template #footer>
            <button
                type="button" 
                class="btn btn-secondary" 
                @click="onCancle"
            >
                Cancle
            </button>
            <button 
                type="button" 
                class="btn btn-danger"
                @click="onOk"
            >
                OK
            </button>
        </template>     
    </Modal>
    
</template>

<script>
import Modal from "@/components/Modal.vue"
export default {
  components:{
      Modal
  },
  props:{
    titleTxt:{
           type:String,
           require:true
    },
    bodyTxt:{
           type:String,
           require:true
    },
  },
  emits:['cancle', 'ok'],
  setup(props, {emit}){
  
     
     const onCancle = () =>{
      
        emit('cancle')
     }

     const onOk = () =>{
        emit('ok')
     }
     
     return {
         onCancle,
         onOk,
         props,
        
     }
   }

}
</script>

<style>

</style>