<template>
	<!-- container start { -->
	<div id="container" class="sub-contents">
		<!-- content start { -->
		<div id="contents">
			<!-- sub title h2 start -->
			<!-- <div class="sub-title">
                <div class="position">
                    <h2 @click="$router.go(-1)">
                        <img src="@/assets/images/cm/ico_back.png" alt="back" class="mr-5"> 공지사항
                    </h2>
                </div>
            </div>   -->

			<!-- sub title h2 start { -->
			<div class="sub-title select-header">
				<form action="" method="get" class="srch-form">
					<div class="form-select mb-10">
						<select name="policy" id="policy">
							<option value="now">현재 FLEXLIFE 개인정보처리방침</option>
							<!-- <option value="2022-06-01">FLEXLIFE 개인정보처리방침(2022.06.01)</option> -->
						</select>
					</div>
					<!-- <div class="w-100 my-10">
						<button type="submit" class="btn btn-lg w-100">검색</button>
					</div> -->
				</form>
			</div>
			<!-- } sub title h2 end -->

			<!-- company information start { -->
			<div class="terms-policy-content">
				<h1>1.수집하는 개인정보의 항목 및 처리방법</h1>
				<div class="mt-20">
					<h2>가. 수집하는 개인정보의 항목</h2>
					<p>
						주식회사 플렉스이엔엠(이하 ‘회사’라 함)은 원활한 서비스 제공을
						위하여 다음과 같은 정보를 수집하고 있습니다.
					</p>
				</div>
				<h4 class="mt-10">
					첫째, 회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해
					아래와 같은 최소한의 개인정보를 필수항목으로 수집하고 있습니다.
				</h4>
				<div>
					<p>▶ 회원 가입 시 : 휴대폰인증 가입 ◀</p>
					<p>
						아이디, 비밀번호, 성명, 닉네임, 휴대폰번호, 생년월일, 성별, 본인확인
						정보(CI), 소셜 계정(SNS로 로그인)
					</p>
					<p>▶ 환전 인증 시 ◀</p>
					<p>주민등록번호, 계좌주, 계좌번호</p>
				</div>

				<h4 class="mt-10">
					둘째, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이
					자동으로 생성되어 수집될 수 있습니다.
				</h4>
				<div>
					<p>
						IP Address, 쿠키, 방문일시, 서비스 이용기록, 불량 이용 기록, 모바일
						앱 설치 시 Device Token, 단말기 정보(모델명, os버전, 기기고유번호,
						펌웨어 버전등), 통신사 정보
					</p>
				</div>

				<h4 class="mt-10">
					셋째, 아이디를 이용한 부가 서비스 및 이벤트 응모 과정에서 해당
					서비스의 이용자(별도 동의한 회원)에 한해서만 아래와 같은 정보들이
					수집됩니다.
				</h4>
				<div>
					<p>선택사항 : 아이디, 이메일, 휴대전화번호, 주소, 이름, 연락처</p>
				</div>

				<h4 class="mt-10">
					넷째, 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수
					있습니다.
				</h4>
				<div>
					<p>신용카드 결제시 : 카드사명, 카드번호 등</p>
					<p>휴대전화 결제시 : 전화번호, 이동 통신사, 결제승인번호 등</p>
					<p>계좌이체시 : 은행명, 계좌번호 등</p>
					<p>상품권(쿠폰) 이용시 : 상품권(쿠폰) 번호</p>
				</div>

				<h4 class="mt-10">
					다섯째, 종량제 결제 서비스를 이용하는 과정에서 아래와 같은 결제
					정보들이 수집될 수 있습니다.
				</h4>
				<div>
					<p>
						결제 등을 불가피하게 필요한 경우 주민등록번호, 휴대전화번호, 통신사
						정보 등
					</p>
				</div>

				<div class="mt-20">
					<h2>나. 개인정보 처리방법</h2>
					<p>회사는 다음과 같은 방법으로 개인정보를 처리 합니다.</p>
					<p>
						홈페이지, 모바일, 서면양식, 팩스, 전화, 고객센터 문의하기, 이메일,
						이벤트 응모, 배송요청
					</p>
					<p>협력회사로부터의 제공</p>
					<p>생성정보 수집을 통한 수집</p>
				</div>

				<div class="mt-20">
					<h2>다. 고유식별정보의 처리</h2>
					<p>
						회사는 다음과 같은 방법으로 개인정보를 처리합니다. 고유식별정보란
						개인정보보호법 제24조 제1항 및 개인정보보호법 시행령 제 19조
						“대통령령으로 정하는 정보”로 주민등록번호, 여권번호, 운전면허번호,
						외국인등록번호를 말합니다. 회사는 아래 각호의 용도로 고유식별정보를
						수집하여 처리하고 있습니다. 거래 및 결제 서비스 사용을 위한 의무준수
						현물 경품 당첨자의 제세공과금 부과 불만처리 등 민원처리 수집된
						고유식별 정보는 개인정보보호법 기타 법률에서 규정하고 있는 경우를
						제외하고는 개인정보처리 목적 이외의 용도로 사용제공되지 않으며
						암호화 되어 안전하게 관리되고 있습니다.
					</p>
				</div>

				<h1 class="mt-30">2. 개인정보의 처리목적</h1>
				<div>
					<p>
						회사는 다음의 목적을 위하여 개인정보를 처리하며, 다음의 목적 이외의
						용도로 이용하지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법
						제 18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행합니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">
						가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산
					</h2>
					<p>
						콘텐츠 제공, 특정 맞춤 서비스 제공, 물품배송 또는 청구서 등 발송,
						본인인증, 구매 및 요금 결제, 요금추심
					</p>
				</div>

				<div>
					<h2 class="mt-10">나 . 회원관리</h2>
					<p>
						회원제 서비스 제공, 개인식별, 이용약관 위반 이용자에 대한 이용제한
						조치, 서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용
						행위 제재, 가입의사 확인, 가입 및 가입횟수 제한, 불만처리 등
						민원처리, 고지사항 전달, 회원탈퇴 의사의 확인
					</p>
				</div>

				<div>
					<h2 class="mt-10">다. 신규 서비스 개발 및 마케팅 광고 활용</h2>
					<p>
						신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
						제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회
						제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스 이용에 대한
						통계
					</p>
				</div>

				<h1 class="mt-30">3. 개인정보의 공유 및 제공</h1>
				<div>
					<p>
						회사는 이용자들의 개인정보를 “2. 개인정보의 처리목적”에서 고지한
						범위 내에서 사용하며, ‘플렉스라이프’라는 서비스명으로 통합
						운영/관리합니다. <br />
						이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로
						이용자의 개인정보를 외부에 공개하지 않습니다. <br />
						다만, 아래의 경우에는 예외하며 경우에 따라 개인정보 제공 여부를
						선택할 수 있습니다.<br />
					</p>
					<br />
					<h2 class="mt-10">통합회원 개인정보의 제3자 제공(선택)</h2>
					<table>
						<th>제공받는 자</th>
						<th>제공 목적</th>
						<th>제공하는 항목</th>
						<th>보유 및 이용기간</th>
						<tr>
							<td>
								플렉스티비 <br />
								(필수 제공)
							</td>
							<td>
								로그인, 포인트 <br />
								적립/사용 및 관련 <br />
								서비스 제공
							</td>
							<td>
								CI, 이름, 생년월일, 성별, <br />
								아이디, 비밀번호, <br />
								휴대전화번호, 캐시 정보, 포인트
							</td>
							<td>
								회원탈퇴 후 6개월간 <br />
								또는 제휴사 이용약관 및 <br />
								제3자 제공 동의 철회 후 <br />
								6개월
							</td>
						</tr>
						<tr>
							<td>
								플렉스머니 <br />
								(선택)
							</td>
							<td>
								로그인, 포인트 <br />
								적립/사용 및 관련 <br />
								서비스 제공
							</td>
							<td>
								CI, 이름, 생년월일, 성별, <br />
								아이디, 비밀번호, <br />
								휴대전화번호, 캐시 정보, 포인트
							</td>
							<td>
								회원탈퇴 후 6개월간 <br />
								또는 제휴사 이용약관 및 <br />
								제3자 제공 동의 철회 후 <br />
								6개월
							</td>
						</tr>
					</table>
				</div>

				<h2 class="mt-20">가. 이용자가 사전에 동의 한 경우</h2>
				<h2>
					나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
					방법에 따라 수사기관의 요구가 있는 경우
				</h2>
				<h2>
					다. ‘회원’은 제3자에게 개인정보 제공 여부를 선택할 수 있으며, 제 3자
					제공 및 활용 동의 여부와 관계없이 “통합회원” 서비스에 가입할 수
					있습니다. 단, ‘제 3자 개인정보 제공 여부’에 따라 부분적으로 서비스
					이용이 불가능할 수 있습니다.
				</h2>

				<h1 class="mt-30">4. 개인정보의 처리위탁</h1>
				<div>
					<p>
						회사는 서비스 향상을 위해서 아래와 같이 개인정보 처리를 위탁하고
						있으며, 위탁처리 기관과 위탁계약 시 개인정보보호법 제 26조에 따라
						필요한 사항을 규정하고 있으며 개인정보가 안전하게 처리하는지
						감독하고 있습니다.
					</p>
				</div>

				<div>
					<p>
						회사의 개인정보 위탁처리 기관 및 위탁 업무 내용은 아래와 같습니다.
					</p>
					<p>수탁업체</p>
					<p>위탁업무 내용</p>
					<p>개인정보 보유 및 이용기간</p>
					<p>NICE평가정보</p>
					<p>회원 가입 시 본인확인, CI발급</p>
					<p>
						해당 업체에서 이미 보유하고 있는 정보이기 때문에 별도 저장하지 않음
					</p>
				</div>

				<h1 class="mt-30">5. 개인정보의 보유 및 이용기간</h1>
				<div>
					<p>
						이용자의 개인정보는 원칙적으로 개인정보의 처리목적이 달성되면 지체
						없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 보존이유로 명시한
						보존 기간 동안 개인정보보호법 제 21조 단서에 근거하여 보존합니다.
						이는 개인정보보호법 제21조 제3항을 준수하기 위하여 기존의 저장공간과
						구분된 별도의 안전한 저장공간에 저장됩니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">가. 회사 내부 방침에 의한 정보보유 사유</h2>
					<p>
						보존 이유 : 회원탈퇴 시 소비자의 불만 및 분쟁해결 등 서비스 이용에
						혼란 및 부정사용(부정가입 등 비정상적인 서비스 이용)방지
					</p>
					<p>보존 항목 : 아이디, 본인확인 값(CI)</p>
					<p>보존 기간 : 6개월</p>
				</div>

				<div>
					<h2 class="mt-10">나. 관련법령에 의한 정보보유 사유</h2>
					<p>
						상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의
						규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한
						일정한 기간 동안 회원정보를 보관합니다.
					</p>
					<p>
						계약 또는 청약철회 등에 관한 기록 보존 이유 : 전자상거래 등에서의
						소비자보호에 관한 법률, 보존기간 :5년
					</p>
					<p>
						대금결제 및 재화 등의 공급에 관한 기록 보존 이유 : 전자상거래
						등에서의 소비자보호에 관한 법률, 보존기간 : 5년
					</p>
					<p>
						전자금융 거래에 관한 기록 보존 이유 : 전자금융거래법, 보존기간 : 5년
					</p>
					<p>
						소비자의 불만 또는 분쟁처리에 관한 기록 보존 이유 : 전자상거래
						등에서의 소비자보호에 관한 법률, 보존기간 : 3년
					</p>
					<p>웹사이트 방문기록 보존 이유 : 통신비밀보호법, 보존기간 : 3개월</p>
					<p>
						제휴사를 통한 개별적인 회원탈퇴를 하였어도 ‘통합회원 제도’에 따라,
						상기 서술된 근거로 개인정보를 보존할 수 있습니다.
					</p>
				</div>

				<h1 class="mt-30">6. 개인정보 파기절차 및 방법</h1>
				<div>
					<p>
						이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
						달성되면 지체 없이 파기합니다. 다만 기타 법령에 의해 보관해야 하는
						정보는 법령이 정한 일정 기간 동안 별도 분리 보관 후 즉시 파기
						합니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">가. 파기절차</h2>
					<p>
						이용자의 개인정보 수집 및 이용목적이 달성되면 지체 없이 파기되며
						기타 법령에 따라 보관되어야 할 경우 별도의 DB에 옮겨져 관련 법령 및
						내부규정을 준수하여 일정기간 동안 안전하게 보관된 후 지체 없이 파기
						됩니다. 동 개인정보는 법령에서 명시한 경우를 제외하고는 보유목적
						이외의 다른 용도로 이용되지 않습니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">나. 파기방법</h2>
					<p>
						종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
						파기합니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수
						없는 기술적 방법을 사용하여 삭제합니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">다. 개인정보 유효기간제(휴면계정 정책)</h2>
					<p>
						1년 동안 서비스 이용기록이 없는 휴면상태의 서비스이용자의 개인정보는
						이용자의 개인정보와 분리하여 별도로 저장 및 관리 됩니다. 다만,
						휴면상태의 서비스이용자의 요청이 있는 경우 서비스 이용을 재개하는
						시점에 다시 개인정보가 제공됩니다.
					</p>
					<p>
						회사는 휴면계정으로 전환되는 시점을 기준으로 30일 이전에 해당 내용에
						대해 이메일 등의 방식으로 이용자에게 공지합니다.
					</p>
					<p>
						분리 보관된 개인정보는 기타 법령에 특별한 규정이 있는 경우를
						제외하고는 해당 개인정보를 이용하거나 제공하지 않습니다. 또한 분리
						보관된 개인정보는 관련 법령에서 명시하고 있는 일정기간 경과 시 지체
						없이 파기되며, 파기 시점을 기준으로 30일 이전에 해당 내용에 대해
						이메일 등의 방식으로 이용자에게 공지합니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">라. 회원탈퇴 유예 제도(회원탈퇴 철회 정책)</h2>
					<p>
						회원탈퇴를 신청한 시점으로부터 30일간 이를 철회할 수 있습니다. 탈퇴
						유예 기간으로 전환된 이후, 회원은 언제든 이를 취소할 수 있으며 해당
						기간동안 모든 회원 정보는 보존됩니다.
					</p>
					<p>정확히 30일이 경과한 이후부터 회원탈퇴로 적용됩니다.</p>
				</div>

				<h1 class="mt-30">7. 이용자 및 법정대리인의 권리와 그 행사방법</h1>
				<div>
					<p>
						이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해
						미성년자(14세 ~ 19세) 아동의 개인정보를 조회하거나 수정할 수 있으며,
						회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나
						가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우
						서비스의 일부 또는 전부 이용이 어려울 수 있습니다.
					</p>
					<p>
						이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는
						‘개인정보변경’(또는 ‘회원정보수정’ 등)을, 가입해지(동의철회)를
						위해서는 “회원탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접 열람,
						정정 또는 탈퇴가 가능합니다.
					</p>
					<p>
						혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체
						없이 조치하겠습니다.
					</p>
					<p>
						이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
						완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
					</p>
					<p>
						또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정
						처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록
						하겠습니다.
					</p>
					<p>
						회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
						개인정보는 “5. 개인정보의 보유 및 이용기간”에 명시된 바에 따라
						처리하고 그외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
					</p>
				</div>
				<h1 class="mt-30">
					8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
				</h1>
				<div>
					<h2 class="mt-10">가. 쿠키란?</h2>
					<p>
						회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
						저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
					</p>
					<p>
						쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게
						보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에
						저장됩니다.
					</p>
					<p>
						이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드
						디스크에 저장되어 있는 쿠키의 내용을 파악하여 이용자의 환경설정을
						유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.
					</p>
					<p>
						쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며,
						이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수
						있습니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">나. 회사의 쿠키 사용 목적</h2>
					<p>
						이용자들의 방문한 서비스와 웹 사이트들에 대한 방문 및 이용형태,
						인기-검색어, 보안접속 여부, 뉴스편집 이용자 규모 등을 파악하여
						이용자에게 광고를 포함한 최적화된 맞춤형 정보를 제공하기 위해
						사용합니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">다. 쿠키의 설치/운영 및 거부</h2>
					<p>
						이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는
						웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
						저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
						있습니다.
					</p>
					<p>
						다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는
						이용에 어려움이 있을 수 있습니다.
					</p>
					<p>
						쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은
						다음과 같습니다.
					</p>
					<div>
						<p>① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</p>
						<p>② [개인정보 탭]을 클릭합니다.</p>
						<p>③ [개인정보처리 수준]을 설정하시면 됩니다.</p>
					</div>
				</div>

				<h1 class="mt-30">9. 개인정보의 기술적/관리적 보호 대책</h1>
				<div>
					<p>
						회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난,
						유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
						기술적/관리적 대책을 강구하고 있습니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">가. 비밀번호 암호화</h2>
					<p>
						이용자가 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어
						이용자 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를
						알고 있는 이용자 본인에 의해서만 가능합니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">나. 해킹 등에 대비한 대책</h2>
					<p>
						회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가
						유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
						개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신
						백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나
						손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여
						네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
						그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
						있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적
						장치를 갖추려 노력하고 있습니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">다. 처리 직원의 최소화 및 교육</h2>
					<p>
						회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한
						별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한
						수시 교육을 통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
					</p>
				</div>

				<div>
					<h2 class="mt-10">라. 개인정보보호전담기구의 운영</h2>
					<p>
						그리고 사내 개인정보보호전담기구 등을 통하여 개인정보처리방침의
						이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시
						수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 회사가
						개인정보보호 의무를 다 하였음에도 불구하고 이용자 본인의 부주의나
						회사가 관리하지 않는 영역에서의 사고 등 회사의 귀책에 기인하지 않은
						손해에 대해서는 회사는 일체의 책임을 지지 않습니다.
					</p>
				</div>

				<h1 class="mt-30">10. 개인정보보호 책임자 및 담당자의 연락처</h1>
				<div>
					<p>
						이용자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련
						민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다.
					</p>
				</div>

				<div>
					<p>개인정보 보호 책임자</p>
					<p>이름 : 정제원</p>
					<p>소속 : 운영팀</p>
					<p>직위 : 팀장</p>
					<p>연락처 : 1566-7381</p>
					<p>E-mail : help@flexenm.com</p>
				</div>

				<div>
					<p>개인정보 보호 관리자</p>
					<p>이름 : 이호진</p>
					<p>소속 : 개발부</p>
					<p>직위 : 팀장</p>
					<p>연락처 : 1566-7381</p>
					<p>E-mail : help@flexenm.com</p>
				</div>

				<div>
					<p>
						기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
						문의하시기 바랍니다.
					</p>
					<p>
						개인정보 분쟁조정위원회 (<a
							href="http://www.kopico.go.kr"
							target="_blank"
							>www.kopico.go.kr</a
						>
						/ 전화 1833-6972)
					</p>
					<p>
						개인정보침해신고센터 (<a
							href="http://privacy.kisa.or.kr"
							target="_blank"
							>privacy.kisa.or.kr</a
						>
						/ 국번없이 118)
					</p>
					<p>
						대검찰청 사이버범죄수사과 (<a
							href="http://www.spo.go.kr"
							target="_blank"
							>www.spo.go.kr</a
						>
						/ 국번없이 1301)
					</p>
					<p>
						경찰청 사이버안전국 (<a
							href="http://cyberbureau.police.go.kr"
							target="_blank"
							>cyberbureau.police.go.kr</a
						>
						/ 국번없이 182)
					</p>
					<p>
						청소년정보이용안전망 그린 i-Net (<a
							href="http://www.greeninet.or.kr"
							target="_blank"
							>www.greeninet.or.kr</a
						>
						/ 02-523-3566)
					</p>
				</div>

				<h1 class="mt-30">11. 기타</h1>
				<div>
					<p>
						회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를
						제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런
						통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해
						책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를
						클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의
						개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의 정책을
						확인하시기 바랍니다.
					</p>
				</div>
				<h1 class="mt-30">12. 고지의 의무</h1>
				<div>
					<p>
						본 개인정보처리방침에서 법령 정책 또는 보안기술의 변경에 따라 내용의
						추가, 삭제 및 수정이 있을 시에는 변경이 되는 개인정보 처리방침을
						시행하기 최소 7일전에 홈페이지의 ‘알려드립니다’ 게시판 혹은 별도
						공지를 통해 고지할 것입니다.
					</p>
					<div>
						<p>- 개인정보처리방침 버전번호 : V1.1</p>
						<p>- 개인정보처리방침 시행일자 : 2023년 1월 1일</p>
						<p>- 개인정보처리방침 변경공고일자 : 2023년 1월 1일</p>
					</div>
				</div>
			</div>
			<!-- } company information list end -->
		</div>
		<!-- } content end -->
	</div>
	<!--  } container end -->
	<Footer />
</template>

<script>
	import Footer from "@/components/Footer.vue";
	import { onMounted } from "vue";
	import { useStore } from "vuex";
	export default {
		components: {
			Footer,
		},
		setup() {
			const store = useStore();
			onMounted(() => {
				store.commit("common/CHANGE_CURRENT_PAGE", "Privacy");
			});

			return {};
		},
	};
</script>

<style></style>
