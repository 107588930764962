<template>

  <!-- header include start { -->
  <!-- <div data-include-path="include/header.html"></div> -->
  <header id="header" v-if="getHeaderType() !== 'empty'">
    <div class="header-area">
      <h1 class="logo" v-if="app === 'money'">
        <a :href="getHomeUrl('money')">
          <img src="@/assets/images/partners/money/logo.png" alt="logo">
        </a>
      </h1>
      <h1 v-else class="logo">
        <router-link :to="{ name: 'Main'}">
          <img src="@/assets/images/cm/logo.png" alt="logo">
        </router-link>
      </h1>
      <div class="lnb-area" v-if="!app">
        <ul class="lnb" @click="onClickNavClose">
          <li @click="onSignUp">
            <router-link :to="{ name: ''}">{{ isLogin ? '회원정보' : '회원가입' }}</router-link>
          </li>
          <li @click="onSignIn">
            <router-link :to="{ name: ''}">{{ isLogin ? '로그아웃' : '로그인' }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <div class="side-nav" :class="isNavOpen ? 'on' : ''" style="max-width: 300px;">
    <div class="nav-area">
      <div class="btn-nav-close">
        <span @click="onClickNavClose"><button type="button" class="btn"><img src="@/assets/images/cm/ico_close.png"
                                                                              alt="menu"></button></span>
      </div>
      <div class="nav-txt">
        <div
            v-if="!isLogin"
        >
          <span><img src="@/assets/images/cm/ico_c_lock.png" alt="lock"></span>
          <p>서비스를 이용하시려면 <strong @click="onSignIn" style="cursor: pointer">로그인</strong>을 해주세요.</p>
        </div>
        <div
            v-else
        >
          <span><img src="@/assets/images/cm/ico_share.png" alt="share"></span>
          <p><strong>하나의 아이디</strong>로 보다 편리하고 빠르게!</p>
        </div>
      </div>
      <nav class="nav-inner">
        <ul class="nav" @click="onClickNavClose">
          <li @click="onClickedMyFlex(0)">
            <router-link :to="{ name: ''}"><span><img src="@/assets/images/cm/ico_mypage.png" alt="mypage"></span> MY
              FLEX
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'CenterNotice'}"><span><img src="@/assets/images/cm/ico_cscenter.png"
                                                                  alt="cscenter"></span> 고객센터
            </router-link>
          </li>
        </ul>
        <div class="lnb-area">
          <ul class="lnb" @click="onClickNavClose">
            <li @click="onSignIn">
              <router-link :to="{ name: ''}"><span><img src="@/assets/images/cm/ico_lock.png"
                                                        alt="lock"></span>{{ isLogin ? '로그아웃' : '로그인' }}
              </router-link>
            </li>
            <li @click="onSignUp">
              <router-link :to="{ name: ''}"><i class="ico-lnb2"></i>{{ isLogin ? '회원정보' : '회원가입' }}</router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <div class="side-bg" :class="isNavOpen ? 'on' : ''">bg</div>
  <!-- } header end -->


</template>

<script>
import { useAuthentication, useCheckLogin } from '@/composables/commonFn';
import { useRouter } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex';
import { useToast } from '@/composables/toast';
import { requestLogout } from '@/helpers/auth'
import { getHomeUrl } from '@/helpers/partner'
import useApp from '@/hooks/useApp'

export default {

  setup() {
    const store = useStore();
    const router = useRouter();
    const isLogin = computed(() => store.state.user.isLogin);
    const currentPage = computed(() => store.state.common.currentPage);
    const isNavOpen = ref(false);
    const { app: appRef } = useApp()

    const app = appRef.value

    onMounted(() => {
      store.commit('common/SET_APP', app);
    })

    const getTitle = () => {
      console.log('gettitle', currentPage.value)
      var result = null;
      if (currentPage.value == "Profile") {
        result = '회원정보'
      } else if (currentPage.value == "ModifyProfile") {
        result = '회원정보 변경'
      } else if (currentPage.value == "RemoveProfile") {
        result = '회원탈퇴'
      } else if (currentPage.value == "FindPassword") {
        result = '비밀번호 찾기'
      } else if (currentPage.value == "Privacy") {
        result = '개인정보처리방침'
      } else if (currentPage.value == "CenterNotice") {
        result = '공지사항'
      } else if (currentPage.value == "Policy") {
        result = '이용약관'
      } else if (currentPage.value === 'ModifyPartner') {
        result = '제휴사 동의'
      }
      return result;
    }

    const getHeaderType = () => {
      if (['Signup', 'KakaoLogin', 'AppleLogin', 'GoogleLogin'].includes(currentPage.value)) {
        return 'empty';
      } else if (
          currentPage.value === 'Main'
          || currentPage.value === 'Profile'
          || currentPage.value === 'ModifyProfile'
          || currentPage.value === 'RemoveProfile'
          || currentPage.value === 'FindPassword'
          || currentPage.value === 'Privacy'
          || currentPage.value === 'CenterNotice'
          || currentPage.value === 'Policy'
          || currentPage.value === 'Certificate'

      ) {
        return 'title';
      }
      return 'normal';
    }
    const {
      getImpuid
    } = useAuthentication();

    const {
      checkLogin
    } = useCheckLogin();


    const {
      triggerToast
    } = useToast();

    checkLogin()

    const onSignUp = () => {
      if (isLogin.value) {
        router.push({
          name: 'Profile',
        });
      } else {
        router.push({
          path: '/certificate',
          query: {
            page: 'Signup',
            app
          }
        })
      }
    }

    const onSignIn = () => {
      onClickNavClose()
      if (isLogin.value) {
        callLogout();
      }
      router.push({
        name: 'Login',
      });
    }

    const callLogout = async () => {
      try {
        await requestLogout()
        store.commit('user/CHANGE_IS_LOGIN', false);
      } catch (error) {
        console.log(error);
        triggerToast(error, 'danger');
        return false;
      }
    }

    const onClickedMyFlex = (stt) => {
      if (isLogin.value) {
        if (stt == 0) {
          router.push({
            name: 'Profile',
          });
        } else if (stt == 1) {

          getImpuid('ModifyProfile');
        } else if (stt == 2) {
          router.push({
            name: 'RemoveProfile',
          });
        }
      } else {
        router.push({
          name: 'Login',
        });
      }
    }

    const onClickNav = () => {
      isNavOpen.value = !isNavOpen.value
    }
    const onClickNavClose = () => {
      isNavOpen.value = false;
    }

    return {
      onSignUp,
      isLogin,
      onSignIn,
      onClickedMyFlex,
      onClickNav,
      onClickNavClose,
      isNavOpen,
      currentPage,
      getTitle,
      getHeaderType,
      getHomeUrl,
      router,
      app
    }
  }
}
</script>

<style>
</style>

