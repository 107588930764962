<template>
    <!-- container start { -->
    <div id="container" class="sub-contents">
        <!-- content start { -->
        <div id="contents">

            <!-- qna success start { -->
            <div class="qna-success">
                <h3>온라인 문의가 정상 등록되었습니다.</h3>
                <img src="@/assets/images/cs/qna-success.png" width="160" alt="온라인 문의가 정상 등록되었습니다.">
                <p>
                    문의하신 내용에 대해 담당자 확인 후 빠른 시간 내에 답변 드리겠습니다.<br />
                    답변은 <strong>고객센터 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg> 온라인 문의</strong>에서 확인하실 수 있습니다.
                </p>
            </div>
            <!-- } qna success end -->

            <div class="mt-30 mb-50 d-flex-between">
                <router-link :to="{ name: 'Main' }" class="btn btn-xl btn-outline-gray w-50 mr-10">메인 페이지로 이동</router-link>
                <router-link :to="{ name: 'CenterQnA' }" class="btn btn-xl btn-blue w-50 px-1">온라인 문의내역 조회</router-link>
            </div>

        </div>
        <!-- } content end -->
    </div>
    <!--  } container end -->

    <Footer/>


</template>

<script>
import Footer from '@/components/Footer.vue';
import { onMounted} from 'vue'
import { useStore } from 'vuex';
export default {
    components:{
        Footer,
    },
    setup(){
        const store = useStore();
        onMounted(()=>{
            store.commit('common/CHANGE_CURRENT_PAGE', 'CenterNotice');
        })

        return{

        }
    }

}
</script>

<style>

</style>
