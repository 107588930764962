<template>
  <!-- container start { -->
  <div id="container">
    <!-- content start { -->
    <div id="contents">

      <!-- login start { -->
      <div class="login-area">
        <h1 class="login-title">서비스를 이용하시려면<br>로그인을 해주세요!</h1>

        <div class="login-section">
          <div class="login-tab">
            <ul class="tab-list">
              <li class="is_on">
                <a href="#loginId" class="btn-tab">아이디</a>
                <div id="loginId" class="tab-cont">
                  <ul class="login-li">
                    <li>
                      <label for="inText1" class="blind">아이디</label>
                      <input type="text" name="inText1" id="inText1" placeholder="아이디" class="w-100" v-model="txt_id">
                    </li>
                    <li>
                      <label for="inPw1" class="blind">비밀번호</label>
                      <input type="password" name="inPw1" id="inPw1" placeholder="비밀번호입력" class="w-100"
                             v-model="txt_pass" @keyup="onKeyup">
                    </li>
                    <li class="chk-li">
                      <div>
                        <input type="checkbox" id="chk1" ref="saveId">
                        <label for="chk1">아이디 저장</label>
                      </div>
                      <div>
                        <span><a @click="onFindId" href="#">아이디 찾기</a></span>
                        <span><a @click="onFindPass" href="#">비밀번호 찾기</a></span>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <div class="btn-login">
              <button type="button" class="btn btn-blue" @click="onLoginClicked">로그인</button>
            </div>
            <div style="margin-top: 15px; color: #999999; font-size: 14px; display: flex; justify-content: center; align-content: center">
              <img src="/logo-flextv.svg" alt="logo" width="60" height="32" />
              <span style="line-height: 32px; margin-left: 10px">
                플렉스티비 계정으로 이용이 가능합니다.
              </span>
            </div>
            <div class="login-sns">
              <!-- <div><a href="#"><img src="@/assets/images/us/ico_facebook.png" alt="페이스북"></a></div>>
               <div><a @click="onNaverLogin" href="#"><img src="@/assets/images/us/ico_naver.png" alt="네이버"></a></div>
              -->
              <div><a :href="appleSignUrl"><img src="@/assets/images/cm/ico_apple.webp" width="35" alt="애플"></a></div>
              <div><a @click="onGoogleLogin" href="#"><img src="@/assets/images/google-logo.svg.png" width="35" alt="구글"></a></div>
              <div><a @click="onKakaoLogin" href="#"><img src="@/assets/images/us/ico_kakao.png" alt="카카오"></a></div>
            </div>
            <div class="login-info">
              <span @click="onSignUp" class="link">
                <a href="#" class="btn btn-black">
                  <span class="mr-5"><img src="@/assets/images/cm/ico_w_logo.png" alt="flex life"></span>
                  통합 회원가입
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- } login end -->

    </div>
    <!-- } content end -->
  </div>
  <!--  } container end -->
  <Footer/>

  <FindidModal
      v-if="showFindidModal"
      @close="closeFindidModal"
      @ok="okFindidModal"
      :findIds="findIds"
  />

  <BasicModal1b
      v-if="showModal"
      @close="closeModal"
      @ok="okModal"

      :titleTxt="titleTxt"
      :bodyTxt="bodyTxt"
  />

</template>

<script>
import { useToast } from '@/composables/toast';
import { onMounted, ref, computed } from 'vue'
import useApp from '@/hooks/useApp'
import Footer from '@/components/Footer.vue';
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex';
import BasicModal1b from '@/components/BasicModal1b.vue';
import FindidModal from '@/components/FindidModal.vue';
import Config from '@/config.js'
import { hasRedirectUrl, redirectToCallbackWithAuth } from '@/helpers/redirect'
import { signIn } from '@/helpers/auth'

export default {
  components: {
    Footer,
    BasicModal1b,
    FindidModal,
    // KakaoLogin
  },
  setup() {
    const route = useRoute();
    const {triggerToast} = useToast();

    const titleTxt = ref('')
    const bodyTxt = ref('')
    const showModal = ref(false);
    const showFindidModal = ref(false);

    const { saveQuery } = useApp()
    const store = useStore();
    const router = useRouter();
    const { app: appRef } = useApp()

    const txt_id = ref('');
    const txt_pass = ref('');
    const isLogin = computed(() => store.state.user.isLogin);

    const saveId = ref(null);

    const findIds = ref([]);
    const {Kakao} = window;

    const app = appRef.value

    const appleSignUrl = `https://appleid.apple.com/auth/authorize?client_id=kr.co.flexlife.flexLife&redirect_uri=${encodeURIComponent(Config.URL.apiLifeUrl + 'sns/appleLoginCallback')}&response_type=code%20id_token&state=${app || 'qwer1234'}&scope=name%20email&response_mode=form_post`

    const onLoginClicked = async () => {
      callLogin()
    }

    const callLogin = async () => {
      const mid = txt_id.value
      const passwd = txt_pass.value
      const params = { mid, passwd }
      if (!mid) {
        triggerToast('아이디를 입력해 주세요.', 'danger');
        return
      }
      if (!passwd) {
        triggerToast('비밀번호를 입력해 주세요.', 'danger');
        return
      }
      try {
        const json = await signIn(txt_id.value, txt_pass.value, route.query['app'])
        if (json.returnCode == 1) {
          if (json.resultMap.returnVal == 1) {
            sessionStorage.setItem('token', json.resultMap.token);
            if (saveId.value.checked) {
              sessionStorage.setItem('saveId', params.mid);
            }
            store.commit('user/CHANGE_IS_LOGIN', true);
            store.commit('user/CHANGE_MEMBER_DATA', json.resultMap.member);
            if (hasRedirectUrl()) {
              redirectToCallbackWithAuth(route.query, json.resultMap.token)
            } else {
              router.push({
                name: 'Main',
              });
            }
          } else if (json.resultMap.returnVal === -1006) {
            if (!window.confirm('로그인을 위해 본인인증이 필요한 계정입니다.이동하시겠습니까?')) return
            sessionStorage.setItem('temp_token', json.resultMap.token);
            router.push({
              path: '/certificate',
              query: {
                page: 'AddPersonalAuth',
                app: route.query.app
              }
            })
          } else {
            showModal.value = true;
            titleTxt.value = 'flexlife.co.kr 내용:'
            bodyTxt.value = json.message;
          }
        }
      } catch (error) {
        triggerToast(error, 'danger');
        return false;
      }
    }
    const closeModal = () => {
      showModal.value = false;
    }
    const okModal = () => {
      showModal.value = false;
    }

    const onSignUp = () => {
      router.push({
        path: '/certificate',
        query: {
          page: 'Signup',
          app: route.query.app
        }
      })
    }
    const onFindId = () => {
      router.push({
        path: '/certificate',
        query: {
          page: 'FindId'
        }
      })
    }

    const okFindidModal = () => {
      console.log("okFindidModal")
      showFindidModal.value = false;
    }

    const closeFindidModal = () => {
      showFindidModal.value = false;
    }

    const onFindPass = () => {
      console.log("onFindPass")
      router.push({
        name: 'FindPassword',
        //  params: {
        //    item:JSON.stringify(testObj)
        //  }
      });

    }

    onMounted(() => {
      saveQuery()

      console.log("login page ", route.params, route.query);
      store.commit('common/CHANGE_CURRENT_PAGE', 'Login');

      let _saveid = sessionStorage.getItem('saveId');
      if (_saveid) {
        txt_id.value = _saveid;
      }
      if (route.params && route.params.item) {
        txt_id.value = route.params.item;
      }
      console.log("saveId:", sessionStorage.getItem('saveId'))
    })

    const onKeyup = (e) => {
      if (e.keyCode == 13) {
        callLogin();
      }
    }

    const onKakaoLogin = () => {
      Kakao.Auth.authorize({
        redirectUri: Config.URL.redirectUri_kakao,
      });
    }

    const onGoogleLogin = () => {
      const clientId = '1045595419140-dtm6tjmhj97db7dt9etot1fjrdovdn9t.apps.googleusercontent.com';
      const redirectUrl = Config.URL.redirectUri_google
      const url = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=' + clientId +
          '&redirect_uri=' + redirectUrl +
          '&response_type=code' +
          '&scope=email profile';

      document.location.href = url
    }

    const onNaverLogin = () => {
      var client_id = "uCLtajLvsb9Au9s9L5Oy";
      var redirectUri = encodeURI(Config.URL.redirectUri_naver);   //redirect URI(변경시 관리자에 등록해야함)
      var state = 'merchant_' + new Date().getTime();         //주문번호같은 클라이언트쪽 코드
      var apiURL = "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=" + client_id + "&redirect_uri=" + redirectUri + "&state=" + state;
      document.location.href = apiURL;
    }


    return {
      txt_id,
      txt_pass,
      onLoginClicked,
      isLogin,
      showModal,
      titleTxt,
      bodyTxt,
      closeModal,
      okModal,
      onSignUp,
      onFindId,
      onFindPass,
      saveId,
      showFindidModal,
      okFindidModal,
      onGoogleLogin,
      findIds,
      closeFindidModal,
      onKakaoLogin,
      onNaverLogin,
      onKeyup,
      appleSignUrl,
      // onFailure,
      //  onSuccess
    }
  }
}
</script>

<style>

</style>
