<template>
  <div id="container" class="sub-contents">
    <!-- content start { -->
    <div id="contents">
      <!-- sub title text start -->
      <div class="sub-title-txt">
        <p>아이디확인을 통해 비밀번호를 변경하고 로그인 해주세요.</p>
      </div>
      <!-- //sub title text end -->
    </div>
    <!-- } content end -->
  </div>
  <!--  } container end -->
  <Footer/>
</template>

<script>
export default {
  name: "Partners"
}
</script>

<style scoped>

</style>
