<template>
  <!-- container start { -->
  <div id="container" class="sub-contents">
    <!-- content start { -->
    <div id="contents">
      <!-- sub title text start -->
      <div class="sub-title-txt">
        <p>동의철회 전 <span class="fc-blue">유의사항</span>을 확인해주세요.</p>
      </div>
      <!-- //sub title text end -->

      <p class="p-sub-tit">제휴사 선택</p>
      <!-- table type 1 start -->
      <table class="tbl-type1 tbl-top-line" style="padding-top: 20px">
        <colgroup>
          <col style="width:100%">
        </colgroup>
        <tbody>
        <tr
            v-for="(item) in partnersObj"
            :key=item.PARTNERS_IDX
        >
          <div class="d-flex-between" style="margin-top: 10px;">
            <input type="checkbox" :id="'chk1' + item.PARTNERS_IDX" :checked="item.ISAGREE === 'Y'" @change="toggleCheck($event, item)"/>
            <label :for="'chk1' + item.PARTNERS_IDX" class="mr-10">{{ item.PARTNERS_NAME }}</label>
          </div>
        </tr>
        </tbody>
      </table>
      <!-- //table type 1 end -->

      <p class="mt-10 mb-50 fs14 fc-gray-l" />

      <p class="p-sub-tit">유의사항</p>
      <ul class="withdrawal-info">
        <li>FLEX 제휴사 탈퇴 시, 제휴사에서 사용하시던 포인트 및 쿠폰 등은 복원할 수 없습니다.</li>
      </ul>

      <div class="d-flex-between my-40">
        <button @click="onRemoveProCancle" type="button" class="btn btn-xl w-50">뒤로가기</button>
        <button @click="onConfirm" type="button" class="btn btn-xl btn-outline-blue w-50 ml-10">변경완료</button>
      </div>

    </div>
    <!-- } content end -->
  </div>
  <!--  } container end -->
  <Footer/>
  <BasicModal1b
      v-if="showModal"
      @close="closeModal"
      @ok="okModal"

      :titleTxt="titleTxt"
      :bodyTxt="bodyTxt"
  />

</template>

<script>
import { ref, onMounted } from 'vue'
import { useToast } from '@/composables/toast';
import BasicModal1b from '@/components/BasicModal1b.vue';
import Footer from '@/components/Footer.vue';
import { useRouter } from 'vue-router';
import axios from '@/axios';
import { useStore } from 'vuex';

export default {
  components: {
    Footer,
    BasicModal1b
  },
  setup() {
    const titleTxt = ref('내용 :')
    const bodyTxt = ref('회원탈퇴 성공했습니다.')
    const showModal = ref(false);
    const partnersObj = ref([]);
    const store = useStore();
    const {
      triggerToast
    } = useToast();

    onMounted(() => {
      store.commit('common/CHANGE_CURRENT_PAGE', 'RemoveProfile');
    })

    const router = useRouter();
    const onRemoveProCancle = () => {
      router.push({
        name: 'Main',
      });
    }

    const onConfirm = () => {
      const params = [
        {
          "pid":"flextv",
          "isAgree": "Y"
        }
      ]
      const money = partnersObj.value.find(p => p.PID === 'flexmoney')
      params.push({
        "pid": "flexmoney",
        "isAgree": money?.ISAGREE ?? 'N'
      })
      apiCallChangePartners(params)
    }
    const closeModal = () => {
      showModal.value = false;
    }
    const okModal = () => {
      showModal.value = false;
    }

    const toggleCheck = (event, item) => {
      for (let i = 0; i < partnersObj.value.length; i++) {
        const partner = partnersObj.value[i];
        if (partner.PID === item.PID) {
          if (event.target.checked) {
            partner.ISAGREE = 'Y'
          } else {
            partner.ISAGREE = 'N'
          }
        }
      }
    }

    const apiCallChangePartners = async (params) => {
      const token = sessionStorage.getItem('token');
      try {
        const res = await axios.put(`loginmember/mypartnerschange`, params, {
          headers: {
            'token': token
          }
        });
        const json = res.data;
        if (json.returnCode === 1) {
          if (json.resultMap.returnVal === 1) {
            triggerToast('변경되었습니다.')
            router.back()
          } else {
            triggerToast(json.message, 'danger');
          }
        } else {
          triggerToast(json.message, 'danger');
        }
      } catch (error) {
        triggerToast(error, 'danger');
        console.log("mypartnerschange error", error);
        return false;
      }
    }

    const apiCallGetmypartners = async () => {
      var token = sessionStorage.getItem('token');
      try {
        const res = await axios.get(`loginmember/getmypartners`, {
          headers: {
            'token': token
          }
        });
        const json = res.data;
        partnersObj.value = (json.resultMap?.dataList ?? []).filter(p => p.PID === 'flexmoney');
        console.log("apiCallGetmypartners:", partnersObj.value)
      } catch (error) {
        console.log("apiCallGetmypartners error", error);
        return false;
      }
    }
    apiCallGetmypartners();

    function showExpireTerm(partner) {
      console.log(partner)
    }

    return {
      onConfirm,
      onRemoveProCancle,
      closeModal,
      okModal,
      showModal,
      titleTxt,
      bodyTxt,
      partnersObj,
      toggleCheck,
      showExpireTerm,
    }
  }
}
</script>

<style>

</style>
