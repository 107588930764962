<template>
  <div id="container" class="sub-contents">
    <!-- content start { -->
    <div id="contents">

      <!-- qna success start { -->
      <div v-if="success" class="qna-success">
        <h3>전화번호가 정상적으로 변경되었습니다.</h3>
        <img src="@/assets/images/cs/qna-success.png" width="160" alt="온라인 문의가 정상 등록되었습니다.">
      </div>
      <!-- } qna success end -->

      <div class="mt-30 mb-50 d-flex-around">
        <button @click="onConfirm" class="btn btn-xl btn-outline-gray w-50">회원정보로 이동</button>
      </div>

    </div>
    <!-- } content end -->
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getHomeUrl } from '@/helpers/partner'
import { closeWindow, createRedirectUrl } from '@/helpers/redirect'
import axios from '@/axios'

export default {
  name: "ChangePhone",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const success = ref(false)

    onMounted(() => {
      const params = route.params.item ? JSON.parse(route.params.item) : {}
      const token = sessionStorage.getItem('token')
      axios.post('loginmember/membermodify', {
        authtoken: token,
        imp_uid: params.imp_uid
      }).then((a) => {
        success.value = true
        console.log(a)
      }).catch(() => {
        window.alert('본인인증 정보와 회원정보가 일치하지 않습니다.')
      })
    })

    const onConfirm = () => {
      const app = route.query?.app ?? ''
      // 'Profile'
      if (app) {
        window.location.href = createRedirectUrl(`${getHomeUrl(app)}/myPage`)
        closeWindow(`${getHomeUrl(app)}/myPage`)
      } else {
        router.push({
          name: 'Profile',
        });
      }
    }

    return {
      success,
      onConfirm
    }
  }
}
</script>

<style scoped>

</style>
