<template>
  <div id="wrap">
    <Header/>
    <router-view/>
  </div>
  <Toast/>
</template>


<script>

import { onMounted } from 'vue'
import { useStore } from 'vuex';

import Toast from '@/components/Toast.vue';
import Header from "@/components/Header";
import { signInWithToken } from '@/helpers/auth'

export default {
  name: 'App',
  components: {
    Header,
    Toast
  },
  setup() {
    const store = useStore();

    const {Kakao} = window;
    Kakao.init('56ab31797785ec331658c4ca6cf37042'); // 사용하려는 앱의 JavaScript 키 입력
    onMounted(async () => {
      const searchParams = new URLSearchParams(window.location.search)
      const token = searchParams.get('token')
      if (token) {
        return signInWithToken(token, { store })
      }
    })
    return {
      //  onHome,
      //  onPage01,
      //  onPage02,
    }
  }
}


</script>


<style>

@import './assets/css/fullpage.min.css';
@import './assets/css/common.css';
@import './assets/css/style.css';


</style>
