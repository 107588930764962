<template>
  <!-- container start { -->
  <div id="container" style="display: flex; justify-content: center; align-items: center; height: 300px;">
    <spinner />
    <!-- } content end -->
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import Spinner from '@/components/Spinner.vue'
import useApp from '@/hooks/useApp'
import Config from '@/config.js'
export default {
  components: {
    Spinner,
  },
  setup() {
    const { app: appRef, saveQuery } = useApp()
    const store = useStore();
    const app = appRef.value
    const appleSignUrl = `https://appleid.apple.com/auth/authorize?client_id=kr.co.flexlife.flexLife&redirect_uri=${encodeURIComponent(Config.URL.apiLifeUrl + 'sns/appleLoginCallback')}&response_type=code%20id_token&state=${app || 'qwer1234'}&scope=name%20email&response_mode=form_post`

    onMounted(() => {
      store.commit('common/CHANGE_CURRENT_PAGE', 'AppleLogin');
      saveQuery()
      window.location.href = appleSignUrl
    })

    return {
    }
  }
}
</script>

<style>

</style>
