<template>
  <!-- container start { -->
  <div id="container" style="display: flex; justify-content: center; align-items: center; height: 300px;">
    <spinner />
    <!-- } content end -->
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import Spinner from '@/components/Spinner.vue'
import useApp from '@/hooks/useApp'
import Config from '@/config.js'
export default {
  components: {
    Spinner,
  },
  setup() {
    const { saveQuery } = useApp()
    const {Kakao} = window;
    const store = useStore();

    const onKakaoLogin = () => {
      saveQuery()
      Kakao.Auth.authorize({
        redirectUri: Config.URL.redirectUri_kakao,
      });
    }

    onMounted(() => {
      store.commit('common/CHANGE_CURRENT_PAGE', 'KakaoLogin');
      onKakaoLogin()
    })

    return {
      onKakaoLogin,
    }
  }
}
</script>

<style>

</style>
