<template>
  <article id="container" class="main-container">
    <div class="main-layout">
      <section class="text-group">
        <h2 class="main-title">
          <span> <strong>통합ID</strong> 하나로 당신의 </span>
          <span> 새로운 일상이 <strong>시작</strong>됩니다. </span>
        </h2>
        <div class="summary">
          <p>
            다양한 서비스를 누리는 스마트한 방법!<br />
            플렉스라이프 통합ID 서비스를 통해 더 쉽고 간편해진 혜택을 만나보세요.
          </p>
          <div class="btn-group">
            <router-link :to="{ name: 'Login' }" class="btn-c" v-if="!isLogin">로그인</router-link>
            <router-link :to="{ name: '' }" class="btn-m" @click="onSignUp()">{{ isLogin ? '내 정보 수정' : '통합 회원가입' }}</router-link>
          </div>
        </div>
      </section>
      <section>
        <div class="link-group">
          <div class="link-box tv">
            <img src="@/assets/images/cm/image-flextv.jpg" width="1708" class="bg" alt="플렉스티비" />
            <dl class="desc">
              <dt><img src="@/assets/images/cm/logo_flextv.png" alt="플렉스티비" /></dt>
              <dd>
                놓칠수 없는 재미, 최고의 BJ 라인업!<br />
                라이브 스트리밍 플랫폼 플렉스티비
              </dd>
            </dl>
            <div class="link-wrap">
              <a class="btn-link" href="https://www.flextv.co.kr" target="_blank" title="플렉스티비 바로가기">
                <span>플렉스티비 바로가기</span>
                <img src="@/assets/images/cm/ico_front.png" width="24" alt="플렉스티비 바로가기" />
              </a>
            </div>
          </div>
          <div class="link-box money">
            <img src="@/assets/images/cm/img_flexmoney.png" class="bg" />
            <dl class="desc">
              <dt><img src="@/assets/images/cm/logo_flexmoney.png" alt="플렉스머니" /></dt>
              <dd>
                주식, 선물, 코인 모든 모의투자 거래를<br />
                플렉스머니 모의투자 대회에서 경험해보세요.
              </dd>
            </dl>
            <div class="link-wrap">
              <a class="btn-link" href="https://www.flexmoney.co.kr" target="_blank" title="플렉스머니 바로가기">
                <span>플렉스머니 바로가기</span>
                <img src="@/assets/images/cm/ico_front.png" width="24" alt="플렉스머니 바로가기" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </article>
  <Footer />
</template>

<script>
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useStore } from 'vuex'
import Footer from '@/components/Footer.vue'
export default {
  name: 'main',
  components: {
    Footer
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const isLogin = computed(() => store.state.user.isLogin)
    const searchParams = new URLSearchParams(window.location.search)
    const app = searchParams.get('app')

    const onSignUp = () => {
      if (isLogin.value) {
        router.push({
          name: 'Profile'
        })
      } else {
        router.push({
          path: '/certificate',
          query: {
            page: 'Signup',
            app
          }
        })
      }
    }

    return {
      isLogin,
      onSignUp
    }
  }
}
</script>
