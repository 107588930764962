export default {
    namespaced: true,
    state: {
        currentPage: "HOME",
        app: ''
    },
    mutations: {
        CHANGE_CURRENT_PAGE(state, payload) {
            console.log("store:", state, payload)
            state.currentPage = payload;
        },
        SET_APP(state, payload) {
            state.app = payload
        }
    },
    actions: {
        // triggerToast({ commit }, message, type = 'success') {
        //     commit('ADD_TOAST', {
        //         id: Date.now(),
        //         message: message,
        //         type: type,
        //     });


        //     setTimeout(() => {
        //         commit('REMOVE_TOAST')
        //     }, 10000)
        // }
    },
    //computed 와 동일한기능..
    getters: {
        // toastMessageWithSmile(state) {
        //     return state.toasts.message + "^^~~~";
        // }
    },
}
