<template>        
    <!-- container start { -->
    <div id="container" class="sub-contents">
        <!-- content start { -->
        <div id="contents">

            <!-- <div class="sub-title">
                <div class="position">
                    <h2 @click="$router.go(-1)">
                        <img src="@/assets/images/cm/ico_back.png" alt="back" class="mr-5"> 온라인문의
                    </h2>
                </div>
            </div>   -->

            <!-- sub notice tab start { -->
            <div class="notice-tab">
                <div ><router-link :to="{ name: 'CenterNotice'}">공지사항 </router-link></div>
                <div><router-link :to="{ name: 'CenterQuestions'}">자주하는 질문 </router-link></div>
                <div class="active"><router-link :to="{ name: isLogin ? 'CenterQnA' : 'Login' }">온라인문의 </router-link></div>
            </div>
            <!-- } sub notice tab end -->

            <!-- qna button start { -->
            <div class="mb-20 mt-40">
                <router-link :to="{ name: 'centerQnaWrite'}" class="btn btn-lg fc-black font-bold w-100">문의하기</router-link>
                <!-- <button type="button"  onclick="location.href='FLEX-LIFE-CS-006.html';">문의하기</button> -->
            </div>
            <!-- } qna button end -->

            <!-- qna start { -->
            <ul class="qna">
                <!-- <li style="text-align:center; border-bottom:1px solid #ddd; padding:50px; ">
                    온라인 문의 준비중입니다.
                </li> -->
                <template
                    v-for="item in qnaLists"
                    :key="item.IDX"             
                >
                    <li
                        v-if="item.REPLY_OK === 'Y'"
                        :class="{'active' : item.isActive}"
                        @click="item.isActive = !item.isActive"
                    >
                        <a class="qna-title">
                            <span class="btn btn-sm status success">답변완료</span>
                            <span class="category">[{{ item.CATEGORY }}]</span>
                            <span class="q">{{ item.SUBJECT }}</span>
                            <span class="chevron">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </a>
                        <div class="qna-area">
                            <div class="qna-q">                            
                                <div class="qna-conts" v-html="item.CONTENTS"></div>
                            </div>
                            <div class="qna-a">
                                <span class="btn btn-sm status btn-outline-blue mx-10">답변</span>
                                <div class="answer">
                                    <div v-html="item.REPLY"></div>
                                    <!-- <p></p><div class="bbs-view-body" v-html="boardItem.CONTENTS"> -->
                                </div>
                            </div>
                        </div>
                    </li>
                    <li 
                        v-else
                        :class="{'active' : item.isActive}"
                        @click="item.isActive = !item.isActive"
                    >
                        <a class="qna-title">
                            <span class="btn btn-sm btn-outline-gray status ready">답변대기</span>
                            <span class="category">[{{ item.CATEGORY }}]</span>
                            <span class="q">{{ item.SUBJECT }}</span>
                            <span class="chevron">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </a>
                        <div class="qna-area">
                            <div class="qna-q">
                                <div class="qna-conts" v-html="item.CONTENTS"></div>
                                <!-- <strong>Q</strong>
                                <div class="question">
                                    <h4 class="title">{{ item.SUBJECT }}</h4>
                                    <p class="mt-20" v-html="item.CONTENTS"></p>
                                </div> -->
                            </div>
                        </div>
                    </li>
                </template>
            </ul>
            
            
            <!-- paging start -->
            <div class="paging" v-if="qnaLists">
                <v-pagination                  
                    v-model="currentPage"
                    :pages= numberOfPages
                    :rangeSize="1"
                    activeColor="#fff"                   
                    @update:modelValue="updateHandler"
                />          
            </div>     
            <!-- //paging end -->

        </div>
        <!-- } content end -->
    </div>
    <!--  } container end -->

    <Footer/>

  
</template>

<script>
import Footer from '@/components/Footer.vue';
// import {useAuthentication} from '@/composables/commonFn';
import axios from '@/axios';
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    components:{   
        Footer,
        VPagination,
    },
    setup() {
        const router = useRouter();
        const qnaLists = ref([]);
        const store = useStore();
        const isLogin = computed(() => store.state.user.isLogin);       
        
        const limit = 10;
        const currentPage = ref(1)
        const numberOfData = ref(0);
        const numberOfPages = computed(()=> {
            return Math.ceil(numberOfData.value/limit)
        });

        const getQnaList = async(page = currentPage.value) => {
            currentPage.value = page; 
            var offset =  (currentPage.value -1) * limit;
            var getUrl = `board/counselinglist?limit=${limit}&offset=${offset}`;  


            var token = sessionStorage.getItem('token');
            try {               
                const res = await axios.get(getUrl, { headers: { 'token': token } });
                const json = res.data

                if( json.returnCode == 1 ) {
                    if(json.resultMap.dataList == null) json.resultMap.dataList = [];
                    qnaLists.value = json.resultMap.dataList;
                    numberOfData.value = json.resultMap.dataCount;
                    
                   // console.log(qnaLists.value)
                }
                
            } catch(error) {
                console.log(error);
                alert('로그인이 필요합니다.\n로그인 후 이용하여 주십시오.');
                router.push({
                    name: 'Login',
                });                
                return false;
            }
        }

        onMounted(()=> {
            store.commit('common/CHANGE_CURRENT_PAGE', 'CenterNotice');
          
            updateHandler(1);
        })
        const updateHandler = () => {
            getQnaList();
        }
        return {          
            qnaLists,
            isLogin,
            currentPage,
            numberOfPages,
            updateHandler
            
        }    

    }
}
</script>

<style>

</style>