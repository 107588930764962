<template>        
    <!-- container start { -->
    <div id="container">
        <!-- content start { -->
        <div id="contents" class="w-100">

            <div class="account-info-area">
                <div class="account-content-section">
                    <h1 class="p-tit">FLEX 계정 통합 가이드</h1>
                    <p>FLEX 계정으로 통합하면<br>다양한 서비스를 하나의 계정으로 이용할 수 있어요.</p>
                    <div class="btn-sec"><button  @click="onClick" type="button" class="btn btn-blue btn-xl">계정 통합하러 가기</button></div>
                    <span class="img"><img src="@/assets/images/fi/img_account_01.png" alt="통합가이드안내"></span>
                </div>
            </div>
            <div class="account-info-area bg-gray-l">
                <div class="account-content-section">
                    <span class="step">step1</span>
                    <h1>계정 통합 방법</h1>
                    <p class="mb-40">FLEX 계정에는 오직 하나의<br>FLEXLIFE 아이디만 통합할 수 있어요.</p>
                    <span class="img"><img src="@/assets/images/fi/img_account_02.png" alt="통합가이드안내"></span>
                </div>
            </div>
            <div class="account-info-area account-info-bg1">
                <div class="account-content-section">
                    <h1 class="fc-white">통합할 아이디는<br>신중히 확인하세요!</h1>
                    <p class="fc-white">FLEX 계정으로 통합하면<br>다양한 서비스를 하나의 계정으로 이용할 수 있어요.</p>
                    <span class="img"><img src="@/assets/images/fi/img_account_03.png" alt="통합가이드안내"></span>
                </div>
            </div>
            <div class="account-info-area">
                <div class="account-content-section">
                    <span class="step">step2</span>
                    <h1>통합 후 로그인 방법</h1>
                    <p>통합 후에는 FLEX 로그인도<br>FLEXLIFE 계정으로 로그인해 주세요.</p>
                    <!-- <span class="img"><img src="../../images/fi/img_account_04.png" alt="통합가이드안내" class="step2"></span> -->
                </div>
            </div>
            <div class="account-info-area">
                <div class="account-content-section">
                    <span class="step">step3</span>
                    <h1>통합 후에도<br>서비스는 그대로!</h1>
                    <p>동일한 FLEXLIFE 계정으로 FLEX 로그인 시,<br>
                        기존 서비스 내역 그대로 이용할 수 있어요.
                    </p>
                    <!-- <span class="img"><img src="../../images/fi/img_account_05.png" alt="통합가이드안내"></span> -->
                </div>
            </div>
            <div class="account-info-area account-info-bg1">
                <div class="account-content-section">
                    <span class="step">step4</span>
                    <h1 class="fc-white">2단계 인증 설정</h1>
                    <p>기존 FLEX 2단계 인증이 해제됩니다.<br>
                        통합한 FLEXLIFE 계정으로 2단계 인증 설정 후<br>
                        FLEX 보안까지 간편하게 관리하세요.
                    </p>
                    <!-- <span class="img"><img src="../../images/fi/img_account_06.png" alt="통합가이드"></span> -->
                    <div class="btn-sec"><button type="button" class="btn btn-blue btn-xl"  @click="onClick" >계정 통합하러 가기</button></div>
                </div>
            </div>

        </div>
        <!-- } content end -->
    </div>
    <!--  } container end -->
    <Footer/>

  
</template>

<script>
import Footer from '@/components/Footer.vue';
import {  useRouter }from 'vue-router'
export default {
components:{   
    Footer,
 },
 setup(){
    const router = useRouter();
    const onClick =()=>{
         router.push({
            name: 'Login',        
        });

    }
   return{
    onClick
   } 
 }
}
</script>

<style>

</style>