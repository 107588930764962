<template>        
    <!-- container start { -->
    <div id="container" class="sub-contents">
        <!-- content start { -->
        <div id="contents">

            <!-- sub title h2 start { -->
            <!-- <div class="sub-title">
                <div class="position">
                    <h2 @click="$router.go(-1)">
                        <img src="@/assets/images/cm/ico_back.png" alt="back" class="mr-5"> 공지사항
                    </h2>
                </div>
            </div>   -->

            <!-- sub notice tab start { -->
            <div class="notice-tab">
                <div class="active"><router-link :to="{ name: 'CenterNotice'}">공지사항 </router-link></div>
                <div><router-link :to="{ name: 'CenterQuestions'}">자주하는 질문 </router-link></div>
                <div><router-link :to="{ name: isLogin ? 'CenterQnA' : 'Login' }">온라인문의 </router-link></div>
            </div>
            <!-- } sub notice tab end -->

            <!-- notice start { -->
            <table class="tbl-type1 tbl-default-detail mt-40">
                <colgroup>
                    <col style="width:100%">
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            {{ noticeViewData.SUBJECT }}
                            <div class="date">
                                <span class="mr-5">{{ noticeViewData.ADMIN_NAME }}</span>
                                {{ noticeViewData.MODIFICATIONDATE?noticeViewData.MODIFICATIONDATE.split(' ')[0]:'' }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div v-html="noticeViewData.CONTENTS?.replace(/(?:\r\n|\r|\n)/g, '<br />')"></div>
                        </td>
                    </tr>
                </tbody>
                <!-- <tfoot>
                    <tr>
                        <td colspan="2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="icons mr-10">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z" clip-rule="evenodd" />
                            </svg>
                            이전글
                            <a href="#">플렉스 이용자금 보호 운영 현황 안내문</a>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="icons mr-10">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z" clip-rule="evenodd" />
                            </svg>
                            다음글
                            <a href="#">[공지] 울릉도/강릉 지역 폭설로 인한 베이커리류 택배 배송불가 안내</a>
                        </td>
                    </tr>
                </tfoot> -->
            </table>
            <!-- } notice end -->

            <!-- board bottom start { -->
            <div class="board-bottom">
                <router-link :to="{ name: 'CenterNotice' }" class="btn btn-xl btn-outline-gray w-100">목록</router-link>
            </div>
            <!-- } board bottom end -->            

        </div>
        <!-- } content end -->
    </div>
    <!--  } container end -->

    <Footer/>

  
</template>

<script>
import Footer from '@/components/Footer.vue';
import axios from '@/axios';
import { useRoute, useRouter }from 'vue-router'
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex';

export default {
    components:{   
        Footer,
    },
    setup() {

        const noticeViewData = ref({});
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const isLogin = computed(() => store.state.user.isLogin);           

        const getNoticeView = async(id) => {
            
            try {
                const res = await axios.get(`/board/noticeview?idx=${id}`);
                const resData = res.data.resultMap;

                noticeViewData.value = resData.data;
            
            } catch(error) {
                alert('데이터 불러오기에 실패하였습니다.\n확인 후 다시 시도하시기 바랍니다.');
                router.push({
                    name: 'CenterNotice',
                });
                return false;
            }
        }

        noticeViewData.value = getNoticeView(route.params.id);
        
        onMounted(() => {
                 store.commit('common/CHANGE_CURRENT_PAGE', 'CenterNotice');
        })


        return {
            noticeViewData,
            isLogin
        }
    }


}
</script>

<style>

</style>