import qs from 'query-string'
import { MONEY_APP_SCHEME } from '@/consts/urls'

export function hasRedirectUrl () {
  const searchParams = new URLSearchParams(window.location.search)
  return !!searchParams.get('redirect_url')
}

export function createRedirectUrl (url, token) {
  if (!url) return ''

  const result = url.startsWith(MONEY_APP_SCHEME) ? 1 : undefined
  const querystring = qs.stringify({
    token,
    result
  })
  const postfix = url.includes('?') ? '&' : '?'

  return `${url}${postfix}${querystring}`
}

export function closeWindow (url) {
  if (!url || !url.startsWith(MONEY_APP_SCHEME)) return

  window.setTimeout(() => {
    window.close()
  }, 200)
}

export function redirectToCallbackWithAuth (query, token, defaultRedirectUrl) {
  if (!query) return
  window.location.href = createRedirectUrl(query['redirect_url'] || defaultRedirectUrl, token)
  closeWindow(query['redirect_url'] || defaultRedirectUrl)
}
