<template>
  <ul class="singup-li">
    <li>
      <span class="txt">이메일주소</span>
      <div class="d-flex-between">
                            <span class="w-32">
                                <label for="email1" class="blind">이메일주소</label>
                                <input type="text" name="email1" id="email1" class="w-100" v-model="email_1">
                            </span>
        <span class="ml-10">@</span>
        <span class="ml-10 w-32">
                                <label for="email2" class="blind">이메일주소</label>
                                <input type="text" name="email2" id="email2" class="w-100" v-model="email_2">
                            </span>
        <div class="form-select ml-10 w-32">
          <label for="emailSelect" class="blind">select</label>
          <select id="emailSelect" @change="onEmailInputType">
            <option value="">직접입력</option>
            <option value="naver.com">naver.com</option>
            <option value="nate.com">nate.com</option>
            <option value="hanmail.net">hanmail.net</option>
            <option value="gmail.com">gmail.com</option>
            <option value="yahoo.com">yahoo.com</option>
            <option value="yahoo.co.kr">yahoo.co.kr</option>
            <option value="hotmail.com">hotmail.com</option>
          </select>
        </div>
      </div>
    </li>
    <!--li>
      <span class="txt">주소</span>
      <div class="input-btn-2col">
        <button @click="onClickZipcode" type="button" class="btn btn-lg btn-outline-black">우편번호</button>
        <span class="w-col">
                                <label for="inputText5" class="blind">우편번호</label>
                                <input type="text" name="inputText5" id="inputText5" maxlength="5" class="w-100"
                                       v-model="zipcode">
                            </span>
      </div>
      <div class="mt-10">
        <label for="inputText6" class="blind">주소</label>
        <input type="text" name="inputText6" id="inputText6" class="w-100" v-model="addr1">
      </div>
      <div class="mt-10">
        <label for="inputText7" class="blind">주소</label>
        <input type="text" name="inputText7" id="inputText7" placeholder="나머지주소" class="w-100" v-model="addr2">
      </div>
      <div ref="embed"></div>
    </li-->
  </ul>
</template>

<script>
import {ref} from "vue";

export default {
  name: "UserAdditionalProfile",
  setup() {
    const email_1 = ref('');
    const email_2 = ref('');
    const zipcode = ref('');
    const addr1 = ref('');
    const embed = ref(null);

    const onEmailInputType = (e) => {
      console.log('onEmailInputType:', e.target.value)
      email_2.value = e.target.value;
    }

    const onClickZipcode = () => {
      console.log("onClickZipcode:",)
      new window.daum.Postcode({
        oncomplete: (data) => {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
          let extraRoadAddr = ''; // 도로명 조합형 주소 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== '' && data.apartment === 'Y') {
            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
          }
          // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraRoadAddr !== '') {
            extraRoadAddr = ' (' + extraRoadAddr + ')';
          }
          // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
          if (fullRoadAddr !== '') {
            fullRoadAddr += extraRoadAddr;
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          zipcode.value = data.zonecode; //5자리 새우편번호 사용
          addr1.value = fullRoadAddr;
        }
      }).embed(embed.value)

    }


    return {
      onEmailInputType,
      onClickZipcode,
      email_1,
      email_2,
      zipcode
    }
  }
}
</script>

<style scoped>

</style>
