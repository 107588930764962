<template>
    <!-- container start { -->
    <div id="container" class="sub-contents">
        <!-- content start { -->
        <div id="contents">

            <!-- <div class="sub-title">
                <div class="position">
                    <h2 @click="$router.go(-1)">
                        <img src="@/assets/images/cm/ico_back.png" alt="back" class="mr-5"> 자주하는 질문
                    </h2>
                </div>
            </div>   -->

            <!-- sub notice tab start { -->
            <div class="notice-tab">
               <div ><router-link :to="{ name: 'CenterNotice'}">공지사항 </router-link></div>
                <div  class="active" ><router-link :to="{ name: 'CenterQuestions'}">자주하는 질문 </router-link></div>
                <div><router-link :to="{ name: isLogin ? 'CenterQnA' : 'Login' }">온라인문의 </router-link></div>
            </div>
            <!-- } sub notice tab end -->

            <!-- faq start { -->
            <ul class="faq mb-50 mt-40">
                <li
                    v-for="item in faqLists"
                    :key="item.IDX"
                    :class="{'active' : item.isActive}"
                    @click="item.isActive = !item.isActive"
                >
                    <a class="faq-q">
                        <span class="txt">질문</span>
                        <span class="category"> [{{ item.CATEGORY }} ]</span>
                        <span class="q">{{ item.QUESTION }}</span>
                        <span class="chevron">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                        </span>
                    </a>
                    <div class="faq-a">
                        <span class="txt">답변</span>
                        <div class="answer">
                            <div v-html="item.ANSWER.replace(/(?:\r\n|\r|\n)/g, '<br />')"></div>
                        </div>
                    </div>
                </li>
                <li v-if="faqLists == null">
                    자주하는 질문이 없습니다.
                </li>
            </ul>
            <!-- } faq end -->

            <!-- paging start -->
            <!-- <div class="paging">
                <a href="javascript:;" class="first"><img src="@/assets/images/cm/ico_paging_first.png" alt="처음"></a>
                <a href="javascript:;" class="prev"><img src="@/assets/images/cm/ico_paging_prev.png" alt="이전"></a>
                <a href="javascript:;" class="active">1</a>
                <a href="javascript:;">2</a>
                <a href="javascript:;">3</a>
                <a href="javascript:;" class="next"><img src="@/assets/images/cm/ico_paging_next.png" alt="다음"></a>
                <a href="javascript:;" class="last"><img src="@/assets/images/cm/ico_paging_last.png" alt="마지막"></a>
            </div> -->
            <!-- //paging end -->

        </div>
        <!-- } content end -->
    </div>
    <!--  } container end -->

    <Footer/>


</template>

<script>
import Footer from '@/components/Footer.vue';
import axios from '@/axios';
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex';

export default {
    components:{
        Footer,
    },
    setup() {
      const faqLists = ref([]);
      const store = useStore();
      const isLogin = computed(() => store.state.user.isLogin);
      const getfaqLists = async() => {
            try {
                const res = await axios.get(`/board/faqlist`);
                const resData = res.data;
                if ( resData.returnCode == 1 ) {
                    faqLists.value = resData.resultMap.dataList;
                    // console.log(faqLists.value);
                } else {
                    console.log('error', resData.message)
                    return null;
                }
            } catch(error) {
                //console.log(error);
                alert('데이터 불러오기에 실패하였습니다.\n확인 후 다시 시도하시기 바랍니다.');
                return false;
            }
        }

        onMounted(()=> {
            store.commit('common/CHANGE_CURRENT_PAGE', 'CenterNotice');
            getfaqLists()
        })

        return {
            faqLists,
            isLogin
        }

    }
}
</script>

<style>

</style>
