import { useToast } from '@/composables/toast';
import { useStore } from 'vuex';
import axios from '@/axios';
import Config from '@/config.js'
import { createAuthInfo } from '@/helpers/auth'
import { useRouter } from 'vue-router'

export const useCheckLogin = () => {
  const store = useStore();
  const checkLogin = async () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      store.commit('user/CHANGE_IS_LOGIN', false);
      return
    }
    try {
      const res = await axios.get('loginmember/logincheck', {headers: {'token': token}});
      console.log("checkLogin:", res.data)
      if (res.data.returnCode == '1') {
        if (res.data.resultMap.returnVal == "1") {
          store.commit('user/CHANGE_IS_LOGIN', true);
          store.commit('user/CHANGE_MEMBER_DATA', res.data.resultMap.member);
        } else {
          store.commit('user/CHANGE_IS_LOGIN', false);
        }
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  return {
    checkLogin,
  }
}

export const useAuthentication = () => {
  const {
    triggerToast
  } = useToast();
  const router = useRouter();

  const {IMP} = window;
  IMP.init("imp02722486"); // 예: imp00000000

  const getImpuid = (page, {sns = null, accessToken = null, inputMid = null, app = '', redirectUrl } = {}) => {
    IMP.certification({ // param
      merchant_uid: "ORD20180131-0000011", // 주문 번호
      pg: 'danal.B010007760',
      // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
      m_redirect_url: Config.URL.redirectUri_m_sms + `?request_page=${page}&sns=${sns}&accessToken=${accessToken}&inputMid=${inputMid}&app=${app}&redirect_url=${redirectUrl}`,
      popup: false // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
    }, function (rsp) { // callback
      if (rsp.success) {
        createAuthInfo(rsp.imp_uid, {sns, accessToken, inputMid}).then((itemData) => {
          router.push({
            name: page,
            params: {
              item: JSON.stringify(itemData)
            },
            query: {
              app,
              redirect_url: redirectUrl
            }
          });
        })
      } else {
        // 인증 실패 시 로직,
        console.log("인증실패", rsp.error_msg)
        triggerToast(rsp.error_msg, 'danger');
      }
    });
  }
  return {
    getImpuid
  }
}
