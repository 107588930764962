<template>
        
        <!-- container start { -->
        <div id="container" class="sub-contents">
            <!-- content start { -->
            <div id="contents">

                <!-- sub title h2 start { -->
                <!-- <div class="sub-title">
                    <div class="position">
                        <h2 @click="$router.go(-1)">
                            <img src="@/assets/images/cm/ico_back.png" alt="back" class="mr-5"> 회사소개
                        </h2>
                    </div>
                </div>   -->

                <p class="fs18 fc-gray text-center">
                    플렉스라이프는 콘텐츠, 비즈니스가 연결되는 새로운 가치와 혁신에 집중하고,<br>콘텐츠 크리에이터를 통해 라이프스타일 플랫폼 기업을 지향합니다.
                </p>
                <!-- company information start { -->
                <div class="company-information">
                    <img src="@/assets/images/co/company_information.png" alt="혁신 | 도전 | 창조 = FLEX">
                </div>
                <!-- } company information list end -->
                
            </div>
            <!-- } content end -->
        </div>
        <!--  } container end -->
        <Footer/>

  
</template>

<script>
import Footer from '@/components/Footer.vue';
import { onMounted} from 'vue'
import { useStore } from 'vuex';
export default {
components:{   
    Footer,
 },
setup(){
     const store = useStore();
   onMounted(()=>{
     store.commit('common/CHANGE_CURRENT_PAGE', 'Company');
   })


    return{

    }
}
}
</script>

<style>

</style>