<template>        
    <!-- container start { -->
    <div id="container">
        <!-- content start { -->
        <div id="contents">

            <!-- page navigation -->
            <div class="breadcrumb">
                <ul>
                    <li class="home"><img src="@/assets/images/cm/ico_home.png" alt="home"></li>
                    <li>FLEX소개</li>
                    <li>플렉스 서비스 소개</li>
                </ul>
            </div>

            <!-- sub title h2 start { -->
            <div class="sub-title">
                <h2>플렉스 서비스 소개</h2>
            </div>
            <!-- } sub title h2 end -->

            <!-- sub title h3 start { -->
            <div class="sub-title-lg">
                <h3>FLEX회원만을 위한 서비스를 즐기세요.</h3>
            </div>
            <!-- } sub title h3 end -->

            <!-- flex service button start { -->
            <!-- <div class="text-left mb-10 flex-service-header">
                <a href="#" class="active">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    전체
                </a>
                <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                    </svg>
                    엔터테인먼트
                </a>
                <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                    </svg>
                    쇼핑
                </a>
                <a href="#">
                    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="3" y1="15" x2="3" y2="8" stroke-width="2" stroke-linecap="round"/>
                        <line x1="9" y1="15" x2="9" y2="1" stroke-width="2" stroke-linecap="round"/>
                        <line x1="12" y1="15" x2="12" y2="1" stroke-width="2" stroke-linecap="round"/>
                        <path d="M5 4C5 4.90912 4.72237 5.69369 4.32132 6.22843C3.9205 6.76286 3.4431 7 3 7C2.5569 7 2.0795 6.76286 1.67868 6.22843C1.27762 5.69369 1 4.90912 1 4C1 3.09088 1.27762 2.30631 1.67868 1.77157C2.0795 1.23714 2.5569 1 3 1C3.4431 1 3.9205 1.23714 4.32132 1.77157C4.72237 2.30631 5 3.09088 5 4Z" stroke-width="2"/>
                    </svg>
                    외식
                </a>
                <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                    </svg>
                    유통
                </a>
            </div> -->
            <!-- } flex service button end -->

            <!-- flex service list start { -->
            <ul class="flex-service-list" style="padding: 24px 0 300px;">
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <span class="category-img">
                        <a href="https://www.flextv.co.kr/" target="_blank"><img src="@/assets/images/fi/flex_service_01.png" alt="" /></a>
                    </span>
                    <p>
                        FLEX TV <strong>제휴처</strong>
                    </p>
                </li>
                <li style="flex-basis: calc(75% - 20px); border:0;">
                    <p class="fs16 mt-50">플렉스이엔엠은 파격적인 이벤트와 신선한 기능을 도입함으로써 대표 인터넷방송 플랫폼으로 성장하고 있는 기업입니다.</p>
                </li>            
                <!-- <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_02.png" alt="" />
                    <p>
                        결제 금액의 <strong>1~5% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="3" y1="15" x2="3" y2="8" stroke-width="2" stroke-linecap="round"/>
                        <line x1="9" y1="15" x2="9" y2="1" stroke-width="2" stroke-linecap="round"/>
                        <line x1="12" y1="15" x2="12" y2="1" stroke-width="2" stroke-linecap="round"/>
                        <path d="M5 4C5 4.90912 4.72237 5.69369 4.32132 6.22843C3.9205 6.76286 3.4431 7 3 7C2.5569 7 2.0795 6.76286 1.67868 6.22843C1.27762 5.69369 1 4.90912 1 4C1 3.09088 1.27762 2.30631 1.67868 1.77157C2.0795 1.23714 2.5569 1 3 1C3.4431 1 3.9205 1.23714 4.32132 1.77157C4.72237 2.30631 5 3.09088 5 4Z" stroke-width="2"/>
                    </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_03.png" alt="" />
                    <p>
                        젊은 창작문화를 움직이다 <strong>CJ문화재단</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_04.png" alt="" />
                    <p>
                        결제 금액의 <strong>0.5~2% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_05.png" alt="" />
                    <p>
                        결제 금액의 <strong>1~2.5% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_06.png" alt="" />
                    <p>
                        결제 금액의 <strong>2~5% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_07.png" alt="" />
                    <p>
                        결제 금액의 <strong>0.1~0.3% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_08.png" alt="" />
                    <p>
                        결제 금액의 <strong>2% 익일 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_09.png" alt="" />
                    <p>
                        결제 금액의 <strong>0.5% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_10.png" alt="" />
                    <p>
                        결제 금액의 <strong>0.3% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_11.png" alt="" />
                    <p>
                        결제 금액의 <strong>0.5% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_12.png" alt="" />
                    <p>
                        결제 금액의 <strong>1~3% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_13.png" alt="" />
                    <p>
                        결제 금액의 <strong>2% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_14.png" alt="" />
                    <p>
                        결제 금액의 <strong>3% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_15.png" alt="" />
                    <p>
                        결제 금액의 <strong>2% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_16.png" alt="" />
                    <p>
                        결제 금액의 <strong>3% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_17.png" alt="" />
                    <p>
                        결제 금액의 <strong>0.5% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_18.png" alt="" />
                    <p>
                        결제 금액의 <strong>2% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_19.png" alt="" />
                    <p>
                        결제 금액의 <strong>2% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_20.png" alt="" />
                    <p>
                        결제 금액의 <strong>0.5% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_21.png" alt="" />
                    <p>
                        결제 금액의 <strong>0.5% 적립</strong>
                    </p>
                </li>
                <li>
                    <span class="category">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    </span>
                    <img src="@/assets/images/fi/flex_service_22.png" alt="" />
                    <p>
                        결제 금액의 <strong>0.5% 적립</strong>
                    </p>
                </li> -->
            </ul>
            <!-- } flex service list end -->
            
        </div>
        <!-- } content end -->
    </div>
    <!--  } container end -->
    <Footer/>

  
</template>

<script>
import Footer from '@/components/Footer.vue';
export default {
components:{   
    Footer,
 }
}
</script>

<style>

</style>